export const smu_valid_email_extensions = [
    'smu.edu',
    'mail.smu.edu',
    'alumni.smu.edu',
    'cox.smu.edu',
    'lyle.smu.edu',
    'law.smu.edu',
    'simmons.smu.edu',
    'meadows.smu.edu'
    // Add more emails extensions here
];