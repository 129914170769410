import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

//  Actions - imported
import { editUserName, editUserBio } from '../actions/authActions';
import { setPage, add_To_Recent_History } from '../actions/navActions';

// wrapper layout
import Layout from '../components/layout/Layout';

// Modal
import Modal from '../components/modal/Modal';
import ModalContainer from '../components/modal/ModalContainer';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import ProfileHeader from '../components/Profile/ProfileHeader';
import Post from '../components/Post/Post';

// Edit profile - page
import ProfileEdit from '../components/Profile/ProfileEdit';

const initialState = {
    first_name: '',
    last_name: '',
    bio: ''
};

const EditProfile = ({ 
    setPage,
    add_To_Recent_History,
    auth: { 
        user, 
        loading,
        profile_update_loading 
    },
    editUserName, 
    editUserBio,
    history
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);
    
    // Edit Mode
    const [editMode, setEditMode] = useState(true);

    // Input field data
    const [formData, setFormData] = useState(initialState);

    // Edit info Modal toggle states
    const [nameModal, setNameModal] = useState(false);
    const [bioModal, setBioModal] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const hostname = window.location.hostname;

    // Set page on component mount
    useEffect(() => {
        setPage('profile edit');
        add_To_Recent_History(url.pathname);  // current url
    }, [])

    // Load profile data on user change
    useEffect(() => {

        // Load profile data
        if (!loading && user) {
            const profileData = { ...initialState };
            for (const key in user) {
                if (key in profileData) profileData[key] = user[key];
            }

            setFormData(profileData);
        }
    }, [user]);


    // Toggle profile edit mode ('profile edit' btn on profilePage)
    const toggleEdit = () => {
        setEditMode(!editMode);
    }

    // Form Data
    const { first_name, last_name, bio } = formData;

    // Edit profile info logic

    // Update the form data when input fields change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Handle name form submission
    const nameTodo = (e) => {
        onSubmitName(e);
    }

    // Handle bio form submission
    const bioTodo = (e) => {
        onSubmitBio(e);
    }

    // Submit the name form
    const onSubmitName = async e => {
        e.preventDefault();

        if(user) {
            const userId = user._id; 

            // Call the editUserName function to update the user's name
            editUserName({ 
                first_name,
                last_name,   
                userId
            });
            
            console.log('FRONT END ID')
            console.log(userId);
            console.log('FIRST NAME')
            console.log(first_name);
            console.log('LAST NAME')
            console.log(last_name);
        }

        toggleNameModal();
    }

    // Submit the bio form
    const onSubmitBio = async e => {
        e.preventDefault();

        if(user) {
            const userId = user._id; 


            // Call the editUserBio function to update the user's bio
            editUserBio({ 
                bio,
                userId
            });

            console.log('FRONT END ID')
            console.log(userId);
            console.log('BIO')
            console.log(bio);
        }

        toggleBioModal();
    }

    // Modal toggle logic

    // Toggle the name modal
    const toggleNameModal = () => {
        setNameModal(!nameModal);
    }

    // Toggle the bio modal
    const toggleBioModal = () => {
        setBioModal(!bioModal);
    }

    // Redirect if not logged in
    if(!loading && !user) {
        history.push("/login");
    }

    const bg = {
        modal: {
            paddingLeft: "0",
            paddingRight: "0",
            border:"1px solid rgb(214,214,214)",
            boxShadow: "none"
        },
        overlay: {
          background: "rgba(255,255,255,0.5)"
        }
    };

    // Handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.identify(user._id);
        mixpanel.track("Edit Profile Page View");
    }

    // Trigger Mixpanel analytics on initial render
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && user !== null && !hostname.includes('xbuysell')) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <Fragment>

            {/* Layout component with profile edit mode */}
            <Layout page={!editMode ? 'profile' : 'profile edit'} user={user}>

                {/* {profile_update_loading ? <Spinner /> : ( */}
                    
                    <ProfileEdit toggleEdit={toggleEdit} toggleNameModal={toggleNameModal} toggleBioModal={toggleBioModal} />
                    
                {/* )} */}
                
            </Layout>

            {/* Modal for editing display name */}
            <ModalContainer editProfile show={nameModal} onClose={toggleNameModal}>
                <Modal>
                    <h2>Edit Display Name</h2>
                    <div className="modal__inputs">

                        {/* Input fields for first name and last name */}
                        <input
                            type="text"
                            placeholder="First Name"
                            className="input"
                            name="first_name"
                            value={first_name}
                            maxlength="20"
                            onChange={e => onChange(e)}
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            className="input"
                            name="last_name"
                            value={last_name}
                            maxlength="20"
                            onChange={e => onChange(e)}
                        />
                    </div>

                    {/* Modal Actions */}
                    <div className="btnRow">

                        {/* Cancel button */}
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                            onClick={() => toggleNameModal()}
                        >
                            Cancel
                        </Button>

                        {/* Save button */}
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet" 
                            fullWidth
                            onClick={(e) => nameTodo(e)}
                        >
                            Save
                        </Button>
                    </div>
                </Modal>
            </ModalContainer>

            {/* Modal for editing bio */}
            <ModalContainer editProfile show={bioModal} onClose={toggleBioModal}>
                <Modal>
                    <h2>Edit Bio</h2>
                    <div className="modal__inputs">

                        {/* Textarea for bio */}
                        <textarea
                            style={{width:'94%', padding:'10px', minHeight:'100px', resize: 'none'}}
                            type="text"
                            placeholder="Bio"
                            className="input"
                            name="bio"
                            value={bio}
                            maxlength="160"
                            onChange={e => onChange(e)}
                        />

                        {/* Display Bio character length */}
                        <div style={{color:'rgb(27, 149, 224)', display: 'flex', justifyContent: 'flex-end', width:'95%', fontSize:'14px', overflowWrap:'break-word'}}>
                            <span>{bio && bio.length} / 160</span>
                        </div>
                    </div>

                    {/* Modal Actions */}
                    <div className="btnRow">

                        {/* Cancel button */}
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet ghost"
                            fullWidth
                            onClick={() => toggleBioModal()}
                        >
                            Cancel
                        </Button>

                        {/* Save button */}
                        <Button 
                            variant="outlined" 
                            className="sidebar__tweet" 
                            fullWidth
                            onClick={(e) => bioTodo(e)}
                        >
                            Save
                        </Button>
                    </div>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

EditProfile.propTypes = {
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    editUserName: PropTypes.func.isRequired, // Function prop to edit the user's name
    editUserBio: PropTypes.func.isRequired, // Function prop to edit the user's bio
    auth: PropTypes.object.isRequired // Object prop representing the authentication state of the user
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage,
    add_To_Recent_History,
    editUserName, 
    editUserBio,
})(EditProfile);
