import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { getCategoryPosts, setPosts, getPosts_With_Images } from '../actions/postActions';
import { toggleSideNav, setPage, add_To_Recent_History, toggleAuthModal, togglePostModal } from '../actions/navActions';
import { setAlert } from '../actions/alertActions';

// Layout Wrapper
import Layout from '../components/layout/Layout';

// components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import TweetBox from '../components/TweetBox/TweetBox';
import Post from '../components/Post/Post';
import GettingStarted from '../components/common/GettingStarted';
import HowItWorks from '../components/common/HowItWorks';
import FAQ_Container from '../components/common/FAQ_Container';
import Contact_Widget from '../components/Widgets/Contact_Widget';

// Icons
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import phone_preview from '../utils/imgs/phone_preview.png'

const FeedPage = ({ 
    getCategoryPosts, 
    setPosts,
    getPosts_With_Images,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    toggleAuthModal,
    togglePostModal,
    setAlert, 
    auth, 
    nav: { 
        sideNav, 
        page, 
        postModal,
        campus_id,
        campus_name,
        campus_loading,
        campus_privacy
    }
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Check if user is verified
    const [checkedVerification, setCheckedVerification] = useState(false);
    const [checkedVerification_schoolEmail, setCheckedVerification_schoolEmail] = useState(false);

    // Posts
    const [posts, setFeedPosts] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Inifite scroll
    const [lastPageDoc, setLastPageDoc] = useState(null);
    const [noMorePosts, setNoMorePosts] = useState(false);
    const [showBottomSpinner, setShowBottomSpinner] = useState(false)

    // Campus Buy Sell Website Stuff
    const [checked_For_Redirect, set_Checked_For_Redirect] = useState(false);

    // URL and filter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("filter");
    const hostname = window.location.hostname;

    // Firebase collection ref
    const postsCollectionRef = collection(db, "posts");
    let q;
    // Construct a new query starting at the last visible document and fetch the next 3 posts.
    let nextQuery;
    
    if(campus_id.length > 0) {
        if(campus_name === 'OU') { // University of Oklahoma
            q = query(postsCollectionRef, where("campus_id", "==", "b7nqQGbbbzw08uh0MoPA"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(25));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "b7nqQGbbbzw08uh0MoPA"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        } 
        else if(campus_name === 'UT') { // University of Texas
            q = query(postsCollectionRef, where("campus_id", "==", "j4R70faL7jaXTR5Nzeex"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "j4R70faL7jaXTR5Nzeex"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else if(campus_name === 'SMU') { // Southern Methodist University
            q = query(postsCollectionRef, where("campus_id", "==", "fNie9ly0jB0cs3tZHQaQ"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "fNie9ly0jB0cs3tZHQaQ"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else if(campus_name === 'UF') { // University of Florida
            q = query(postsCollectionRef, where("campus_id", "==", "g4O5N3JOeQPiMxoSbCex"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "g4O5N3JOeQPiMxoSbCex"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else if(campus_name === 'UCLA') { // (UCLA) University of California, Los Angeles
            q = query(postsCollectionRef, where("campus_id", "==", "3Yeoq4slVvACXGTJLZqo"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "3Yeoq4slVvACXGTJLZqo"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else if(campus_name === 'Stanford') { // Stanford University
            q = query(postsCollectionRef, where("campus_id", "==", "HujlZDJLvvQGOSIsX1HX"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "HujlZDJLvvQGOSIsX1HX"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else if(campus_name === 'Duke') { // Duke University 
            q = query(postsCollectionRef, where("campus_id", "==", "JGYU2vQKslO94zmHoGjM"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("campus_id", "==", "JGYU2vQKslO94zmHoGjM"), where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
        else{
            q = query(postsCollectionRef, where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
            nextQuery = query(postsCollectionRef, where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
        }
    } else {
        q = query(postsCollectionRef, where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), limit(15));
        nextQuery = query(postsCollectionRef, where("post_commented_on", "==", null), orderBy('lastModified', 'desc'), startAfter(lastPageDoc || 0), limit(15));
    }

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;
    const notFullScreen = windowWidth <= 1170;
    
    useEffect(() => {
        setPage('home');
        add_To_Recent_History(url.pathname);  // current url
        
        // Display alert if the filter parameter is set to 'notify-posted'
        if(filter && filter === 'notify-posted') {
            setAlert('Your post was sent', 'okay')
        } 

    }, [filter]);

    if(!gotPosts && !campus_loading) {
        // Fetch initial posts
        onSnapshot(q, async (snapshot) => {
            console.log('UPDATING POSTS...');
            const tempPostList = snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

            // Recursively fetch comments of comments
            const fetchCommentsOfComments = async (comments) => {
                for (let i = 0; i < comments.length; i++) {
                const comment = comments[i];
                
                // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                
                // Execute the query and retrieve the query snapshot
                const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                
                // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                
                // Append the comments of comments to the current comment
                comment.commentsOfComments = commentsOfComments;
                
                // Recursively fetch comments of comments for the current comment
                await fetchCommentsOfComments(commentsOfComments);
                }
            };
            
            // Fetch comments of comments for the initial set of comments
            await fetchCommentsOfComments(tempPostList);

            setFeedPosts(tempPostList);
            setPosts(tempPostList);
            
            setGotPosts(true);

            // Get the last visible document for infinite scroll
            setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
        })
    }


    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {
        // Check if URL has a campus ID to redirect to after user completes verifiying their school email
        if(localStorage.redirect_to && campus_name.toLowerCase() === 'campus') {

            const temp_campus_id = localStorage.redirect_to;

            // Remove redirect_to token so user doesn't get redirected again on a future page load
            window.localStorage.removeItem("redirect_to");

            if(temp_campus_id === 'b7nqQGbbbzw08uh0MoPA') { // University of Oklahoma
                window.location.href = 'https://www.oubuysell.com';
            } 
            else if (temp_campus_id === 'j4R70faL7jaXTR5Nzeex') { // Univeristy of Texas
                window.location.href = 'https://www.utbuysell.com';
            }
            else if (temp_campus_id === 'fNie9ly0jB0cs3tZHQaQ') { // Southern Methodist University (SMU)
                window.location.href = 'https://www.smubuysell.com';
            }
            else if (temp_campus_id === 'g4O5N3JOeQPiMxoSbCex') { // Univeristy of Florida (UF)
                window.location.href = 'https://www.ufbuysell.com';
            }
            else if (temp_campus_id === '3Yeoq4slVvACXGTJLZqo') { // (UCLA) University of California, Los Angeles
                window.location.href = 'https://www.uclabuysell.com';
            }
            else if (temp_campus_id === 'HujlZDJLvvQGOSIsX1HX') { // Stanford University (Stanford)
                window.location.href = 'https://www.stanfordbuysell.com';
            }
            else if (temp_campus_id === 'JGYU2vQKslO94zmHoGjM') { // Duke Univeristy (Duke)
                window.location.href = 'https://www.dukebuysell.com';
            }

            console.log('REDIRECT URL ID')
            console.log(temp_campus_id)

        }
  
    }, [campus_name]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - 1 && !showBottomSpinner && !noMorePosts && gotPosts) {
            console.log('scrolling');
            loadMore();
        }
    }

    // Function to load more paginated posts
    const loadMore = () => {
        console.log("last", lastPageDoc);

        // Show spinner
        setShowBottomSpinner(true);

        // Fetch the next set of posts
        onSnapshot(nextQuery, async (snapshot) => {
            // If there are no more documents in the database to load
            if(snapshot.docs.length < 2) {
                setNoMorePosts(true);
            } else {

                console.log('UPDATING POSTS...');
                
                const tempPostList = [...posts, ...snapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}))];

                // Recursively fetch comments of comments
                const fetchCommentsOfComments = async (comments) => {
                    for (let i = 0; i < comments.length; i++) {
                    const comment = comments[i];
                    
                    // Create a query to retrieve comments where the post_commented_on field matches the comment ID, ordered by creation date in descending order
                    const commentOfCommentQuery = query(postsCollectionRef, where("post_commented_on", "==", comment._id), orderBy('createdAt', 'asc'));
                    
                    // Execute the query and retrieve the query snapshot
                    const commentOfCommentSnapshot = await getDocs(commentOfCommentQuery);
                    
                    // Map through the document snapshots in the query snapshot and extract the data and ID for each comment of comment
                    const commentsOfComments = commentOfCommentSnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));
                    
                    // Append the comments of comments to the current comment
                    comment.commentsOfComments = commentsOfComments;
                    
                    // Recursively fetch comments of comments for the current comment
                    await fetchCommentsOfComments(commentsOfComments);
                    }
                };
                
                // Fetch comments of comments for the initial set of comments
                await fetchCommentsOfComments(tempPostList);

                // Update the posts state with the new posts
                setFeedPosts(tempPostList);
    
                // Get the last visible document for the next load
                setLastPageDoc(snapshot.docs[snapshot.docs.length - 1]);
    
                // Hide the loading spinner
                setShowBottomSpinner(false);
            }

        })
    }

    // Handle create Post Modal Toggle
    const handleToggle_PostModal = () => {
        if(auth.user) {
            togglePostModal();
        } else {
            toggleAuthModal('post');
        }
        

    }

    // END - Infinite Scroll

    // Check if user is verified. If no redirect to verify page
    if(auth.user && !auth.user.verified && !checkedVerification) {
        window.location.href = '/verify';

        setCheckedVerification(true);
    }

    // Check if user's school email is verified. If no redirect to school email page
    if(auth.user && !auth.user.school_verified && !checkedVerification_schoolEmail && campus_name.toLowerCase() !== 'campus' && !campus_loading) {
        window.location.href = '/settings/account/school_email';

        setCheckedVerification_schoolEmail(true);
    }

    let postList;

    // Check if posts are null or not yet fetched
    if(posts === null || !gotPosts) {
        postList = <Spinner />;
    }
    else {
        if(posts.length > 0) {

            // Iterate through the posts array and render each post component
            postList = posts.map(post => {

                const renderPostRecursive = (postComments, parentPost) => {
                    
                    const lastComment = postComments[postComments.length - 1]; // Get the last comment

                    return (
                        <Fragment>
                            <Post key={lastComment._id} postDoc={lastComment} parentPost={parentPost} />

                            {/* Recursive call to render comments of comments */}
                            {lastComment.commentsOfComments && lastComment.commentsOfComments.length > 0 && (
                                <div className="comments-of-comments">
                                {renderPostRecursive(lastComment.commentsOfComments, lastComment)}
                                </div>
                            )}
                        </Fragment>
                    );

                };

                if(post.commentsOfComments && post.commentsOfComments.length > 0) {
                    return (
                        <div className='post_container'>
                            <Post key={post._id} postDoc={post} />

                            {renderPostRecursive(post.commentsOfComments, post)}
                        </div>
                    )
                } else {
                    return (
                        <Post key={post._id} postDoc={post} />
                    )
                }
                      

                    // return (
                    //     <Post key={post._id} post={post} />
                    // )

            })
        }
        else {
            // Display a message when there are no posts
            postList = (
                <div className="no-rides">
                    <h1 className='placeholder_title'>Be the first...</h1>
                    <p className='placeholder_text'>...to create a post, it's so easy!</p>

                    <Button 
                        onClick={handleToggle_PostModal}
                        variant="outlined" 
                        className="sidebar__tweet minWidth black"
                    >
                        Create A Post
                    </Button>
                    
                </div>
            );
        }
    }


    // Function to handle Mixpanel analytics
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Feed Page View");
    }

    // Trigger Mixpanel analytics on initial render in production mode
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    // -- Campus Buy Sell Website --

    const handle_Campus_Redirect = () => {
  
        if(auth.user.campus_id === 'b7nqQGbbbzw08uh0MoPA') { // University of Oklahoma
            window.open('https://www.oubuysell.com', '_blank');
        } 
        else if (auth.user.campus_id === 'j4R70faL7jaXTR5Nzeex') { // Univeristy of Texas
            window.open('https://www.utbuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'fNie9ly0jB0cs3tZHQaQ') { // Southern Methodist University (SMU)
            window.open('https://www.smubuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'g4O5N3JOeQPiMxoSbCex') { // Univeristy of Florida (UF)
            window.open('https://www.ufbuysell.com', '_blank');
        }
        else if (auth.user.campus_id === '3Yeoq4slVvACXGTJLZqo') { // (UCLA) University of California, Los Angeles
            window.open('https://www.uclabuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'HujlZDJLvvQGOSIsX1HX') { // Stanford University (Stanford)
            window.open('https://www.stanfordbuysell.com', '_blank');
        }
        else if (auth.user.campus_id === 'JGYU2vQKslO94zmHoGjM') { // Duke Univeristy (Duke)
            window.open('https://www.dukebuysell.com', '_blank');
        }
    }

    const handle_Join_Campus_Btn_Click = () => {
        if(auth.user) {
            window.location.href = '/search_schools';
        } else {
            toggleAuthModal('join campus');
        }
    }

    // -- END: Campus Buy Sell Website --

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="home">
                
                {campus_loading ? (
                    <Fragment>
                        <Spinner />
                    </Fragment>
                ) : (
                    <Fragment>
                        {campus_id === 'sWlhtpjZ3UFPMwFF4vJX' ? ( // Campus Buy Sell website
                            <Fragment>
                                <div className="no-rides" style={{paddingTop: '20px'}}>
                                    <h1 className='placeholder_title'>Welcome To Campus Buy Sell.</h1>
                                    <p className='placeholder_text'>The simplest way to Buy & Sell.</p>

                                    {auth.user ? (
                                        auth.user.school_verified ? (
                                            <Button 
                                                onClick={handle_Campus_Redirect}
                                                variant="outlined" 
                                                className="sidebar__tweet minWidth"
                                            >
                                                View Your Campus
                                            </Button>
                                        ) : (
                                            <Button 
                                                onClick={handle_Join_Campus_Btn_Click}
                                                variant="outlined" 
                                                className="sidebar__tweet minWidth heart_background"
                                            >
                                                Find Your Campus
                                            </Button>
                                        )
                                    ) : (
                                        <Button 
                                            onClick={handle_Join_Campus_Btn_Click}
                                            variant="outlined" 
                                            className="sidebar__tweet minWidth black"
                                        >
                                            Join Your Campus
                                        </Button>
                                    )}

                                    <div className='search_imgContainer' style={{display:'flex', justifyContent:'center', marginTop: '40px'}}>
                                        <img style={{maxWidth:'100%'}} src={phone_preview} />
                                    </div>
                                </div>

                                <div style={{borderTop: '1px solid #e6ecf0'}} className="post noHighlight">
                                    <HowItWorks hide_title />
                                </div>

                                <div className="post noHighlight">
                                    <GettingStarted hide_actionBtn />
                                </div>

                                <FAQ_Container />

                                {notFullScreen && <Contact_Widget notFullScreen={notFullScreen} />}

                                <div className="no-rides"></div>
                            </Fragment>
                        ) : (
                            <Fragment>

                                {/* Render GettingStarted component if user is authenticated and profile is not set up */}
                                {!auth.loading && auth.isAuthenticated && !auth.user.profile_set_up  ? (
                                    <div className="post noHighlight">
                                        <GettingStarted />
                                    </div>
                                ) : null}

                                {/* Render AddIcon for creating a post on tablet devices */}
                                {/* {!auth.loading && auth.user ? (
                                    isTablet && (
                                        <Link to={`/create/post?goBack=${page}`} id="fixed-button" className="show">
                                            <AddIcon />
                                        </Link>
                                    )
                                ) : null} */}

                                {!auth.loading && !auth.isAuthenticated && (
                                    <div style={{padding: '12px 16px', background: '#fff'}} className="btnRow">
                                        <Button 
                                            onClick={toggleAuthModal}
                                            variant="outlined" 
                                            className="sidebar__tweet ghost minWidth noMargin"
                                        >
                                            Log In
                                        </Button>
                                        <Button 
                                            onClick={toggleAuthModal}
                                            variant="outlined" 
                                            className="sidebar__tweet minWidth noMargin"
                                        >
                                            Join Group
                                        </Button>
                                    </div>
                                )}

                                {!auth.loading && !auth.isAuthenticated ? (
                                    <div style={{borderTop: '1px solid #e6ecf0'}} className="post noHighlight">
                                        <HowItWorks />
                                    </div>
                                ) : null}

                                {(campus_privacy === 'private' && !auth.loading && !auth.isAuthenticated) ? (
                                    <Fragment>
                                        <div className="no-rides">
                                            <h1 className='placeholder_title'>This community is private.</h1>
                                            <p className='placeholder_text'>Click to join the group and view / participate in discussions.</p>

                                            <Button 
                                                onClick={() => toggleAuthModal('join campus')}
                                                variant="outlined" 
                                                className="sidebar__tweet minWidth black"
                                            >
                                                Click to Join
                                            </Button>
                                        </div>

                                        <div className="no-rides"></div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {/* Search Block here */}
                                        <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                                            <span>Find what you need</span>
                                        </div>

                                        <div style={{height: 'fit-content', width: '100%', marginTop: '15px', display: 'flex', boxSizing: 'border-box', overflowX: 'scroll', overflowY: 'auto', paddingBottom: '12px', borderBottom: '1px solid #e6ecf0', background: '#fff'}}>
                                            <a href="/search?q=Tickets" style={{textDecoration: 'none', color: '#000'}}>
                                                <div className='circular_tab'>
                                                    <div className='circular_tab_img_container'>
                                                        <img src="https://cdn-icons-png.flaticon.com/512/235/235873.png" />
                                                    </div>
                                                    <div className='circular_tab_text'>
                                                        <span>
                                                            Tickets
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/search?q=Textbooks" style={{textDecoration: 'none', color: '#000'}}>
                                                <div className='circular_tab'>
                                                    <div className='circular_tab_img_container'>
                                                        <img src="https://cdn-icons-png.flaticon.com/512/3616/3616898.png" />
                                                    </div>
                                                    <div className='circular_tab_text'>
                                                        <span>
                                                            Textbooks
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/search?q=Clothing" style={{textDecoration: 'none', color: '#000'}}>
                                                <div className='circular_tab'>
                                                    <div className='circular_tab_img_container'>
                                                        <img src="https://www.shareicon.net/download/2016/08/18/809208_shirt_512x512.png" />
                                                    </div>
                                                    <div className='circular_tab_text'>
                                                        <span>
                                                            Clothing
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                            <a href="/search?q=Computers" style={{textDecoration: 'none', color: '#000'}}>
                                                <div className='circular_tab'>
                                                    <div className='circular_tab_img_container'>
                                                        <img src="https://icon-library.com/images/187503.png" />
                                                    </div>
                                                    <div className='circular_tab_text'>
                                                        <span>
                                                            Computers
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>

                                        {/* Render TweetBox component if user is authenticated and postModal is false */}
                                        {!postModal ? (
                                            <TweetBox isTablet={isMobile} mobile_redirect={isMobile} />
                                        ) : null}

                                        <div style={{background: '#e6ecf0', height: '1px'}}></div>
                                        
                                        {/* Render the postList */}
                                        {postList}

                                        {/* Render a spinner at the bottom if there are more posts to load */}
                                        {showBottomSpinner && !noMorePosts && <Spinner />}
                                        {/* <button onClick={loadMore}>More Posts</button> */}
                                        
                                        {/* Display a message when there are no more posts */}
                                        <div className="no-rides">{(noMorePosts && posts.length !== 0) && "You've reached the end."}</div>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}

            </Layout>
        </Fragment>
    )
}

FeedPage.propTypes = {
    getCategoryPosts: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired,
    getPosts_With_Images: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    togglePostModal: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    nav: state.nav,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    getCategoryPosts, 
    setPosts,
    getPosts_With_Images,
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    toggleAuthModal,
    togglePostModal,
    setAlert 
})(FeedPage);
