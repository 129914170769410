import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Firebase


// Redux
import { connect } from 'react-redux';

// Actions

// Components

// Icons
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import RefreshIcon from '@material-ui/icons/Refresh';

const Empty_Card = ({
    loadMore,
    setCanLoadMore,
    canLoadMore,
    page,
    post: {
        last_page_doc,
        show_bottom_spinner
    }

}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    const handleLoadMore = () => {
        if(canLoadMore) {
            console.log('CLICK 1')
            loadMore(last_page_doc);
        }

        console.log('canLoadMore', canLoadMore)

        console.log('last_page_doc', last_page_doc)
    }

    return (
        <div className='product_media_image_container icon_36' onClick={handleLoadMore} style={{ flexDirection: 'column', textAlign: 'center', borderRadius: '8px', fontWeight: 'bold', color: 'rgb(83, 100, 113)', fontSize: '13px'}}>
            {canLoadMore ? (
                <Fragment>
                    <RefreshIcon />
                    Click To Load More..
                </Fragment>  
            ) : 'Loading'}
                        
        </div>   
    )
}

Empty_Card.propTypes = {
    post: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    post: state.post
})

export default connect(mapStateToProps, { })(Empty_Card);