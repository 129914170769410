class BrowseHistory { // Stack data structure

    constructor(oldHistory) {
        this.recentPages = [];
        this.count = 0;
        this.createdAt = null;

        if(oldHistory) { 
            this.recentPages = oldHistory.recentPages;
            this.count = oldHistory.recentPages.length;
            this.createdAt = oldHistory.createdAt;
        }
        
    }

    push(stringURL) {
        this.recentPages[this.count] = stringURL;
        this.count += 1

        return this.count - 1 // Return the index that just got filled

    };

    pop() {
        if(this.count == 0) return undefined
        let lastPage = this.recentPages.pop()
        this.count -= 1

        return lastPage
    };
    
    peek() {
       return this.recentPages[this.count - 1]
    };

    isEmpty() {
        return this.count == 0
     };

     setTimestamp() {
        const timeSinceEpoch = Date.now();
        this.createdAt = timeSinceEpoch;

        return timeSinceEpoch
     };
};

export default BrowseHistory;