import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUnreadCount } from '../../actions/notificationActions';
import { getUnreadChats } from '../../actions/chatActions';

import { Link } from 'react-router-dom';


const SidebarOption = ({ 
    getUnreadCount,
    getUnreadChats,
    active, 
    toggle, 
    handleSlideMenu, 
    text, 
    Icon, 
    link, 
    auth, 
    nav, 
    notification, 
    chat 
}) => {

    // State to determine whether to display the badge or not
    const [showBadge, setShowBadge] = useState(false);

    // State to hold the value to be displayed on the badge
    const [badgeValue, setBadgeValue] = useState(0);

    // State to track if the badge value has been lowered
    const [loweredBadge, setLoweredBadge] = useState(false);

    // Check if the user has unread notifications or messages
    useEffect(() => {
        if(auth.user) {
            if(text.toLowerCase() === 'notifications') {
                console.log(' FETCHING NOTIFY NOTIFY #');
                console.log(notification.read)

                // If there are unread notifications
                if(!notification.read) {
                    console.log(' SET NOTIFY NOTIFY TRUE')

                    // Display the badge
                    setShowBadge(true);

                    // Fetch the count of unread notifications
                    getUnreadCount(auth.user._id);
                } else {
                    console.log(' SET NOTIFY NOTIFY FALSE')

                    // Hide the badge if all notifications are read
                    setShowBadge(false);
                }

            }

            if(text.toLowerCase() === 'messages') {
                
                // Always display the badge for messages
                setShowBadge(true);

                // Fetch the count of unread messages
                getUnreadChats();
                // getUnreadCountM();
            }
        }
    }, [notification.notifications, notification.read, auth.user, nav.page]);

    // Lower the badge value if the chat is opened
    useEffect(() => {
        if(text.toLowerCase() === 'messages' && chat.chat && !loweredBadge) {
            
            // Lower the badge value by 1
            lowerBadgeValue();
        }
    }, [chat.chat])

    // Update the badge value based on the unread notifications or messages count
    useEffect(() => {
        if(text.toLowerCase() === 'notifications') {

            // Update the badge value with the count of unread notifications
            setBadgeValue(notification.num_unread);
        }

        if(text.toLowerCase() === 'messages') {

            // Update the badge value with the count of unread messages
            setBadgeValue(chat.num_unread_chats);
        }
    }, [notification.num_unread, chat.num_unread_chats])

    // const getUnreadCountN = async () => {
    //     if(auth.user) {
    //         const unreadNotifications = await axios.get(`/api/notifications/unread/${auth.user._id}`);
        
    //         console.log('UNREAD NOTIFICATIONS #');
    //         console.log(unreadNotifications.data);

    //         setBadgeValue(unreadNotifications.data);
    //     }
    // }

    // const getUnreadCountM = async () => {
    //     if(auth.user) {
    //         const unreadMessages = await axios.get(`/api/chats/unread/${auth.user._id}`);
        
    //         console.log('UNREAD MESSAGES #');
    //         console.log(unreadMessages.data);

    //         setBadgeValue(unreadMessages.data);
    //     }
    // }

    // Lower the badge value by 1
    const lowerBadgeValue = () => {
        console.log('LOWER BADGE #');

        // Decrease the badge value by 1
        const newValue = badgeValue - 1;

        console.log(newValue);
        
        // Update the badge value state
        setBadgeValue(newValue);

        // Set the flag to indicate that the badge value has been lowered
        setLoweredBadge(true);
    }

    return (
        <a href={link} onClick={toggle && handleSlideMenu} style={{textDecoration:'none'}}>
            <div className={`sidebarOption ${active && 'sidebarOption--active'}`}>
                
                {/* Icon & Badge value */}
                <div className="sidebarOption__icon">
                    {Icon && <Icon />}
                    {showBadge && badgeValue > 0 ? (
                        <span className="notification-badge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* SidebarOption Text */}
                <h2>{text}</h2>
                
            </div>
        </a>
    )
}

SidebarOption.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
    notification: PropTypes.object.isRequired, // Prop type validation for notification
    chat: PropTypes.object.isRequired, // Prop type validation for chat
    getUnreadCount: PropTypes.func.isRequired, // Prop type validation for function to get unread notifications
    getUnreadChats: PropTypes.func.isRequired // Prop type validation for function to get unread chats
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    notification: state.notification,
    chat: state.chat
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    getUnreadCount, 
    getUnreadChats 
})(SidebarOption);
