export default [
    {
        value: 'January',
    },
    {
        value: 'Febuary',
    },
    {
        value: 'March',
    },
    {
        value: 'April',
    },
    {
        value: 'May',
    },
    {
        value: 'June',
    },
    {
        value: 'July',
    },
    {
        value: 'August',
    },
    {
        value: 'September',
    },
    {
        value: 'October',
    },
    {
        value: 'November',
    },
    {
        value: 'December',
    }
];
