import CategoryIcon from '@material-ui/icons/Category';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SchoolIcon from '@material-ui/icons/School';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FaceIcon from '@material-ui/icons/Face';
import WeekendIcon from '@material-ui/icons/Weekend';
import HeadsetIcon from '@material-ui/icons/Headset';
import BuildIcon from '@material-ui/icons/Build';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import BrushIcon from '@material-ui/icons/Brush';
import ComputerIcon from '@material-ui/icons/Computer';
import MicIcon from '@material-ui/icons/Mic';
import HearingIcon from '@material-ui/icons/Hearing';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import DiscFullIcon from '@material-ui/icons/DiscFull';
import WatchIcon from '@material-ui/icons/Watch';
import PrintIcon from '@material-ui/icons/Print';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import CreateIcon from '@material-ui/icons/Create';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import ExtensionIcon from '@material-ui/icons/Extension';
import KingBedIcon from '@material-ui/icons/KingBed';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

// Outlined icons (icon2)
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import WeekendOutlinedIcon from '@material-ui/icons/WeekendOutlined';
import SpeakerOutlinedIcon from '@material-ui/icons/SpeakerOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import HeadsetOutlinedIcon from '@material-ui/icons/HeadsetOutlined';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import BrushOutlinedIcon from '@material-ui/icons/BrushOutlined';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import TramOutlinedIcon from '@material-ui/icons/TramOutlined';
import FitnessCenterOutlinedIcon from '@material-ui/icons/FitnessCenterOutlined';
import HealingOutlinedIcon from '@material-ui/icons/HealingOutlined';
import LocalFloristOutlinedIcon from '@material-ui/icons/LocalFloristOutlined';
import TvOutlinedIcon from '@material-ui/icons/TvOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import VideogameAssetOutlinedIcon from '@material-ui/icons/VideogameAssetOutlined';
import DiscFullOutlinedIcon from '@material-ui/icons/DiscFullOutlined';
import WatchOutlinedIcon from '@material-ui/icons/WatchOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import SportsCricketOutlinedIcon from '@material-ui/icons/SportsCricketOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import MusicNoteOutlinedIcon from '@material-ui/icons/MusicNoteOutlined';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import MotorcycleOutlinedIcon from '@material-ui/icons/MotorcycleOutlined';
import PetsOutlinedIcon from '@material-ui/icons/PetsOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined';

export default [
    {
        text: 'All categories',
        value: 'all',
        icon: <CategoryIcon fontSize="small" />,
        icon2: <CategoryOutlinedIcon fontSize="small" />
    },
    {
        text: 'Audio & Speakers',
        value: 'audio & speakers',
        icon: <MicIcon fontSize="small" />,
        icon2: <MicNoneOutlinedIcon fontSize="small" />
    },
    {
        text: 'Auto parts & Accessories',
        value: 'auto parts & accessories',
        icon: <BuildIcon fontSize="small" />,
        icon2: <BuildOutlinedIcon fontSize="small" />
    },
    {
        text: 'Books, Movies, & Music',
        value: 'books, movies, & music',
        icon: <ImportContactsIcon  fontSize="small" />,
        icon2: <ImportContactsIcon  fontSize="small" />
    },
    {
        text: 'Clothing & Shoes',
        value: 'clothing, shoes, & accessories',
        icon: <FaceIcon fontSize="small" />,
        icon2: <FaceOutlinedIcon fontSize="small" />
    },
    {
        text: 'Cars & Trucks',
        value: 'cars & trucks',
        icon: <DirectionsCarIcon fontSize="small" />,
        icon2: <DirectionsCarOutlinedIcon fontSize="small" />
    },
    {
        text: 'Cameras & Photography',
        value: 'cameras & photography',
        icon: <PhotoCameraIcon fontSize="small" />,
        icon2: <PhotoCameraOutlinedIcon fontSize="small" />
    },
    {
        text: 'Cell phones & Accessories',
        value: 'cell phones & accessories',
        icon: <PhoneIphoneIcon fontSize="small" />,
        icon2: <PhoneIphoneOutlinedIcon fontSize="small" />
    },
    {
        text: 'Collectibles & Art',
        value: 'collectibles & art',
        icon: <BrushIcon fontSize="small" />,
        icon2: <BrushOutlinedIcon fontSize="small" />
    },
    {
        text: 'Computers & Accessories',
        value: 'computers & accessories',
        icon: <ComputerIcon fontSize="small" />,
        icon2: <ComputerIcon fontSize="small" />
    },
    {
        text: 'Drones',
        value: 'drones',
        icon: <TramOutlinedIcon fontSize="small" />,
        icon2: <TramOutlinedIcon fontSize="small" />
    },
    {
        text: 'Exercise',
        value: 'exercise',
        icon: <FitnessCenterOutlinedIcon fontSize="small" />,
        icon2: <FitnessCenterOutlinedIcon fontSize="small" />
    },
    {
        text: 'Jewelry & Accessories',
        value: 'jewelry & accessories',
        icon: <HearingIcon fontSize="small" />,
        icon2: <HearingIcon fontSize="small" />
    },
    {
        text: 'Furniture',
        value: 'furniture',
        icon: <WeekendIcon fontSize="small" />,
        icon2: <WeekendOutlinedIcon fontSize="small" />
    },
    {
        text: 'Health & Beauty',
        value: 'health & beauty',
        icon: <HealingOutlinedIcon fontSize="small" />,
        icon2: <HealingOutlinedIcon fontSize="small" />
    },
    {
        text: 'Housing & Apartments',
        value: 'housing & apartments',
        icon: <HomeWorkIcon fontSize="small" />,
        icon2: <HomeWorkOutlinedIcon fontSize="small" />
    },
    {
        text: 'Home decor',
        value: 'home decor',
        icon: <LocalFloristIcon fontSize="small" />,
        icon2: <LocalFloristOutlinedIcon fontSize="small" />
    },
    {
        text: 'Household',
        value: 'household',
        icon: <KingBedIcon fontSize="small" />,
        icon2: <KingBedOutlinedIcon fontSize="small" />
    },
    {
        text: 'Kitchen & Dining',
        value: 'kitchen & dining',
        icon: <HomeWorkIcon fontSize="small" />,
        icon2: <HomeWorkOutlinedIcon fontSize="small" />
    },
    {
        text: 'Musical instruments',
        value: 'musical instruments',
        icon: <MusicNoteIcon fontSize="small" />,
        icon2: <MusicNoteOutlinedIcon fontSize="small" />
    },
    {
        text: 'Motorcycles',
        value: 'motorcycles',
        icon: <MotorcycleOutlinedIcon fontSize="small" />,
        icon2: <MotorcycleOutlinedIcon fontSize="small" />
    },
    {
        text: 'Pet supplies',
        value: 'pet supplies',
        icon: <PetsOutlinedIcon fontSize="small" />,
        icon2: <PetsOutlinedIcon fontSize="small" />
    },
    {
        text: 'School supplies',
        value: 'school supplies',
        icon: <CreateIcon fontSize="small" />,
        icon2: <CreateOutlinedIcon fontSize="small" />
    },
    {
        text: 'Sports & Outdoors',
        value: 'sports & outdoors',
        icon: <SportsCricketIcon fontSize="small" />,
        icon2: <SportsCricketOutlinedIcon fontSize="small" />
    },
    {
        text: 'Tickets',
        value: 'tickets',
        icon: <ConfirmationNumberIcon  fontSize="small" />,
        icon2: <ConfirmationNumberOutlinedIcon  fontSize="small" />
    },
    {
        text: 'Tires & Rims',
        value: 'tires & rims',
        icon: <DirectionsCarIcon  fontSize="small" />,
        icon2: <DirectionsCarOutlinedIcon  fontSize="small" />
    },
    {
        text: 'Toys, Games, & Hobbies',
        value: 'toys, games, & hobbies',
        icon: <ExtensionIcon fontSize="small" />,
        icon2: <ExtensionOutlinedIcon fontSize="small" />
    },
    {
        text: 'Trading cards',
        value: 'trading cards',
        icon: <PaymentIcon fontSize="small" />,
        icon2: <PaymentIcon fontSize="small" />
    },
    {
        text: 'TVs & Media players',
        value: 'tvs & media players',
        icon: <TvOutlinedIcon fontSize="small" />,
        icon2: <TvOutlinedIcon fontSize="small" />
    },
    {
        text: 'Video games & Consoles',
        value: 'video games & consoles',
        icon: <SportsEsportsIcon  fontSize="small" />,
        icon2: <SportsEsportsOutlinedIcon  fontSize="small" />
    },
    {
        text: 'Virtual reality',
        value: 'virtual reality',
        icon: <DiscFullIcon  fontSize="small" />,
        icon2: <DiscFullOutlinedIcon  fontSize="small" />
    },
    {
        text: 'Wearables',
        value: 'wearables',
        icon: <WatchIcon  fontSize="small" />,
        icon2: <WatchOutlinedIcon  fontSize="small" />
    },
    {
        text: '3D Printers & Supplies',
        value: '3d printers & supplies',
        icon: <PrintIcon  fontSize="small" />,
        icon2: <PrintOutlinedIcon  fontSize="small" />
    }
    
];
