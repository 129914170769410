import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav } from '../../actions/navActions';

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import GoogleBtn from '../common/GoogleBtn';
import SidebarProfileWidget from './SidebarProfileWidget';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonIcon from '@material-ui/icons/Person';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import FastfoodOutlinedIcon from '@material-ui/icons/FastfoodOutlined';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import SidebarOption from './SidebarOption';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';

import campusbuysell_logo from '../../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';
import uf_logo from '../../utils/imgs/uf_logo.png';
import ucla_logo from '../../utils/imgs/ucla_logo.png';
import stanford_logo from '../../utils/imgs/stanford_logo.png';
import duke_logo from '../../utils/imgs/duke_logo.png';

const SidebarMobile = ({ 
    slideMenu, 
    handleSlideMenu, 
    auth: { 
        user,
        modalUser,
        loading,
        isAuthenticated
    },
    nav: { 
        page,
        // - Campus Logic -
        campus_name,
        campus_loading,
        // - END: Campus Logic -
        // - Security Logic -
        country_code,
        show_firewall,
        geo_loading
        // - Security Logic -
    }
}) => {

    // State to determine highlighted sidebar option
    const [navHighlight, setNavHighlight] = useState(null);

    // --- Update the navHighlight based on the current page
    useEffect(() => {
        if(page === 'home') {
            setNavHighlight('home');
        }
        // if(page === 'howitworks') {
        //     setNavHighlight('howitworks');
        // }
        if(page === 'notifications') {
            setNavHighlight('notifications');
        }

        if(page === 'messages') {
            setNavHighlight('messages');
        }

        if(page === 'saved') {
            setNavHighlight('saved');
        }

        if(page === 'profile likes') {
            setNavHighlight('saved');
        }

        if(page === 'profile') {
            setNavHighlight('profile');
        }

        if(page === 'settings') {
            setNavHighlight('settings');
        }

        // - Marketplace ---
        
        if(page === 'marketplace' || page === 'product detail' || page === 'cart' || page === 'checkout' || page === 'collection') {
            setNavHighlight('grocery');
        }
    }, [page]);

    // Get User info
    let username = null;

    // Check if the user is logged in and set the username
    if(user) {
        username = user.username;
    }

    let isMyProfile = false;

    // Check if the current highlighted navigation is 'profile'
    if(navHighlight === "profile") {
        if(user && modalUser && user._id !== modalUser._id) {
            isMyProfile = false;
        } else {
            isMyProfile = true;
        }
    }

    let user_name;

    if(!loading && isAuthenticated) {
        user_name = `${user.first_name} ${user.last_name && user.last_name}`
    }

    // --- Campus Logis ----

    let app_logo;

    if(campus_name === 'Campus') {
            
        app_logo = campusbuysell_logo
    }
    else if(campus_name === 'OU') {
            
        app_logo = ou_logo
    }
    else if(campus_name === 'UT') {
        app_logo = ut_logo
    }
    else if(campus_name === 'SMU') {
        app_logo = smu_logo
    }
    else if(campus_name === 'UF') {
        app_logo = uf_logo
    }
    else if(campus_name === 'UCLA') {
        app_logo = ucla_logo
    }
    else if(campus_name === 'Stanford') {
        app_logo = stanford_logo
    }
    else if(campus_name === 'Duke') {
        app_logo = duke_logo
    }

    // --- END: Campus Logis ----

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(user && user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    // --- Security Function --- 

    if(slideMenu && !geo_loading && (country_code !== 'US' || show_firewall)) {
        handleSlideMenu();
        window.location.href = '/404';
    }

    // --- END: Security Function --- 

    return (
        /* --- Stucture --- */

            /* Twitter logo or icon */
            /* Sidebar options */
            /* Display additional options if user is authenticated */
            /* Sidebar options */
            
            /* Close button */

        /* --- */
        
        <div className={slideMenu ? "nav open": "nav"}>
            <div className="sidebar-mobile" style={{display:'flex', height:'100vh', flexDirection:'column'}}>

                <div onClick={() => window.location = '/'} style={{cursor: 'pointer'}}>
                    <img className={`sidebar__twitterIcon ${campus_name === 'Campus' ? 'big_logo' : 'small_logo'}`} src={app_logo} alt="logo" />
                </div>

                {/* --- Sidebar options */}

                {!campus_loading && (
                    <Fragment>
                        {campus_name === 'Campus' ? (
                            <Fragment>
                                <SidebarOption toggle active={navHighlight === "home"} Icon={navHighlight === "home" ? HomeIcon : HomeOutlinedIcon} text="Home" link="/home" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption toggle active={navHighlight === "search"} Icon={SearchOutlinedIcon} text="Search" link="/search_schools" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption active={navHighlight === "settings"} Icon={SettingsOutlinedIcon} text={"Settings"} link="/settings" />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <SidebarOption toggle active={navHighlight === "home"} Icon={navHighlight === "home" ? HomeIcon : HomeOutlinedIcon} text="Buy/Sell" link="/home" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption toggle active={navHighlight === "search"} Icon={SearchOutlinedIcon} text="Search" link="/search" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption toggle active={navHighlight === "notifications"} Icon={navHighlight === "notifications" ? NotificationsIcon : NotificationsNoneIcon} text="Notifications" link="/notifications" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption toggle active={navHighlight === "messages"} Icon={navHighlight === "messages" ? MailIcon : MailOutlineIcon} text="Messages" link="/messages" handleSlideMenu={handleSlideMenu} />
                                <SidebarOption toggle active={navHighlight === "saved"} Icon={navHighlight === "saved" ? FavoriteIcon : FavoriteBorderIcon} text="Saved" link={user ? `/${user.username}?show=likes` : '/saved'} handleSlideMenu={handleSlideMenu} />

                                {!loading && isAuthenticated && (
                                    <SidebarOption active={isMyProfile} Icon={isMyProfile ? PersonIcon : PersonOutlineIcon} text="Profile" link={username ? `/${username}` : '/xyz'} />
                                )}

                                {!loading && !isAuthenticated && (
                                    <SidebarOption active={navHighlight === "settings"} Icon={!loading && isAuthenticated ? MoreHorizIcon : SettingsOutlinedIcon} text={!loading && isAuthenticated ? "More" : "Settings"} link="/settings" />
                                )}
                                <hr className="full" />
                            </Fragment>
                        )}
                    </Fragment>
                )}
                
                {!loading && (
                    <Fragment>
                        {!isAuthenticated ? (
                             <div style={{boxSizing: 'border-box', padding: '0 10px'}}>

                                <p style={{fontSize: '12px', color: 'rgb(128, 128, 128)', fontWeight: 600, margin: '10px 0' }}>Log in takes less than a few seconds</p>
                                
                                <GoogleBtn />

                                <div onClick={() => window.location = '/user_registration'} className='delivery_widget authPage grey_background'>
                                    <div style={{height: '90%', margin: '5px 5px 5px -5px', color: '#000', padding: '3px', borderRadius: '50%', border: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                                        <MailIcon />
                                    </div>

                                    <span style={{fontWeight: 'bold'}}>
                                        Continue with Email
                                    </span>
                                    
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                {(!campus_loading && campus_name !== 'Campus') && (
                                    <SidebarProfileWidget sidebarMobile />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
                
            </div>
            <a href="#" className="close" onClick={handleSlideMenu}>
                <CloseIcon />
            </a>
        </div>
    )
}

SidebarMobile.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
})

// Connect the SidebarMobile component to the Redux store and export it
export default connect(mapStateToProps, null)(SidebarMobile);
