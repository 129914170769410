import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Blurhash } from 'react-blurhash';

// Redux
import { connect } from 'react-redux';

// Icons = material UI

const ImgElement = ({img_obj}) => {

   const [imgLoaded, setImgLoaded] = useState(false);

   // --- ImageKit (img optimization service) ---
   
    // Replace the firebase URL with ImageKit.io URL-endpoint
    const optimized_img_path = img_obj.img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");

   useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImgLoaded(true)
        }
        img.src = optimized_img_path
   }, [img_obj])

   const maxHeight = 200;
            
    // Get the original dimensions of the image
    var originalWidth = img_obj.width;
    var originalHeight = img_obj.height;

    // Calculate the scaling factor based on the max height
    var scale = maxHeight / originalHeight;

    // Calculate the new dimensions based on the scaling factor
    var newWidth = originalWidth * scale;
    var newHeight = maxHeight;

  return (
    <Fragment>
        {!imgLoaded && (
            <Blurhash
                hash="L8I;kmVf#t8^$+RktlpI00t+pc.T"
                width={newWidth}
                height={newHeight}
                resolutionX={32}
                resolutionY={32}
                punch={1} />
        )}
        {imgLoaded && (
            <img 
                loading="lazy" 
                src={optimized_img_path} 
                alt="img" 
            />
        )}
    </Fragment>
  )
}

ImgElement.propTypes = {
}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop

});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
})(ImgElement);