import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';

// Actions
import { getUserById } from '../../../actions/authActions';

import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

const UserTable = ({ 
    getUserById, 
    toggleMessageModal, 
    auth: {
        loading, 
        users, 
        modalUser
    }, 
    auth,
    nav: { 
        page 
    }, 
    post 
}) => {  

    // Function to navigate to a user's profile
    const goToProfile = (username) => {
        window.location.href = `/${username}`;
    }

    // Function to handle messaging a user
    const messageTodo = (id) => {
        getUserById(id);
        toggleMessageModal();
    }

    let userList;

    // Check if the page is 'likes'
    if(page === 'likes') {

        // Check if the post data is null or still loading
        if(post.post === null || post.loading) {
            // Display a loading spinner
            userList = <Spinner />; 
        } else {

            // Check if there are any likes on the post
            if(post.post.likes.length > 0) {
                
                // Map through the list of likes and generate user blocks
                userList = post.post.likes.map(user => (
                    // User Block
                    <div key={user.user._id} className="userTable__block">

                        {/* User Avatar - left */}
                        <div onClick={() => goToProfile(user.user.username)} className="userTable__avatar likes">

                            {user.user.img ? (
                                <Avatar src={user.user.img} />
                            ) : (
                                <DefaultAvatar name={user.user && `${user.user.first_name} ${user.user.last_name}`} />
                            )}
                        </div>

                        {/* Main section of User Block - right */}
                        <div className="userTable__info--container">

                            {/* Display the user's name and redirect onClick to their profile */}
                            <div onClick={() => goToProfile(user.user.username)} className="userTable__info likes">
                                <h3>
                                    {user.user.first_name}{' '}{user.user.last_name}
                                    <span className="userTable__infoSpecial">
                                        <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                                    </span>
                                </h3>
                                <p>@{user.user.username}</p>
                            </div>

                            {/* Check if the authenticated user is not the same as the liked user */}
                            {auth.user && (user.user._id !== auth.user._id) ? (
                                // Display a message button to initiate a conversation
                                <div onClick={() => messageTodo(user.user._id)}>
                                    <Button className="tweetBox__tweetButton">Message</Button>
                                </div>
                            ) : null}

                            {/* Check if there is no authenticated user */}
                            {!auth.user && (
                                // Display a message button to initiate a conversation
                                <div onClick={() => messageTodo(user.user._id)}>
                                    <Button className="tweetBox__tweetButton">Message</Button>
                                </div>
                            )}
                        </div>
                    </div>
                ));
            } else {

                // Display a message when there are no likes
                userList = <h3>No Users</h3>
            }
        }
    } else {
        // Check if the users data is null or still loading
        if(users === null || loading) {
            // Display a loading spinner
            userList = <Spinner />; 
        } else {
            // Check if there are any users
            if(users.length > 0) {
                
                // Map through the list of users and generate user blocks
                userList = users.map(user => (
                    <div key={user._id} onClick={() => getUserById(user._id)} className="userTable__block">

                        {/* User Avatar - left */}
                        <div className="userTable__avatar">

                            {user.img ? (
                                <Avatar src={user.img} />
                            ) : (
                                <DefaultAvatar name={user && `${user.first_name} ${user.last_name}`} />
                            )}
                        </div>

                        {/* Display the user's name & username */}
                        <div className="userTable__info">
                            <h3>
                                {user.first_name}{' '}{user.last_name}
                                <span className="userTable__infoSpecial">
                                    <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                                </span>
                            </h3>
                            <p>@{user.username}</p>
                        </div>

                        {/* Show if User Block has been clicked */}
                        <div className="userTable__active">

                            {/* Check if the current user is the same as the selected user */}
                            {modalUser && (user._id === modalUser._id) && <CheckIcon />}
                        </div>
                    </div>
                ));
            } else {

                // Display a message when there are no users
                userList = <h3>No Users</h3>
            }
        }
    }

    return (
        <Fragment>
            {userList}
        </Fragment>
    )
}

UserTable.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
    nav: PropTypes.object.isRequired, // Prop: Object representing navigation state
    post: PropTypes.object.isRequired, // Prop: Object representing post state
    getUserById: PropTypes.func.isRequired, // Required prop: Function to get user by ID
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    post: state.post
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    getUserById 
})(UserTable);
