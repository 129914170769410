import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import ProfileDropdown from '../Dropdowns/ProfileDropdown';
import AuthDropdown from '../Dropdowns/AuthDropdown';

const Avatar_For_Headers = ({
    auth
}) => {

    // Toggle Dropdwon
    const [profileDropdown, setProfileDropdown] = useState(false);
    const [authDropdown, setAuthDropdown] = useState(false);

    // Close correct Dropdowns if logged in or not
    useEffect(() => {
        if(auth.user){

            if(authDropdown) setAuthDropdown(false);
        } else {
            if(profileDropdown) setProfileDropdown(false);
        }

    }, [auth.user]);

    // --- Get Users Full Name ---
    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    // --- END: Get Users Full Name ---

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path = '';

    if(auth.user && auth.user.img) {

        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <Fragment>
            {!auth.loading && auth.user ? (
                <div onClick={() => setProfileDropdown(!profileDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                    {auth.user.img ? (
                        <Avatar src={optimized_profile_img_path} />
                    ) : (
                        <DefaultAvatar name={user_name} />
                    )}
                </div>
            ) : (
                <div onClick={() => setAuthDropdown(!authDropdown)} className="post__avatar layoutHeader" style={{marginLeft: '0px', marginTop:'0', marginBottom: '0px'}}>
                    <Avatar />
                </div>
            )}

            <ProfileDropdown setProfileDropdown={setProfileDropdown} profileDropdown={profileDropdown} />

            <AuthDropdown setAuthDropdown={setAuthDropdown} authDropdown={authDropdown} />
        </Fragment>
    )
}

Avatar_For_Headers.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the authentication state
    auth: state.auth,
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component

})(Avatar_For_Headers);