import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../../../actions/alertActions';
import { addVerificationCode, checkVerificationCode } from '../../../actions/verificationActions';

// Components - material UI
import { Button } from '@material-ui/core';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LockIcon from '@material-ui/icons/Lock';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// Initial State 
const initialState = {
    verification_code: ''
};

const Verify_School_Email = ({
    auth: {
        user,
        isAuthenticated,
        loading
    },
    nav: {
        campus_name
    },
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    setAlert, 
    goBack,
    addVerificationCode, 
    checkVerificationCode
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    // States for form data
    const [formData, setFormData] = useState(initialState); 

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const url_Email = url.searchParams.get("email");
    const url_Campus_Id = url.searchParams.get("campus");

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(user){
            getUnreadCount(user._id);
            getUnreadChats();
        }

    }, [notification.notifications, user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])
    

    // Send verification email with PIN code
    useEffect(() => {
        
        if(isAuthenticated && !loading && url_Email) {
            addVerificationCode(campus_name, url_Email);
        }

    }, [isAuthenticated, url_Email]);

    const { 
        verification_code
     } = formData;

     // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;


    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const todo = (e) => {
        e.preventDefault();
        
        if(verification_code !== '') {
            if(campus_name?.toLowerCase() === 'campus' && url_Campus_Id) {
                checkVerificationCode(verification_code, url_Email, url_Campus_Id);
            } else {
                checkVerificationCode(verification_code, url_Email);
            }
        } else {
            setAlert('Please enter verification code', 'danger');
        } 
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Enter PIN
                    </h3>
                </div>
            </div>

            {/* Body */}
            <div className="settings__body">

                {/* Input */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', flexDirection: 'column', justifyContent:'center', borderBottom:'1px solid #e6ecf0'}}>

                    <div>
                        <div style={{paddingLeft:'10px', color:'rgb(83, 100, 113)', fontSize:'14px', fontWeight:'500', marginTop:'5px', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>We sent a code to <b>{url_Email}</b>.</span>
                        </div>
                    </div>

                    <input
                        type="text"
                        placeholder="Enter code"
                        className="input"
                        name="verification_code"
                        value={verification_code}
                        onChange={e => onChange(e)}
                    />

                    {/* Input Disclaimer */}
                    <div onClick={() => addVerificationCode(campus_name)} style={{cursor: 'pointer', color:'rgb(27, 149, 224)', paddingLeft:'10px', fontSize:'15px', overflowWrap:'break-word'}}>
                        <span>Didn't receive the code?</span>
                    </div>

                </div>

                {/* Submit */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                    <Button 
                        disabled={verification_code === ''}
                        variant="outlined" 
                        className={`sidebar__tweet save ${verification_code === '' ? 'disabled' : ''}`} 
                        onClick={todo}
                    >
                        Verify
                    </Button>
                </div>
            </div>
        </div>
    )
}

Verify_School_Email.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired, 
    addVerificationCode: PropTypes.func.isRequired, 
    checkVerificationCode: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav,
    chat: state.chat,
    notification: state.notification
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
    addVerificationCode, 
    checkVerificationCode,

})(Verify_School_Email);