import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History } from '../actions/navActions';
// Layout Wrapper
import Layout from '../components/layout/Layout';

// components
import GettingStarted from '../components/common/GettingStarted';

const HowItWorksPage = ({ 
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    nav: { 
        sideNav 
    }
}) => {

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    
    useEffect(() => {

        // Set the current page to 'howitworks'
        setPage('howitworks');
        add_To_Recent_History(url.pathname);  // current url
    }, []);

    return (
        <Fragment>
            <Layout sideNav={sideNav} handleSlideMenu={toggleSideNav} page="home">

                {/* Render the GettingStarted component */}
                <div className="post noHighlight">
                    <GettingStarted />
                </div>

                {/* Empty div */}
                <div className="no-rides" />
            </Layout>
        </Fragment>
    )
}

HowItWorksPage.propTypes = {
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    toggleSideNav, 
    setPage,
    add_To_Recent_History
})(HowItWorksPage);
