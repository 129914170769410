import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import mixpanel from 'mixpanel-browser';

// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Actions
import { getUserById } from '../../actions/authActions';
import { addLike, deletePost } from '../../actions/postActions';
import { toggleAuthModal } from '../../actions/navActions';
import { setAlert } from '../../actions/alertActions';

// components - imported
import { Avatar, Button } from '@material-ui/core';
import ImageBlock from '../common/ImageBlock';
import DefaultAvatar from 'react-avatar';
import Verification_Badge from '../common/Verification_Badge';

// Icons - imported
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const Post = ({ 
    postDoc, 
    auth, 
    post: {
        hiddenComments
    },
    nav: {
        campus_name,
        campus_url
    },
    getUserById, 
    parentPost,
    addLike, 
    deletePost, 
    setAlert, 
    toggleAuthModal,
    executeScroll
}) => {

    // Window Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Tracks the like status of post
    const [liked, setLiked] = useState(false);
    // Flag for checking if like is properly updated
    const [checkLike, setCheckLike] = useState(false);

    // --- Convert DraftJS text to HTML text

    // Tracks whether the text has been converted
    const [gotText, setGotText] = useState(false);
    // Stores the converted text
    const [textState, setTextState] = useState(null);

    // Check if post has image - for font size reasons
    const [hasImg, setHasImg] = useState(false);
    // Flag for checking if hasImg is properly updated
    const [checkHasImg, setCheckHasImg] = useState(false);

    // Toggle Dropdwon
    const [dropdown, setDropdown] = useState(false);
    const [shareDropdown, setShareDropdown] = useState(false);

    // Stores the height of a toggle menu
    const [menuHeight, setMenuHeight] = useState(null);

    // Delete post Modal toggles
    const [deleteModal, setDeleteModal] = useState(false);

    // Tracks the status of copying
    const [copied, setCopied] = useState(false);

    // Controls the visibility of a copied modal
    const [copiedModal, setCopiedModal] = useState(false);

    // Get parent post for like page purposes and displaying the 'replying to'
    const [gotParentPost, setGotParentPost] = useState(false);

    const [replyToPost, setReplyToPost] = useState(null);

    useEffect(() => {

        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Clears the copied modal after a delay
    useEffect(() => {
        setTimeout(() => {
            setCopiedModal(false);
        }, 2000);
    }, [copiedModal]);

    // Resets the share dropdown and copied status after a delay
    useEffect(() => {
        setTimeout(() => {
            setShareDropdown(false);
            setCopied(false);
        }, 1000);
    }, [copied]);

    const getParentPost = async () => {
        const docRef = doc(db, 'posts', postDoc.post_commented_on);
        const postData = await getDoc(docRef);
        setReplyToPost({ ...postData.data(), _id: postData.id });
    }

    if(!gotParentPost && parentPost) {
        if(!parentPost?.user) {
            getParentPost();
        } else {
            setReplyToPost(parentPost)
        }

        setGotParentPost(true);
    }

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // ----- Post Actions Logic -------
    
    // Handle post like action
    const handleLike = (postId) => {
        if(auth.user) {
            addLike(campus_name, postId, auth.user, postDoc);

            // Toggles the like status
            setLiked(!liked);
        } else {

            // Retrieves user information by ID
            getUserById(postDoc.user._id);

            // Toggles the authentication modal for liking
            toggleAuthModal('like');
        }
    }

    // Closes the dropdown menu
    const toggleCopiedModal = () => {
        setDropdown(false)
        
        // Sets an alert message indicating that the content has been copied to the clipboard
        // setCopiedModal(!copiedModal);
        setAlert('Copied to clipboard', `${!auth.loading && auth.isAuthenticated ? 'okay' : 'success'}`);
        
    }

    // Closes the share dropdown
    const handleShareCopy = () => {
        setShareDropdown(false)
        
        // Sets an alert message indicating that the content has been copied to the clipboard
        // setCopiedModal(!copiedModal);
        setAlert('Copied to clipboard', `${!auth.loading && auth.isAuthenticated ? 'okay' : 'success'}`);
    }

    // Toggle the delete post confirmation modal
    const toggleDeleteModal = () => {
        setDropdown(false)

        // Toggles the delete modal
        setDeleteModal(!deleteModal); 
    }

    // Deletes the post
    const handleDelete = () => {
        if(postDoc) {
            let stored_id = postDoc._id

            deletePost(postDoc._id);

            // Mixpanel event tracking for deleting a post
            if (process.env.NODE_ENV === 'production') {
                mixpanel.track("Delete Post", {
                    "Post ID": `${stored_id}`
                });
            }
        }

        // Closes the delete modal
        setDeleteModal(false);
    }

    // ----- END: Post Actions Logic -------

    // Function to Capitalize the first letter of a string
    const capitalizeFirstLetter =(string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Checks if the user has already liked the post
    if(auth.user && !checkLike) {
        if(postDoc.likes.filter(like => like.user._id.toString() === auth.user_id).length > 0){
            setLiked(true);
        }
        
        // Marks that the check for likes has been performed
        setCheckLike(true);
    }  

    // Converts the DraftJS text to HTML and sets the converted text
    if (postDoc && !gotText) {
        // console.log('TEXT BLOCK')

        let unRawState;

        if(postDoc.text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(postDoc.text))
            );
        
            // console.log(unRawState)

            // console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setTextState(unRawDescription);
        }

        
        // let editorState = postDoc.text
        // const rawContentState = editorState.getCurrentContent();
 
        // const markup = draftToHtml(
        //     rawContentState
        // );

        // console.log(markup)

        // Marks that the text conversion has been performed
        setGotText(true)
    }

    let optimized_profile_img_path;

    if(postDoc.user.img) {

        // --- ImageKit (img optimization service) ---
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = postDoc.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    
    
    return (
        <Fragment>
            {/* Render the post component */}
            <div style={(hiddenComments?.length > 0 && hiddenComments?.includes(postDoc._id)) ? {display: 'none'} : {}} className={!(postDoc.commentsOfComments?.length > 0) ? "post" : "post hasPostContainer"}>

                {/* Avatar - left side 3;7 */}
                <div style={{display:'flex', flexDirection:'column'}}>
                    <div className="post__avatar" onClick={() => window.location.href = `/${postDoc.user.username}`}>
                        {postDoc.user.img ? (
                            <Avatar src={optimized_profile_img_path && optimized_profile_img_path} />
                        ) : (
                            <DefaultAvatar name={`${postDoc.user.first_name} ${postDoc.user.last_name}`} />
                        )}
                    </div>
                    
                    {postDoc.commentsOfComments?.length > 0 && (
                        <div className='post_thread_indicator'></div>
                    )}
                </div>

                {/* Post Main Body - right side 3:7 */}
                <div className="post__body">

                    {/* More Button */}
                    <div onClick={() => setDropdown(!dropdown)} className="post__moreBtn">
                        <MoreHorizIcon />
                    </div>

                    {/* Dropdown Menu */}
                    <div className={dropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                        <div className="menu">

                            {/* Copy Link Menu Item */}
                            <CopyToClipboard 
                                text={`${campus_url}/post/${postDoc._id}?redirect=true`}
                                onCopy={toggleCopiedModal}
                            >
                                <div className="menu-item">
                                    {!copied ? (
                                        <Fragment>
                                            <LinkIcon />
                                            Copy link
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <CheckIcon />
                                            Copied!
                                        </Fragment>
                                    )}
                                </div>
                            </CopyToClipboard>

                            {/* Delete Menu Item (shown only for authenticated user who created the post) */}
                            {auth.user && postDoc.user._id === auth.user_id ? (
                                <div onClick={toggleDeleteModal} className="menu-item danger">
                                    <DeleteIcon />
                                    Delete
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* Hidden Overlay for dropdown */}
                    <div
                        className={`hidden-overlay ${dropdown ? "show" : ""}`}
                        onClick={() => setDropdown(!dropdown)}
                    />

                    {/* Post Header */}
                    <div className="post__header">
                        <div className="post__headerText">
                            <h3 className={parentPost ? 'isReply' : ''}>

                                {/* Post Author name */}
                                <span>
                                    <a href={`/${postDoc.user.username}`}>
                                        <span>{postDoc.username ? capitalizeFirstLetter(postDoc.username) : null} {' '}</span>
                                        <Verification_Badge user={postDoc.user} /> <span>{/*TODO: GET RID OF THIS SPAN for some reason it makes the username bold*/}</span> 
                                    </a>
                                </span>
                                {/* <span>
                                    &middot;
                                </span> */}

                                {/* Post Timestamp */}
                                <span className="post__headerSpecial">
                                    <a href={`/post/${postDoc._id}`}>
                                        {formatDistanceToNow(postDoc.date)} ago
                                    </a>
                                </span>
                            </h3>

                            {(replyToPost && replyToPost.user?.username)&& (
                                <div className='replying_to_title'>
                                    Replying to {' '}
        
                                    <a href={`/${replyToPost.user.username}`}>
                                        {replyToPost.user.first_name} {replyToPost.user.last_name && ' ' + replyToPost.user.last_name}
                                    </a>
                                </div>
                            )}
                        </div>

                        {/* Post Text */}
                        {textState !== null && (
                            <div className={`post__headerDescription ${hasImg ? 'withImgs' : ''}`}>
                                {/* <p>{postDoc.text ? postDoc.text : null}</p> */}

                                <div dangerouslySetInnerHTML={{__html: textState}} />
                            </div>
                        )}
                        
                    </div>

                    {/* Post Images */}
                    <ImageBlock detailPost={postDoc} setHasImg={setHasImg} setCheckHasImg={setCheckHasImg} checkHasImg={checkHasImg} executeScroll={executeScroll} redirect_on_click />
                    {/* {postDoc.img && <img src={postDoc.img} alt="" />} */}
                    
                    {/* Post Actions */}
                    <div className={`post__footer ${hasImg ? 'hasImg' : ''}`}>

                        {/* Comment Button */}
                        <div>
                            <button 
                                onClick={() => window.location.href = `/post/${postDoc._id}`}
                                className="secondaryBtn post"
                            >
                                <div>
                                    {/* Icon */}
                                    <ChatBubbleOutlineIcon fontSize="small" />

                                    {/* Button Text */}
                                    <span>Comment</span>
                                </div>
                            </button>
                        </div>

                        {/* Like Button */}

                        <div className={liked ? "active" : "" } onClick={() => handleLike(postDoc._id)}>
                            <button className={liked ? "secondaryBtn post active like" : "secondaryBtn post like" }>
                                <div>
                                    {/* Icon */}
                                    {liked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}

                                    {/* Button Text & like count */}
                                    <span>{liked ? 'Saved!' : 'Save'} {postDoc.likes.length > 0 && (
                                        <span style={liked ? {display:'none'}: {fontSize:'11px'}}>({postDoc.likes.length})</span>
                                    )}</span>
                                </div>
                            </button>
                        </div>

                        {/* Share Button */}
                        <div style={{position: 'relative'}}>
                            <button onClick={() => setShareDropdown(!shareDropdown)} className="secondaryBtn post share">
                                <div>
                                    {/* Icon */}
                                    <ShareIcon fontSize="small" />

                                    {/* Button Text */}
                                    <span>Share</span>
                                </div>
                            </button>

                            {/* Share Dropdown */}
                            <div className={shareDropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                                <div className="menu">

                                    {/* Copy Link Menu Item */}
                                    <CopyToClipboard 
                                        text={`${campus_url}/post/${postDoc._id}?redirect=true`}
                                        onCopy={handleShareCopy}
                                    >
                                        <div className="menu-item">
                                            {!copied ? (
                                                <Fragment>
                                                    <LinkIcon />
                                                    Copy link
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <CheckIcon />
                                                    Copied!
                                                </Fragment>
                                            )}
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>

                            {/* Hidden Overlay for share dropdown */}
                            <div
                                className={`hidden-overlay ${shareDropdown ? "show" : ""}`}
                                onClick={() => setShareDropdown(!shareDropdown)}
                            />
                        </div>
                    
                    </div>
                </div>

                {/* Post Overlay to detect when clicked and redirect to postPage */}
                <div 
                    className="post__overlay" 
                    onClick={() => window.location.href = `/post/${postDoc._id}`}
                ></div>
            </div>

            {/* Delete Post Modal */}
            <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Delete Post?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can’t be undone and it will be removed from your profile, the main feed, and from any search results.</p>
                    
                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDelete}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Delete
                    </Button>
                    <Button 
                        onClick={toggleDeleteModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>

            {/* Copied Link Modal */}
            <ModalContainer show={copiedModal} onClose={toggleCopiedModal} white>
                <Modal border>

                    {/* Modal Title */}
                    <div>
                        <h2>Link Copied!</h2>
                    </div>

                    {/* Modal Description */}
                    <p>You can share this link with anyone.</p>

                    {/* Modal Action */}
                    <Button 
                        onClick={toggleCopiedModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost"
                        fullWidth
                    >
                        Close
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

Post.propTypes = {
    getUserById: PropTypes.func.isRequired, // Required prop: Function to get user by ID
    addLike: PropTypes.func.isRequired, // Required prop: Function to add a like
    deletePost: PropTypes.func.isRequired, // Required prop: Function to delete a post
    auth: PropTypes.object.isRequired, // Required prop: Object representing the authenticated user
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired, // Required prop: Function to set an alert
    toggleAuthModal: PropTypes.func.isRequired // Required prop: Function to toggle the authentication modal
}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop
    auth: state.auth,
    post: state.post,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    getUserById, 
    addLike, 
    deletePost, 
    setAlert, 
    toggleAuthModal 
})(Post);
