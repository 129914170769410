import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Trust_Settings = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    const hostname = window.location.hostname;

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track Settings Trust Page View
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Trust Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }
    
    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    /* Back Btn Icon & Badge Value */
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Trust and Safety
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="trust-and-safety">
                    <h2 class="h1 mb-2 text-center">Code of Conduct</h2>
                    <hr class="hr-white" />
                    <h4 class="text-upp desk-text-center">BE NEIGHBORLY.</h4>
                    <div class="text-editor mb-4">
                        <p>
                            We don&rsquo;t all live next door to each other, but we think every {campus_name} Buy / Sell experience can be better if we act like we do.
                        </p>
                        <p>
                            <strong>Set fair prices, make fair offers.</strong>
                            <br />
                            We&rsquo;re after that perfect marketplace equilibrium where everyone feels like they got a good deal. Prices and offers that reflect the real value of things help us get there.&nbsp;
                        </p>
                        <p>
                            <strong>Tell the truth.</strong>
                            <br />
                            We&rsquo;re still looking for the place where this advice doesn&rsquo;t apply, but it&rsquo;s especially important here. Thorough descriptions of posted items and transparent conversations about the details of an offer will help everyone find more success more quickly.&nbsp;
                        </p>
                        <p>
                            <strong>Show up when you say you will.</strong>
                            <br />
                            If 80% of success is showing up, we think the other 20% is being on time. Please don&rsquo;t flake. If you can&rsquo;t make it on time, found another buyer, or you&rsquo;re no longer interested, please let each other know.&nbsp;
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 class="text-upp desk-text-center">BE ETHICAL.</h4>
                    <div class="text-editor mb-4">
                        <p>
                            We have a zero tolerance&nbsp; policy when it comes to violations of good citizenship. This is a place to be your best self. If you infringe on the security, privacy, safety, or dignity of members of the {campus_name} Buy / Sell community, your stay in our marketplace will be short.&nbsp;
                        </p>
                        <p>
                            <strong>No bullying, no violence, no threats.</strong>
                            <br />
                            We prohibit acts of violence, verbal or written suggestions of violence, and any attempt to intimidate, harass, or otherwise violate the safety and wellbeing of others.&nbsp;
                        </p>
                        <p>
                            <strong>No discrimination.</strong>
                            <br />
                            Everyone who abides by our policies and standards is welcome here. Mistreatment of any kind on the basis of race, ethnicity, gender, gender identity, sexual orientation, religious affiliation, or disability will not be tolerated.&nbsp;
                        </p>
                        <p>
                            <strong>No misrepresentation.</strong>
                            <br />
                            Be yourself. We accept no substitutes. Impersonation of other people, other entities, or {campus_name} Buy / Sell employees is not allowed, regardless of affiliation. And posting another person&rsquo;s personal information in any form is prohibited.&nbsp;
                        </p>
                        <p>
                            <strong>No coarse or suggestive language.</strong>
                            <br />
                            We prohibit sexual harassment. If a member of our community reports vulgar or abusive language or behavior, we will take immediate steps to remove the offending user from our marketplace.&nbsp;
                        </p>
                        <p>
                            <strong>No scams.</strong>
                            <br />
                            We will remove any user who impersonates another person or entity, sends unsolicited messages to potential buyers, or sends misleading, fake, or malicious communication. If you&rsquo;re on the receiving end of something suspicious, please&nbsp; report it through the app.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <h4 class="text-upp desk-text-center">BE PROACTIVE.</h4>
                    <div class="text-editor mb-4">
                        <p>
                            We ask members of our community to take their personal wellbeing as seriously as we do by making good decisions about how and where to buy and sell.
                        </p>
                        <p>
                            <strong>Avoid phishyness.</strong>
                            <br />
                            Please don&rsquo;t give away personal information such as email, phone number, or address. Use in-app messaging to communicate securely. Pay with cash or use{" "}
                                {campus_name} Buy / Sell Payments
                            available in some markets. Do not make wire transfers or send money by mail.&nbsp;
                        </p>
                        <p>
                            <strong>Meet in a sensible location.</strong>
                            <br />
                            Please agree to meet in well-lit, well-crowded public locations, and let a friend or family member know where you&rsquo;re going.&nbsp;
                        </p>
                        <p>
                            <strong>Inspect items in person.</strong>
                            <br />
                            Please thoroughly inspect and test items before you complete a sale.&nbsp;
                        </p>
                        <p>
                            <strong>Report problems.</strong>
                            <br />
                            If you see something on {campus_name} Buy / Sell that violates our policy, please{" "}
                                report
                            it through the app. If you have an experience with a buyer or seller that violates our community standards, please take the appropriate action to safeguard your personal wellbeing, and then contact us.&nbsp;
                        </p>
                        <p>
                            <strong>Rate each other.</strong>
                            <br />
                            We expect members of our community to earn high marks, but our in-app rating tool is your opportunity to give each other honest feedback on the buying and selling experience and help improve that experience for others.
                        </p>
                    </div>
                    <hr class="hr-white" />
                    <div class="text-editor mb-4">
                        <p><em>{campus_name} Buy / Sell will take action against known violators of this Code of Conduct. If you see something or experience something that doesn&rsquo;t live up to these standards, please contact us.&nbsp;</em></p>
                    </div>
                    <h5 style={{marginTop:'30px'}}>NEED HELP?</h5>
                    <h3>REACH US ON Social</h3>
                    <div>
                        <p>We are here to help solve problems and investigate issues when they arise. Please contact us for assistance.</p>
                        <Button onClick={() => window.open(`https://www.instagram.com/${campus_name.toLowerCase()}buysell/`, "_blank")} variant="outlined" className="sidebar__tweet ghost" fullWidth>DM us @{campus_name}BuySell</Button>
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Trust_Settings;
