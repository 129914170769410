import React, { useState, Fragment } from 'react';

import { socket } from '../../socket';
import moment from 'moment';

// Components
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Verification_Badge from '../common/Verification_Badge';

// Icons
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const Message = ({
    auth,
    chat,
    getChatById,
    history,
    text,
}) => {

    // --- Other users info for chat

    // State for storing the chat avatar
    const [chatAvatar, setChatAvatar] = useState(null);

    // State to track if chat name has been obtained
    const [gotChatName, setGotChatName] = useState(false);

    // State for storing the chat user object
    const [chatUser, setChatUser] = useState(null);

    // State for storing the chat name
    const [chatName, setChatName] = useState(null);

    // State for storing the chat username
    const [chatUserName, setChatUserName] = useState(null);

    // State for storing the chat bio

    const [chatBio, setChatBio] = useState(null);

    // State for toggling the dropdown menu
    const [dropdown, setDropdown] = useState(false);

    // State for storing the menu height
    const [menuHeight, setMenuHeight] = useState(null);

    // State for toggling the delete modal
    const [deleteModal, setDeleteModal] = useState(false);

    // --- Convert DraftJS text to HTML text

    // Tracks whether the text has been converted
    const [gotText, setGotText] = useState(false);
    // Stores the converted text
    const [textState, setTextState] = useState(null);

    // --- Modal toggle logic

    const toggleDeleteModal = () => {
        
        // Close the dropdown menu
        setDropdown(false)

        // Toggles the delete modal state
        setDeleteModal(!deleteModal);
    }


    // Handle message deletion
    const handleDelete = () => {
        // if(post) {
        //     deletePost(post._id);
        // }

        // Closes the delete modal
        setDeleteModal(false);
    }

    // Redirects to the chat page
    const viewChat = () => {    
        // getChatById(chat._id);
        window.location.href = `/messages/${chat._id}`;
        // history.push(`/messages/${chat._id}`)

        // window.location.reload(false);
    }

    // Check if user and chat information is available to set chat details
    if(auth.user && chat && chat.to_user && chat.from_user && !gotChatName) {

        // Check if the authenticated user is the recipient of the chat (to_user)
        if(auth.user._id === chat.to_user._id) {

            // --- ImageKit (img optimization service) ---

            let optimized_profile_img_path; 

            if(chat.from_user?.img) {
                // Replace the firebase URL with ImageKit.io URL-endpoint
                optimized_profile_img_path = chat.from_user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
                
                // Sets the chat avatar
                setChatAvatar(optimized_profile_img_path);
            }

            // Sets the chat user
            setChatUser(chat.from_user);
            // Sets the chat name
            setChatName(chat.from_user.first_name + ' ' + chat.from_user.last_name);
            // Sets the chat username
            setChatUserName(chat.from_user.username);
            // Sets the chat bio if available
            if(chat.from_user.bio) setChatBio(chat.from_user.bio)
            // Updates the flag indicating that chat name has been obtained
            setGotChatName(true);
        } 
        // Check if the authenticated user is the sender of the chat (from_user)
        else if (auth.user._id === chat.from_user._id) {

            // --- ImageKit (img optimization service) ---

            let optimized_profile_img_path; 

            if(chat.to_user?.img) {
                // Replace the firebase URL with ImageKit.io URL-endpoint
                optimized_profile_img_path = chat.to_user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");

                // Sets the chat avatar
                setChatAvatar(optimized_profile_img_path);
            }

            // Sets the chat user
            setChatUser(chat.to_user);
            // Sets the chat name
            setChatName(chat.to_user.first_name + ' ' + chat.to_user.last_name);
            // Sets the chat username
            setChatUserName(chat.to_user.username);
            // Sets the chat bio if available
            if(chat.to_user.bio) setChatBio(chat.to_user.bio)
            // Updates the flag indicating that chat name has been obtained
            setGotChatName(true);
        }
    }

    // Retrieve the current URL path and split it into an array
    let previousURL = window.location.pathname;
    var pathArray = previousURL.split( '/' );

    console.log('HELLO PATH ARRAY');
    console.log(pathArray);
    console.log('FORTH PATH');
    if(pathArray[2]) {
        console.log(pathArray[2]);
    }
    
    // Converts the DraftJS text to HTML and sets the converted text
    if (text.includes('{"blocks":[{') && !gotText) {
        console.log('TEXT BLOCK')

        let unRawState;

        if(text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(text))
            );
        
            console.log(unRawState)

            console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setTextState(unRawDescription);
        }

        // Marks that the text conversion has been performed
        setGotText(true)
    }

    return (
        <Fragment>
            {/* Chat Message */}
            <div onClick={viewChat} className={`message ${(Array.isArray(chat.read) && chat.read.includes(auth.user._id) && (chat?._id !== pathArray[2])) ? 'not_seen' : ''} ${pathArray[2] && chat._id === pathArray[2] ? 'active' : ''}`}>
                
                {/* Message avatar */}
                <div className="message__avatar">
                    {chatAvatar ? (
                        <Avatar src={chatAvatar && chatAvatar} />
                    ) : (
                        <DefaultAvatar name={chatName} />
                    )}
                </div>

                {/* Message body */}
                <div className="message__body">

                    {/* Message timestamp and dropdown button */}
                    <div onClick={() => setDropdown(!dropdown)} className="chat__moreBtn">
                        <p>{moment(chat.date).format("MMM Do")}</p>
                        {(Array.isArray(chat.read) && chat.read.includes(auth.user._id)) && (
                            (pathArray[2] && chat._id === pathArray[2]) ? null : (
                                <div className='chat_unread_circle'></div>
                            )
                        )}
                    </div>
                    <div className="message__header">

                        {/* Message chat name and badge */}
                        <div className="message__headerText">
                            <h3>
                                {chatName} {' '}
                                <span className="message__headerSpecial">
                                    <Verification_Badge user={chatUser} /> {/* @{chatUserName && chatUserName} */}
                                </span>
                            </h3>
                        </div>

                        {/* Message Text */}
                        <div className={`message__headerDescription line-clamp-1 ${Array.isArray(chat.read) && chat.read.includes(auth.user._id) ? 'width_80' : ''}`}>
                            {textState !== null ? (<p dangerouslySetInnerHTML={{__html: textState}} />) : <p>{text}</p>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Modal */}
            <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Delete Post?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can’t be undone and it will be removed from your profile, the main feed, and from any search results.</p>

                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDelete}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Delete
                    </Button>
                    <Button 
                        onClick={toggleDeleteModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

export default Message;
