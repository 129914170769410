import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Returns_Settings = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    const hostname = window.location.hostname;

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {
        
        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track Settings Returns Page View
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Returns Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }
    
    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    /* Back Btn Icon & Badge Value */
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Return Policy
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="return-policy">
                    <section>
                        <div>
                            <div>
                                <p><span>If you buy an item, inspect it closely. Is it what you expected? If not, let the seller know by requesting a refund.</span></p>
                                <p><span>You can request a refund by privately messaging the Seller up to 2 days after you get the item. If you don&apos;t get a response that solves the issue, you have 2 more days to let us know by&nbsp;<a href="mailto:tunde@campusbuysell.com" target="_self">email</a>.</span></p>
                                <p><span>After that time period expires, we&apos;ll assume you&rsquo;re happy with the item and complete the transaction.</span></p>
                                <h3>To request a refund&nbsp;</h3>
                                <ol>
                                    <li><span>Navigate to your Inbox by tapping&nbsp;</span><strong>Messages</strong></li>
                                    <li><span>Select the corresponding conversation you previously had with the Seller of the item you would like to request a refund for</span></li>
                                    <li><span>Type&nbsp;</span>"<strong>I would like a refund</strong>" and click <strong>Send</strong></li>
                                    <li><span>Respond to the Sellers &ldquo;What&rsquo;s the issue?&rdquo; question in the text box</span></li>
                                    <li><span>Agree to a refund</span></li>
                                </ol>
                                <h3>Once you&apos;ve requested a refund</h3>
                                <ul>
                                    <li><span>The buy & seller have 2 days to return the item(s) and payment to each other for a refund</span></li>
                                    <li><span>If the seller declines or doesn&apos;t respond to your refund request, you have 2 days to report the issue directly to us.</span></li>
                                </ul>
                                <p><strong>Important:</strong><span>&nbsp;You must report to us within 4 days of the item(s) being exchanged. You have 2 days to contact the seller, and 2 more days to contact us if the seller does not respond or declines.</span></p>
                                <p><span>If your item(s) arrives damaged, turns out to be counterfeit, or you have other questions, please&nbsp;</span><a href="mailto:tunde@campusbuysell.com"><span>contact us</span></a><span>.</span></p>
                                
                                <h5 style={{marginTop:'30px'}}>NEED HELP?</h5>
                                <h4>REACH US ON Social</h4>
                                <div>
                                    <p>We are here to help solve problems and investigate issues when they arise. Please contact us for assistance.</p>
                                    <Button onClick={() => window.open(`https://www.instagram.com/${campus_name.toLowerCase()}buysell/`, "_blank")} variant="outlined" className="sidebar__tweet ghost" fullWidth><b>DM us @{campus_name}BuySell</b></Button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Returns_Settings;
