import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import { Avatar } from '@material-ui/core';
import GoogleBtn from '../common/GoogleBtn';

// Icons
import MailIcon from '@material-ui/icons/Mail';

const AuthDropdown = ({
    setAuthDropdown,
    authDropdown,
    nav: { 
        country_code,
        show_firewall,
        geo_loading
    },
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    // --- Security Function --- 

    if(authDropdown && !geo_loading && (country_code !== 'US' || show_firewall)) {
        setAuthDropdown(false);
        window.location.href = '/404';
    }

    // --- END: Security Function --- 

    return (
        <Fragment>
            {/* Auth Dropdown Menu */}
            <div className={authDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
                <div className='auth_dropdown__topNavbar'>
                    <div className='auth_dropdown_avatar'>
                        <Avatar />
                    </div>
                    <h1>
                        Log In / Sign Up
                    </h1>
                    <p>Takes less than a few seconds.</p>

                    <GoogleBtn />

                    <div onClick={() => window.location = '/user_registration'} className='delivery_widget authPage grey_background'>
                        <div style={{height: '90%', margin: '5px 5px 5px -5px', color: '#000', padding: '3px', borderRadius: '50%', border: '1px solid #e6ecf0', boxSizing: 'border-box'}}>
                            <MailIcon />
                        </div>
                        <span style={{fontWeight: 'bold', fontSize: '16px', color: '#000'}}>
                            Continue with Email
                        </span>
                    </div>

                </div>
            </div>

            {/* Hidden Overlay for Auth dropdown */}
            <div
                className={`hidden-overlay ${authDropdown ? "show" : ""}`}
                onClick={() => setAuthDropdown(!authDropdown)}
            />
        </Fragment>
    )
}

AuthDropdown.propTypes = {
    nav: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions to the component
})(AuthDropdown);
