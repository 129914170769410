import React, { Fragment, useEffect, useState } from 'react';

// Components - material UI
import { Button } from '@material-ui/core';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Gender = ({
    setGender,
    gender,
    genderTodo,
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    goBack
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">

                {/* ack Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Gender
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body">
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'flex-start'}}>

                    {/* Disclaimer text */}
                    <div style={{color: 'rgb(83, 100, 113)', fontSize:'15px', fontWeight:'500', lineHeight:'16px', overflowWrap:'break-word'}}>
                        <span>This information won’t be displayed publicly.</span>
                    </div>
                </div>
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'flex-start', borderBottom:'1px solid #e6ecf0'}}>
                    
                    {/* Gender Options */}
                    <div style={{ padding:'0 10px', display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                        
                        {/* Male */}
                        <div onClick={() => setGender('male')} className="button-hover-class" style={{margin:'10px 0', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {gender === 'male' ? (
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'16px', width:'16px', border:'2px solid rgb(29, 155, 240)', padding:'2px', borderRadius:'50%', marginRight:'10px'}}>
                                    <div style={{height:'100%', width:'100%', background:'rgb(29, 155, 240)', borderRadius:'50%'}}></div>
                                </div>
                            ) : (
                                <div style={{height:'19px', width:'19px', border:'2px solid #cecece', borderRadius:'50%', marginRight:'10px'}}></div>
                            )}
                            <p style={{margin:'0', fontSize:'1rem'}}>Male</p>
                        </div>

                        {/* Female */}
                        <div onClick={() => setGender('female')} className="button-hover-class" style={{margin:'10px 0', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {gender === 'female' ? (
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'16px', width:'16px', border:'2px solid rgb(29, 155, 240)', padding:'2px', borderRadius:'50%', marginRight:'10px'}}>
                                    <div style={{height:'100%', width:'100%', background:'rgb(29, 155, 240)', borderRadius:'50%'}}></div>
                                </div>
                            ) : (
                                <div style={{height:'19px', width:'19px', border:'2px solid #cecece', borderRadius:'50%', marginRight:'10px'}}></div>
                            )}
                            <p style={{margin:'0', fontSize:'1rem'}}>Female</p>
                        </div>

                        {/* Other Gender */}
                        <div onClick={() => setGender('other')} className="button-hover-class" style={{margin:'10px 0', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {gender === 'other' ? (
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center',height:'16px', width:'16px', border:'2px solid rgb(29, 155, 240)', padding:'2px', borderRadius:'50%', marginRight:'10px'}}>
                                    <div style={{height:'100%', width:'100%', background:'rgb(29, 155, 240)', borderRadius:'50%'}}></div>
                                </div>
                            ) : (
                                <div style={{height:'19px', width:'19px', border:'2px solid #cecece', borderRadius:'50%', marginRight:'10px'}}></div>
                            )}
                            <p style={{margin:'0', fontSize:'1rem'}}>Other</p>
                        </div>
                    </div>
                </div>

                {/* Submit */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                    <Button 
                        onClick={genderTodo}
                        variant="outlined" 
                        className="sidebar__tweet save" 
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Gender;
