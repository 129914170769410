import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Privacy_Policy = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    const hostname = window.location.hostname;

    useEffect(() => {
        
        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Privacy Policy page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Privacy Policy Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">
            
            {/**Header */}
            <div className="settings__header">
                {isTablet && (
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Privacy Policy
                    </h3>
                </div>
            </div>

            <div className="settings__body terms">
                <div className="terms-of-service">
                    <h2 className="MuiTypography-root jss3 MuiTypography-h3 MuiTypography-colorTextPrimary">Privacy Policy</h2>
                    <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary">Last updated: June 24, 2022</p>
                    <div>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                            This Privacy Policy explains how information about you is collected, used, and shared by Cardboard Express, Inc. (hereafter {campus_name} Buy / Sell,” “our,” “we,” or “us”). This Privacy Policy applies to information we collect when you use our website, mobile applications, when you interact with us, and when you use other online services (collectively, the {campus_name} Buy / Sell Service”).
                        </p>
                        <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this Privacy Policy and, in some cases, we may provide you with more prominent notice (such as adding a statement to our homepage or sending you an email notification). We encourage you to review the Privacy Policy whenever you access the {campus_name} Buy / Sell Service to stay informed about our information practices and the ways you can help protect your privacy.
                        </p>
                        <ol style={{paddingLeft:'0.7em'}}>
                            <strong>
                                <li style={{paddingBottom:'12px'}}>
                                    Information We Collect and How We Collect It
                                </li>
                            </strong>
                            <ol type="A" style={{paddingLeft:'24px'}}>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Information You Provide to Us.
                                    </li>
                                </strong>
                                <ol type="1" style={{paddingLeft:'24px'}}>
                                    <li style={{paddingBottom:'12px'}}>
                                        {campus_name} Buy / Sell collects information that you provide directly to us when you sign up and use the {campus_name} Buy / Sell service including:
                                    </li>
                                    <ol type="a" style={{paddingLeft:'24px'}}>
                                        <li style={{paddingBottom:'12px'}}>
                                            Information when you register or update the details of your account,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            participate in identification badge programs such as TruYou
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            post items for sale,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            enroll in third-party payment processing services offered in connection with the {campus_name} Buy / Sell Service,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            communicate with other users, provide reviews or other comments, purchase services from {campus_name} Buy / Sell,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            request customer support, or otherwise communicate with us.
                                        </li>
                                    </ol>
                                    <li style={{paddingBottom:'12px'}}>
                                        The types of information we may collect include:
                                    </li>
                                    <ol type="a">
                                        <li style={{paddingBottom:'12px'}}>
                                            your name,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            email address,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            {campus_name} Buy / Sell account password (encrypted),
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            telephone number,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            postal address including city, state, and zip code,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            photographs of posted items,
                                        </li>
                                        
                                        <li style={{paddingBottom:'12px'}}>
                                            written descriptions of your posted items,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            bids you make on items,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            reviews or comments you make on the {campus_name} Buy / Sell Service,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            all messages with other users or with us sent through the {campus_name} Buy / Sell messaging service or to {campus_name} Buy / Sell directly,
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            government-issued identification documents (such as a driver's license),
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            any other information you choose to provide.
                                        </li>
                                    </ol>
                                </ol>
                                <strong>
                                    <li id="information-we-collect" style={{paddingBottom:'12px'}}>
                                        Information We Collect Automatically
                                    </li>
                                </strong>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    When you access or use the {campus_name} Buy / Sell Service, we automatically collect information about you, including:
                                </p>
                                <ol type="1" style={{paddingLeft:'24px'}}>
                                        <li style={{paddingBottom:'12px'}}>
                                            We log information about your use of the {campus_name} Buy / Sell Service, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before navigating to our {campus_name} Buy / Sell Service.
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            We collect information about the computer or mobile device you use to access the {campus_name} Buy / Sell Service, including the hardware model, operating system and version, unique device identifiers, and mobile network information.
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            If you consent to the collection of location information from your computer or mobile device, we may collect this information when you access the {campus_name} Buy / Sell Service or when you otherwise consent to the collection of this information. We may also use your IP address to infer an approximate geographic location for your computer or device. For more details, please see the 
                                            {' '}
                                            <a className="alt nowrap" rel="noopener noreferrer" href="#your-choices">
                                                Your Choices
                                            </a> 
                                            {' '}
                                            section below.
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            We use various technologies to collect some of the information listed above, and this may include sending cookies to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory that helps us to improve the {campus_name} Buy / Sell Service and your experience, see which areas and features of the {campus_name} Buy / Sell Service are popular and count visits. We may also collect information using web beacons (also known as “gifs,” “pixel tags” and “tracking pixels”). Web beacons are electronic images that may be used in the {campus_name} Buy / Sell Service or emails and help deliver cookies, count visits, understand usage, advertising campaign effectiveness, and determine whether an email has been opened and acted upon. For more information about cookies, and how to disable them, please see the 
                                            {' '}
                                            <a className="alt nowrap" rel="noopener noreferrer" href="#your-choices">
                                                Your Choices
                                            </a> 
                                            {' '}
                                            section below.
                                        </li>
                                    </ol>
                                    <strong>
                                        <li style={{paddingBottom:'12px'}}>
                                            Information from Third Parties.
                                        </li>
                                    </strong>
                                    <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                        {campus_name} Buy / Sell may, from time to time, obtain information from other sources and combine that with information we collect through the {campus_name} Buy / Sell Service in order to enhance our ability to serve you, prevent fraudulent transactions, tailor our content to you, deliver relevant advertising to you, offer you opportunities to purchase products or services that we believe may be of interest to you, and for any other purposes described in this Privacy Policy. For example, if you create or log into your account through a social media site, we will have access to certain information from that site, such as your name, account information and friends lists, in accordance with the authorization procedures determined by such social media site. We may also receive transaction information from Stripe Inc. when you purchase or sell items using the {campus_name} Buy / Sell payment solution, including the date and time of sale and the amount of the sale.
                                    </p>
                                    <strong>
                                        <li style={{paddingBottom:'12px'}}>
                                            Payment Information Collected by Stripe.
                                        </li>
                                    </strong>
                                    <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                        {campus_name} Buy / Sell is integrated with Stripe, Inc.'s (“Stripe”) services to allow sellers to accept credit card and other electronic payment methods for goods they list for sale on the {campus_name} Buy / Sell Service. Stripe provides these services directly to sellers pursuant to its own terms and privacy policy. When signing up to receive funds electronically, Stripe, and not {campus_name} Buy / Sell, collects some of the information provided by sellers via the {campus_name} Buy / Sell Service, including debit card numbers, Social Security numbers, government IDs, and bank account information. For more information about the third-party payment processing services integrated with the {campus_name} Buy / Sell Service, please see our 
                                        {' '}
                                        <a rel="noopener noreferrer" target="_self" href="/settings/help" className="alt">
                                            FAQs
                                        </a>
                                        .
                                    </p>
                                </ol>
                                <strong>
                                    <li id="how-we-use-information" style={{paddingBottom:'12px'}}>
                                        How We Use Your Information.
                                    </li>
                                </strong>
                                <ol type="A" style={{paddingLeft:'24px'}}>
                                    <strong>
                                        <li style={{paddingBottom:'12px'}}>
                                            {campus_name} Buy / Sell uses information about you for various purposes, including to:
                                        </li>
                                    </strong>
                                    <ol type="1">
                                        <li style={{paddingBottom:'12px'}}>
                                            Verify your login information to allow you to access and use the {campus_name} Buy / Sell Service, to personalize the {campus_name} Buy / Sell Service, and to optimize the location and type of offers presented to you when you use the {campus_name} Buy / Sell Service;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Connect you to other users on the {campus_name} Buy / Sell Service via friends lists when authorized by you;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Create and maintain a trusted and safer environment on the {campus_name} Buy / Sell Service, including through detection and prevention of fraud, unauthorized access, intrusion, and service interruption;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Conduct investigations and to respond to disputes between users, error resolution, and other similar customer support service;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Enable any identification programs we may institute and in which you elect (e.g., TruYou) or are required to participate, such as verifying your identity and address, and conduct checks against various publicly available databases;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Operate, protect, improve and optimize the {campus_name} Buy / Sell Service, our business, and our users’ experience;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Perform analytics, conduct research, and monitor and analyze trends, usage and activities in connection with the {campus_name} Buy / Sell Service;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Communicate with you about products, services, offers, promotions, rewards, and events offered by {campus_name} Buy / Sell and others;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Provide advertisements, news, and information to match user profiles or interests that we think will be of interest to you;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Send you service, support and administrative messages, reminders, technical notices, updates, security alerts, and information requested by you;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Comply with our legal obligations or requests from law enforcement agencies, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Link or combine with information we get from others to help understand your needs and provide you with better service;
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Assist third-party payment processors with transaction processing, error, chargeback and disputed transaction resolution, and similar payment processing functions; and
                                        </li>
                                        <li style={{paddingBottom:'12px'}}>
                                            Carry out any other purpose for which the information was collected.
                                        </li>
                                    </ol>
                                    <li style={{fontStyle:'normal', paddingBottom:'12px'}}>
                                        {campus_name} Buy / Sell is based in the United States and the information we collect is governed by U.S. law. By accessing or using the {campus_name} Buy / Sell Service or otherwise providing information to us, you consent to the processing and transfer of information in and to the U.S. and other countries, where you may not have the same rights and protections as you do under local law.
                                    </li>
                                </ol>
                                <strong>
                                    <li id="why-we-share-information" style={{paddingBottom:'12px'}}>
                                        Why We May Share Your Information and When
                                    </li>
                                </strong>
                                <ol type="A" style={{paddingLeft:'24px'}}>
                                    <strong>
                                        <li style={{paddingBottom:'12px'}}>
                                            We may share the information we collect about you in the following situations:
                                        </li>
                                    </strong>
                                    <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        With the public when you post on the {campus_name} Buy / Sell Service. For a detailed description of the type of information associated with your {campus_name} Buy / Sell account that is made available publicly through the {campus_name} Buy / Sell Service.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        When you register through the {campus_name} Buy / Sell Service to receive electronic payments via Stripe’s services, we will share certain information about you with Stripe, including name, address, IP address, and date of birth as directed by you to sign up for Stripe’s services. This information is in addition to the information collected directly by Stripe (as described above in “Payment Information Collected by Stripe”). Stripe may use this information for the purposes described in its privacy policy, including for fraud prevention.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf (including, without limitation, with third-party service providers that may use the information we share to help us and other companies detect inaccurate personal information and prevent fraudulent transactions).
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        With advertisers to help us deliver relevant advertising to you on the {campus_name} Buy / Sell Service and across the internet.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process, or as otherwise required by any applicable law, rule, or regulation.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property, and safety of {campus_name} Buy / Sell or others.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business to another company.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        Between and among {campus_name} Buy / Sell, and its current and future parents, affiliates, subsidiaries, and other companies under common control and ownership.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        With your consent or at your direction, including as mentioned in the Social Sharing Features section below or if we otherwise notify you through the {campus_name} Buy / Sell Service that the information you provide will be shared in a particular manner and you provide such information.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
                                    </li>
                                </ol>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        When You Choose to Share Outside of {campus_name} Buy / Sell.
                                    </li>
                                </strong>
                                <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        The {campus_name} Buy / Sell Service offers social sharing features and other integrated tools (such as the Facebook “Like” button), which let you share actions you take on the {campus_name} Buy / Sell Service with other media, and vice versa. The use of such features enables you to share your information with your friends or the public, depending on your selected settings on those platforms. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide those features.
                                    </li>
                                </ol>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Advertising and Analytics Services Provided by Others
                                    </li>
                                </strong>
                                <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        We may allow partners like Google and Facebook to serve advertisements on the {campus_name} Buy / Sell Service, on our behalf across the Internet, and to provide us with analytics services. These entities may use cookies, web beacons, and other technologies to collect information about your use of the {campus_name} Buy / Sell Service and other websites and online services, including your IP address, device identifier, web browser, pages viewed, time spent on pages, links clicked and conversion information.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        Information collected by these service providers may be used by {campus_name} Buy / Sell and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on the {campus_name} Buy / Sell Service and other websites and online services, and better understand your online activity.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purpose, please visit 
                                        {' '}
                                        <a rel="noopener noreferrer" className="alt" target="_blank" href="http://www.aboutads.info/choices/">
                                            http://www.aboutads.info/choices/
                                        </a>
                                        .
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        Your mobile device operating system may also include a feature that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes.
                                    </li>
                                </ol>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Security
                                    </li>
                                </strong>
                                <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        {campus_name} Buy / Sell takes reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.
                                        
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        {campus_name} Buy / Sell protects your privacy by limiting the amount of personally identifiable data we share with third parties. In most cases, we share data that is aggregated or anonymized. When we do need to share identifiable data with third parties, we take steps to ensure that they provide the same level of protection to your data as {campus_name} Buy / Sell provides under this Privacy Policy.
                                    </li>
                                </ol>
                            </ol>
                            <strong>
                                <li id="your-choices">
                                    Your Choices
                                </li>
                            </strong>
                            <ol type="A" style={{paddingLeft:'24px'}}>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Account Information.
                                    </li>
                                </strong>
                                <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        You may update, correct, or remove account profile information at any time by logging into your account.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        You can deactivate your account by following 
                                        {' '}
                                        <a rel="noopener noreferrer" target="_self" href="/logout">
                                            this link
                                        </a>
                                        . We will make your account inactive until the time you decide you would like to return to using the {campus_name} Buy / Sell Services. To reactivate your account, you can sign in using the same username and password.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        If you are on an iOS device and would like to delete your account, you can request deletion from your Account Settings menu.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        If you are a California resident and would like to exercise your rights under the California Consumer Privacy Act, please see 
                                        {' '}
                                        <a className="alt nowrap" rel="noopener noreferrer" href="#information-for-california-residents">
                                            Information for California Residents
                                        </a> 
                                        {' '}
                                        below.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        Please note that we may retain certain information as required by law or for legitimate business purposes. We will also retain certain information such as reviews, forum postings, and similar materials posted by you may continue to be publicly available (but not associated with your identifiable information) on the {campus_name} Buy / Sell Service after your {campus_name} Buy / Sell account is canceled.
                                    </li>
                                </ol>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Location Information.
                                    </li>
                                </strong>
                                <ol type="1">
                                    <li style={{paddingBottom:'12px'}}>
                                        When you first download our mobile app or the first time you attempt to use any features that use location information, you will be asked to consent to our collection of this information. You may revoke your consent to our tracking location information at any time by changing your preferences in the settings menu on your mobile device or by following the standard uninstall process to remove our mobile application form your device.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        Even if you do not consent to collect the precise location from your mobile device, we may use your IP address to infer an approximation of your location when using certain features of the {campus_name} Buy / Sell Services, such as the {campus_name} Buy / Sell payments solution.
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        In some features, {campus_name} Buy / Sell uses mapping services from Google Maps/Google Earth, including Google Maps API(s). Your use of these services is subject to 
                                        {' '}
                                        <a target="_blank" rel="noopener noreferrer" className="alt nowrap" href="https://www.google.com/intl/en_us/help/terms_maps.html">
                                            Google's terms of service
                                        </a>.
                                    </li>
                                </ol>
                                <strong>
                                    <li id="cookies" style={{paddingBottom:'12px'}}>
                                        Cookies.
                                    </li>
                                </strong>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Removing or rejecting browser cookies does not necessarily affect third-party flash cookies used in connection with the {campus_name} Buy / Sell Service. To delete or disable flash cookies please visit 
                                    {' '}
                                    <a rel="noopener noreferrer" className="alt" target="_blank" href="http://www.adobe.com/products/flashplayer/security">
                                        www.adobe.com/products/flashplayer/security
                                    </a> 
                                    {' '}
                                    for more information. Please note that removing or rejecting certain cookies may affect the functionality of the {campus_name} Buy / Sell Service.
                                </p>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Promotional Communications
                                    </li>
                                </strong>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    You may opt out of receiving promotional emails, text messages, and other communications from {campus_name} Buy / Sell by following the instructions in those emails, text messages, or other communications or by making changes in your {campus_name} Buy / Sell account Settings menu.
                                </p>
                                <strong>
                                    <li id="information-for-california-residents" style={{paddingBottom:'12px'}}>
                                        Information for California Residents.
                                    </li>
                                </strong>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    This section provides additional disclosures required by the California Consumer Privacy Act (“CCPA”), which applies to the personal information we collect about California residents.

                                </p>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    In the preceding 12 months, we have collected the following categories of personal information: identifiers (such as name, contact information, device identifiers and state-issued ID numbers); internet or other electronic network activity information (such as usage data); demographic information (such as age); commercial information (such as products purchased or sold); geolocation data; inferences (such as approximate location); visual or similar information (such as photographs of yourself); and other information that relates to or is reasonably capable of being associated with you and that does not fit neatly into one of the other categories (such as personal information contained in reviews, comments and communications with us). For more details about the personal information we collect, including the categories of sources, please see the 
                                    {' '}
                                    <a className="alt nowrap" rel="noopener noreferrer" href="#information-we-collect">
                                        Information We Collect
                                    </a>
                                    {' '} 
                                    section above.
                                </p>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    We collect personal information for the business and commercial purposes listed in the
                                    {' '}
                                    <a className="alt nowrap" rel="noopener noreferrer" href="#how-we-use-information">
                                        How We Use Your Information
                                    </a> 
                                    {' '}
                                    section above. We share the personal information we collect with the categories of third parties listed in the 
                                    {' '}
                                    <a className="alt nowrap" rel="noopener noreferrer" href="#why-we-share-information">
                                        Why We Share Your Information
                                    </a> 
                                    {' '}
                                    section above.
                                </p>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    Subject to certain limitations, the CCPA provides California residents with the right to:
                                </p>
                                <ul style={{listStyleType: 'disc'}}>
                                    <li style={{paddingBottom:'12px'}}>
                                        request to know about the personal information that we have collected about you in the 12 months preceding your request (including the categories of information collected, the source of that information, the business purpose of that collection, the categories of third parties with whom that information is shared, and the specific pieces of personal information collected about you);
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        request to delete any personal information about you that we have collected;
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        opt-out of “sales” of personal information to third parties, if applicable; and
                                    </li>
                                    <li style={{paddingBottom:'12px'}}>
                                        not to be discriminated against for exercising these rights.
                                    </li>
                                </ul>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    CCPA requires that we provide transparency about personal information we “sell,” which is broadly defined. While we don’t share personal information in exchange for money, we do allow Google’s online advertising service to collect identifiers (such as cookies and other device identifiers) and internet or other electronic network information (such as the webpage you visited) through the {campus_name} Buy / Sell Service for the purpose of sending personalized ads to you. This activity may be considered a “sale” under California law. To opt out of this “sale,” please send an email to
                                    {' '}
                                    <a rel="noopener noreferrer" href="mailto:support@campusbuysell.com">
                                        support@campusbuysell.com
                                    </a> 
                                    {' '}
                                    page. We do not knowingly sell personal information about consumers under the age of 16.
                                </p>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    To submit a request to know about your personal information or a request to delete your account, please send an email to 
                                    {' '}
                                    <a rel="noopener noreferrer" href="mailto:support@campusbuysell.com" target="_blank">
                                        support@campusbuysell.com
                                    </a>
                                    . We will verify your request by asking you to provide information that matches information we have on file about you. You may designate another person as your authorized agent to act on your behalf with regard to CCPA requests. If you have designated an authorized agent to submit requests on your behalf, we will require proof that the person is authorized to act on your behalf and may also still ask you to verify your identity with us directly.
                                </p>
                                <strong>
                                    <li style={{paddingBottom:'12px'}}>
                                        Questions and Comments.
                                    </li>
                                </strong>
                                <p style={{marginBottom:'12px', marginTop:'0', lineHeight:'1.5'}}>
                                    If you'd like to provide feedback to us about this Privacy Policy, or if you have any questions, please contact us at 
                                    {' '}
                                    <a rel="noopener noreferrer" target="_blank" href="mailto:support@campusbuysell.com">
                                        support@campusbuysell.com
                                    </a>
                                    .
                                </p>
                            </ol>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default Privacy_Policy;
