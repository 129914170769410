import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../../actions/alertActions';
import { addVerificationCode, checkVerificationCode } from '../../actions/verificationActions';
import { logout } from '../../actions/authActions';

// Components - imported
import { Button } from '@material-ui/core';

// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Icons -imported
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Logo from '../../components/common/Logo';

// Initial State 
const initialState = {
    verification_code: ''
};

const Verification = ({ 
    addVerificationCode,
    checkVerificationCode,
    logout,
    setAlert,
    auth: {
        isAuthenticated, 
        loading,
        user
    },
    nav: {
        campus_name,
        campus_id
    }
 }) => {

    // States for form data
    const [formData, setFormData] = useState(initialState); 

    // Get the URL filter and check if there is a "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);

    const { 
        verification_code
     } = formData;

    useEffect(() => {
        
        if(isAuthenticated && !loading && !user.verified) {
            addVerificationCode(campus_name);
        }

    }, [isAuthenticated]);

    // Function to handle input change
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    // Log the user out and Redirect to the login page
    const goBack = () => {

        logout();

    }

    const todo = (e) => {
        e.preventDefault();
        
        if(verification_code !== '') {
            checkVerificationCode(verification_code, null, campus_id);
        } else {
            setAlert('Please enter verification code', 'danger');
        } 
    }

    if(!isAuthenticated && !loading) {
        window.location.href = '/user_registration';
    }

    if(user && user.verified && !loading) {
        window.location.href = '/home';
    }

    return (
        <ModalPage>
            <div className="form-transition-element">

                {/* Button to go back */}
                <div onClick={goBack} className="form__backBtn">
                    <ArrowBackIcon />
                </div>
                    
                {/* Logo */}
                <Logo campus_name={campus_name} />

                {/* Header Description */}
                <div>
                    <h2>Confirm your email address</h2>
                </div>

                {/* <div style={{height:'27px'}}>
                    <Alert />
                </div> */}

                {/* Input Container */}
                <div className="modal__inputs" style={{flex:1}}>

                    {/* Input Description */}
                    <div>
                        <div style={{textAlign:'center',color:'rgb(83, 100, 113)', fontSize:'14px', fontWeight:'500', marginTop:'5px', lineHeight:'20px', overflowWrap:'break-word'}}>
                            <span>We sent a code to <b>{user?.email}</b>.</span>
                        </div>
                    </div>

                    {/* Verification Code input */}
                    <input
                        type="text"
                        placeholder="Enter code"
                        className="input"
                        name="verification_code"
                        value={verification_code}
                        onChange={e => onChange(e)}
                    />

                    {/* Input Disclaimer */}
                    <div onClick={() => addVerificationCode(campus_name)} style={{cursor: 'pointer', color:'rgb(27, 149, 224)', paddingLeft:'10px', fontSize:'15px', overflowWrap:'break-word'}}>
                        <span>Didn't receive the code?</span>
                    </div>
                </div>

                {/* Form Actions */}
                <div>
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet" 
                        fullWidth
                        // disabled={recommendationTags.length > 2 ? false : true} 
                        onClick={(e) => todo(e)} 
                        // className={recommendationTags.length > 2 ? "step-form-btn" : "step-form-btn disabled"}
                    >
                        Submit
                    </Button>
                </div>

                {/* Form footer */}
                <div className="auth__footer">
                    {/* <a href={`/register`}>Forgot password?</a>
                    <a href={`/login`}>Log In</a> */}
                </div>
            </div>
        </ModalPage>
    )
}

Verification.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    addVerificationCode: PropTypes.func.isRequired,
    checkVerificationCode: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    addVerificationCode,
    checkVerificationCode,
    logout,
    setAlert 
})(Verification);
