export const duke_valid_email_extensions = [
    'duke.edu',
    'dukehealth.org',
    'dm.duke.edu',
    'dental.duke.edu',
    'nursing.duke.edu',
    'dukeclinicalresearch.org',
    'law.duke.edu',
    'fuqua.duke.edu',
    'nicholas.duke.edu',
    'pratt.duke.edu',
    'div.duke.edu',
    'sanford.duke.edu',
    'trinity.duke.edu',
    'duke-nus.edu.sg',
    'dukece.com',
    'globalhealth.duke.edu',
    'neuro.duke.edu',
    'cs.duke.edu',
    'math.duke.edu',
    'phy.duke.edu',
    'alumni.duke.edu',
    'duaa.duke.edu',
    'library.duke.edu',
    'studentaffairs.duke.edu',
    // Add more emails extensions here
];