import React from 'react';
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Layout Wrapper
import ModalPage from '../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';

// Icons -imported
import TwitterIcon from '@material-ui/icons/Twitter';

// Imgs
import campusbuysell_logo from '../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../utils/imgs/ou_logo.png';
import ut_logo from '../utils/imgs/ut_logo.png';
import smu_logo from '../utils/imgs/smu_logo.png';
import uf_logo from '../utils/imgs/uf_logo.png';
import ucla_logo from '../utils/imgs/ucla_logo.png';
import stanford_logo from '../utils/imgs/stanford_logo.png';
import duke_logo from '../utils/imgs/duke_logo.png';

const Landing = ({ 
    auth: { 
        user 
    },
    nav: {
        campus_name
    }
}) => {
    let history = useHistory();

    // On login btn click redirect to login page
    const loginLink = () => {
        history.push('/login')
    }

    // On register btn click redirect to register page
    const registerLink = () => {
        history.push('/register')
    }

    // If user is already logged in Redirect
    if(user) {
        window.location.href = "/home";
        // history.push("/home");
    }

    // --- Campus Logis ----

    let app_logo;

    if(campus_name === 'Campus') {
            
        app_logo = campusbuysell_logo
    }
    else if(campus_name === 'OU') {
            
        app_logo = ou_logo
    }
    else if(campus_name === 'UT') {
        app_logo = ut_logo
    }
    else if(campus_name === 'SMU') {
        app_logo = smu_logo
    }
    else if(campus_name === 'UF') {
        app_logo = uf_logo
    }
    else if(campus_name === 'UCLA') {
        app_logo = ucla_logo
    }
    else if(campus_name === 'Stanford') {
        app_logo = stanford_logo
    }
    else if(campus_name === 'Duke') {
        app_logo = duke_logo
    }

    // --- END: Campus Logis ----

    return (
        <ModalPage>
            {/* <TwitterIcon className="modal__twitterIcon" /> */}

            {/* Logo image */}
            <img className="modal__twitterIcon" src={app_logo} style={{maxHeight: '40px'}} alt="logo" />
            
            {/* Main Text */}
            <div>
                <h2>University of Oklahoma</h2>
                <hr/>
                <h2>Buy / Sell</h2>
            </div>
            {/* <p>You can always log back in at any time. If you just want to switch accounts, you can do that by adding an existing account.</p> */}
            
            {/* Login and registration buttons */}
            <div className="btnRow">
                <Button 
                    onClick={loginLink}
                    variant="outlined" 
                    className="sidebar__tweet grey"
                >
                    Log in
                </Button>
                <Button 
                    onClick={registerLink}
                    variant="outlined" 
                    className="sidebar__tweet"
                >
                    Sign up
                </Button>
            </div>
        </ModalPage>
    )
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication state of the user
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, null)(Landing);
