import React from 'react'
import PropTypes from 'prop-types'

// Components
import DefaultAvatar from 'react-avatar';
import { Avatar, Button } from '@material-ui/core';

// Icons
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const People_Block = ({
    hit
}) => {
    return (
        <div onClick={() => window.location = `/${hit?.username}`} key={hit.objectID} className="userTable__block">
                        
            {/* User Avatar - left */}
            <div className="userTable__avatar">
                {hit.img ? (
                    <Avatar src={hit.img} />
                ) : (
                    <DefaultAvatar name={`${hit.first_name} ${hit.last_name}`} />
                )}
            </div>

            {/* Display the user's name & username */}
            <div className="userTable__info">
                <h3>
                    {hit.first_name}{' '}{hit.last_name}
                    <span className="userTable__infoSpecial">
                        <VerifiedUserIcon className="userTable__badge"></VerifiedUserIcon>
                    </span>
                </h3>
                <p>@{hit.username}</p>
            </div>
        </div>
  )
}

People_Block.propTypes = {}

export default People_Block