import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

// Components
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import GoogleBtn from '../common/GoogleBtn';

// Icons
import MailIcon from '@material-ui/icons/Mail';

const ProfileDropdown = ({
    auth,
    profileDropdown, 
    setProfileDropdown
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Add an event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update the window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine if the window is in mobile or tablet view
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1170;

    let user_name;

    if(!auth.loading && auth.isAuthenticated) {
        user_name = `${auth.user.first_name} ${auth.user.last_name && auth.user.last_name}`
    }

    // --- ImageKit (img optimization service) ---
    
    let optimized_profile_img_path;

    if(auth.user && auth.user.img) {
   
        // Replace the firebase URL with ImageKit.io URL-endpoint
        optimized_profile_img_path = auth.user.img.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");
    }

    // --- END: ImageKit (img optimization service) ---

    return (
        <Fragment>
            {/* Dropdown Menu */}
            <div className={profileDropdown ? "profile-dropdown-topNavbar active" : "profile-dropdown-topNavbar"}>
            <div onClick={() => window.location = `/${auth.user ? auth.user.username : 'profile'}`} className="menu-item" style={isMobile ? {padding: '1rem 1rem 0px 1rem'} : undefined}>
                <div className='sidebar__profile topNav'>
                    <div className="post__avatar">

                        {auth.user && auth.user.img ? (
                            <Avatar src={optimized_profile_img_path} />
                        ) : (
                            <DefaultAvatar name={auth.user && `${auth.user.first_name} ${auth.user.last_name}`} />
                        )}
                    </div>
                    <div  className='sidebar__profile__text__container'>
                        <div className='sidebar__profile__text'>
                            <div>
                                <span>
                                    {user_name}
                                </span>
                            </div>
                            {auth.user && (
                                <div>
                                    <div>
                                        <span>@{auth.user.username}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
            
            <div onClick={() => window.location = '/saved'} className="menu-item">
                My Saved
            </div>

            <div onClick={() => window.location = '/settings/account'} className="menu-item">
                Account Settings
            </div>

            <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />
            
            <div onClick={() => window.location = '/logout'} className="menu-item danger">
                Log Out
            </div>
        </div>

        {/* Hidden Overlay for dropdown */}
        <div
            className={`hidden-overlay ${profileDropdown ? "show" : ""}`}
            onClick={() => setProfileDropdown(!profileDropdown)}
        />
        </Fragment>
    )
}

ProfileDropdown.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    // Mapping the AUTHENTICATION state
    auth: state.auth,
})

export default connect(mapStateToProps, {
    
    // Connecting actions to the component
})(ProfileDropdown);