import React from 'react'
import PropTypes from 'prop-types'

// Icons
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const Verification_Badge = ({
    user
}) => {
  return (
    <VerifiedUserIcon 
        className={`post__badge ${user?.verified ? 'active' : ''}`}
    >
        
    </VerifiedUserIcon>
  )
}

Verification_Badge.propTypes = {}

export default Verification_Badge