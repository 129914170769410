import React from 'react'

import campus_logo from '../../utils/imgs/campusbuysell_logo.png';
import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';
import uf_logo from '../../utils/imgs/uf_logo.png';

// A functional component representing a logo
const Logo = ({
    campus_name
}) => {

    // --- Campus Logic ----

    let app_logo;

    if(campus_name.toLowerCase() === 'campus') {
            
        app_logo = campus_logo
    }
    else if(campus_name.toLowerCase() === 'ou') {
            
        app_logo = ou_logo
    }
    else if(campus_name.toLowerCase() === 'ut') {
        app_logo = ut_logo
    }
    else if(campus_name.toLowerCase() === 'smu') {
        app_logo = smu_logo
    } 
    else if(campus_name.toLowerCase() === 'uf') {
        app_logo = uf_logo
    } 
    else {
        app_logo = ou_logo
    }

    // --- END: Campus Logic ----

     // Render an image element with the logo
    return <img className="modal__twitterIcon" src={app_logo} style={{maxHeight: '40px'}} alt="logo" />;
}

Logo.propTypes = {

}

// Export component as the default export
export default Logo
