import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// Routing
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// Redux
import { connect } from 'react-redux';

// Pages
import Default from './Default';
import FeedPage from '../../pages/FeedPage';
import HowItWorksPage from '../../pages/HowItWorksPage';
import Notifications from '../../pages/Notifications/Notifications';
import Mentions from '../../pages/Notifications/Mentions';
import Messages from '../../pages/Messages';
import ChatNav from '../../pages/ChatNav';
import SearchPage from '../../pages/SearchPage/SearchPage';
import School_SearchPage from '../../pages/SearchPage/School_SearchPage';
import LikePage from '../../pages/LikePage';
import Profile from '../../pages/Profile';
import EditProfile from '../../pages/EditProfile';
import Settings from '../../pages/Settings';
import SettingsNav from '../../pages/SettingsNav';
import PostPage from '../../pages/PostPage';
import Detail_MediaPage from '../../pages/MediaPage/Detail_MediaPage';
import LikedBy from '../../pages/LikedBy';
import AboutPage from '../../pages/AboutPage';
import MediaPage from '../../pages/MediaPage/MediaPage';

const Routes = ({ auth: { user, isAuthenticated, loading }}) => {

  // Screen Width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {

    // Add event listener for window resize
    window.addEventListener('resize', () => handleWindowSizeChange());
      
    // Clean up by removing event listener on component unmount
    return () => window.removeEventListener('resize', () => handleWindowSizeChange());
  }, [window.location]);

  // Handle window size change
  const handleWindowSizeChange = () => {
      setWindowWidth(window.innerWidth);
  };

  // Determine if the current screen width is mobile or tablet
  const isMobile = windowWidth <= 769;
  const isTablet = windowWidth <= 1000;

  return (
    <Fragment>
        <Switch>

            {/* Home page */}
            <Route exact path="/home" component={FeedPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/media" component={MediaPage} />
            
            <Route exact path="/search" component={SearchPage} />
            <Route exact path="/search_schools" component={School_SearchPage} />
            {/* <Route exact path="/howitworks" component={HowItWorksPage} /> */}

            {/* Notifications Page */}
            <Route exact path="/notifications" component={Notifications} />
            <PrivateRoute exact path="/notifications/mentions" component={Mentions} />
            
            {/* Messages */}
            <Route exact path="/messages" component={!isTablet ? Messages : ChatNav} />
            <Route exact path="/messages" component={Messages} />

            <Route exact path="/saved" component={LikePage} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/profile/edit" component={EditProfile} />

            {/* Settings */}
            <Route exact path="/settings" component={SettingsNav}>

              {/* Redirect based on authentication and loading state */}
              {!isTablet && (
                !loading && isAuthenticated ? (
                  <Redirect to="/settings/account" /> 
                ) : (
                  <Redirect to="/settings/about" />
                )
              )}
            </Route>

            {/* Setting Account Pages */}
            <PrivateRoute exact path="/settings/account/screen_name" component={Settings} />
            <PrivateRoute exact path="/settings/account/password" component={Settings} />
            <PrivateRoute exact path="/settings/account/email" component={Settings} />
            <PrivateRoute exact path="/settings/account/school_email" component={Settings} />
            <PrivateRoute exact path="/settings/account/verify_school_email" component={Settings} />
            <PrivateRoute exact path="/settings/account/phone" component={Settings} />
            <PrivateRoute exact path="/settings/account/gender" component={Settings} />
            <PrivateRoute exact path="/settings/account/birth_date" component={Settings} />
            {/* End */}
            
            {/* More Settings Pages */}
            <PrivateRoute exact path="/settings/payments" component={Settings} />
            <PrivateRoute exact path="/settings/account" component={Settings} />
            <Route exact path="/settings/howitworks" component={Settings} />
            <Route exact path="/settings/about" component={Settings} />
            <Route exact path="/settings/help" component={Settings} />
            <Route exact path="/settings/feedback" component={Settings} />
            <Route exact path="/settings/returns" component={Settings} />
            <Route exact path="/settings/terms" component={Settings} />
            <Route exact path="/settings/trust_and_safety" component={Settings} />
            <Route exact path="/settings/privacy" component={Settings} />
            <Route exact path="/settings/cookies" component={Settings} />

            {/* Detail Post pages */}
            <Route exact path="/post/:id" component={PostPage} />
            <Route exact path="/post/:id/likes" component={LikedBy} />
            <Route exact path="/media/:id" component={Detail_MediaPage} />

            {/* ChatBox Page */}
            <PrivateRoute exact path="/messages/:id" component={Messages} />

            {/* Profile Page */}
            <Route exact path="/:username" component={Profile} />

            {/* Page not found */}
            <Route component={Default} />
        </Switch>
    </Fragment>
  );
};

Routes.propTypes = {
  auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
}

const mapStateToProps = state => ({

  // Mapping the states from the Redux store to the below props
  auth: state.auth
})

// Connect the SidebarMobile component to the Redux store and export it
export default connect(mapStateToProps, null)(Routes);
