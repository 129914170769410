import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Icons
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const FAQ_Container = () => {

    const [dropdown_1, setDropdown_1] = useState(true);
    const [dropdown_2, setDropdown_2] = useState(false);
    const [dropdown_3, setDropdown_3] = useState(false);
    const [dropdown_4, setDropdown_4] = useState(false);
    const [dropdown_5, setDropdown_5] = useState(false);

    // Capitalize the first letter of each word
    const capitalizeWords = (inputString) => {
        return replaceHyphenWithSpace(inputString.replace(/\b\w/g, char => char.toUpperCase()));
    }

    const replaceHyphenWithSpace = (inputString) => {
        return replaceIesWithY(inputString.replace(/-/g, ' '));
    }

    const replaceIesWithY = (inputString) => {
        return removeLastS(inputString.replace(/ies\b/g, 'y'));
    }

    const removeLastS = (inputString) => {
        return inputString.replace(/s\b/g, '');
    }

    return (
        <div style={{width: '100%', boxSizing: 'border-box', flexWrap: 'wrap', display: 'flex', margin: 'auto'}}>
            <div style={{padding: '24px 0', display: 'flex', flexDirection: 'column', flex: 1}}>
                {/* FAQ category description */}
                <div style={{marginBottom: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                    <div style={{fontSize: '28px', fontWeight: 'bold'}}>
                        FAQ
                    </div>
                </div>
                <div style={{width: '100%', overflow: 'hidden', borderBottom: '1px solid #e6ecf0'}}>
                    <div onClick={() => setDropdown_1(!dropdown_1)} className='faq_dropdown_header'>
                        <div style={{marginRight: '12px', flex: 1}}>
                            <div style={{maxWidth: '100%', textOverflow: 'ellipsis', fontSize: '22px', fontWeight: 'bold', overflow: 'hidden'}}>
                                Are we affiliated with your school?
                            </div>
                        </div>
                        <div style={{border: '2px solid #000', height: '25px', width: '25px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {dropdown_1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </div>
                    </div>
                    <div style={{height: `${dropdown_1 ? 'fit-content' : '0'}`, overflow: 'hidden', margin: '10px 16px 20px'}}>
                        <div style={{fontSize: '16px', lineHeight: '22px'}}>
                            No! We aren't associated with your school.
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', overflow: 'hidden', borderBottom: '1px solid #e6ecf0'}}>
                    <div onClick={() => setDropdown_2(!dropdown_2)} className='faq_dropdown_header'>
                        <div style={{marginRight: '12px', flex: 1}}>
                            <div style={{maxWidth: '100%', textOverflow: 'ellipsis', fontSize: '22px', fontWeight: 'bold', overflow: 'hidden'}}>
                                I'm a student! How do I join my campus? 
                            </div>
                        </div>
                        <div style={{border: '2px solid #000', height: '25px', width: '25px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {dropdown_2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </div>
                    </div>
                    <div style={{height: `${dropdown_2 ? 'fit-content' : '0'}`, overflow: 'hidden', margin: '10px 16px 20px'}}>
                        <div style={{fontSize: '16px', lineHeight: '22px'}}>
                            All students need is a .edu email to join their campus. Simply log in and verify your .edu email to join the community.
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', overflow: 'hidden', borderBottom: '1px solid #e6ecf0'}}>
                    <div onClick={() => setDropdown_3(!dropdown_3)} className='faq_dropdown_header'>
                        <div style={{marginRight: '12px', flex: 1}}>
                            <div style={{maxWidth: '100%', textOverflow: 'ellipsis', fontSize: '22px', fontWeight: 'bold', overflow: 'hidden'}}>
                                I don’t see my school. How can I add my school?
                            </div>
                        </div>
                        <div style={{border: '2px solid #000', height: '25px', width: '25px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {dropdown_3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </div>
                    </div>
                    <div style={{height: `${dropdown_3 ? 'fit-content' : '0'}`, overflow: 'hidden', margin: '10px 16px 20px'}}>
                        <div style={{fontSize: '16px', lineHeight: '22px'}}>
                            It’s possible your school's community does not exist yet. Don’t fret! You can be the first to get your campus going, to setup a new school please just send us a DM on Instagram @CampusBuySell with your school's full name and short name (optional).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

FAQ_Container.propTypes = {}

export default FAQ_Container