import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

// Initial state of the alert array
const initialState = [];

// Reducer function to manage alerts in the state
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case 'SET_ALERT':

            // Add a new alert to the state array
            return [...state, payload];
        case 'REMOVE_ALERT':

        // Remove an alert from the state array based on its ID
            return state.filter(alert => alert.id !== payload);
        default:

            // Return the current state if the action type is not recognized
            return state;
    }
}