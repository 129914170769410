import { db, auth } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, limit, increment } from 'firebase/firestore';
import { setAlert } from './alertActions';

import {  
    GET_RECENT_SEARCHES,
    GET_POPULAR_SEARCHES,
    SEARCHES_ERROR,
    CLEAR_POPULAR_SEARCHES,
    POPULAR_SEARCHES_LOADING,
    CLEAR_RECENT_SEARCHES,
    DELETE_RECENT_SEARCH,
    RECENT_SEARCHES_LOADING
} from './types';

// Reference to the "searches" collection in Firestore
const searchCollectionRef = collection(db, "searches");
const searchCollectionRef_marketplace = collection(db, "searches_marketplace");

// Add New Search Query
export const addSearchQuery = ( queryText, userData ) => async dispatch => {

    try {

        console.log('ADDING SEARCH QUERY');

        // Check if search query has already been added
        console.log("CHECKING SEARCH QUERY")

        // Create a query to check if the username already exists in the users collection
        const searchQuery = query (searchCollectionRef, where("text", "==", queryText))

        // Retrieve the list of users with the same username
        const array_of_results = await getDocs(searchQuery);

        if(array_of_results.docs.length > 0) {

            if(array_of_results.docs[0]) {
                // Reference to the user document in the Firestore database
                const searchDocRef = doc(db, "searches", array_of_results.docs[0].id);

                // --- Update the user's search history if logged in AND increment the search query's 'views' count

                if(userData) {
                    console.log('UPDATING USER SEARCH HISTORY');

                    // Get the reference to the user and to the user's search history collection
                    const docRef = doc(db, 'users', userData._id);
                    const colRef = collection(docRef, "searches");

                    // Fetch the newly created search document
                    const searchQueryDoc = await getDoc(searchDocRef);

                    // Create a query to check if the search already exists in the users search history collection
                    const q = query(colRef, where("search_text", "==", searchQueryDoc.data().text));

                    // Fetch the user's search history
                    const searchHistory = await getDocs(q);

                    if(searchHistory.docs.length > 0) {
                        // dispatch(setAlert('You have searched this before.', 'danger'));

                        // Create a reference to the user's search document in the "searches" sub-collection
                        const searchHistoryDocRef = doc(colRef, searchHistory.docs[0].id)

                        // The search already exists in the user's history, increment the 'views' and proceed with the update in the user searches sub-collection
                        await updateDoc(searchHistoryDocRef, {
                            ...searchHistory.docs[0].data(),
                            search_qty: searchHistory.docs[0].data().search_qty + 1,
                            lastModified: serverTimestamp() 
                        })
                        
                    } else {
                        // Add the new search to the "searches" subcollection of the user
                        await addDoc(colRef, {
                            search_id: searchQueryDoc.id,
                            search_text: searchQueryDoc.data().text,
                            search_qty: 1,
                            date: Date.now(),
                            lastModified: serverTimestamp() 
                        });

                        const updatedUniqueViewCount = array_of_results.docs[0].data().unique_views + 1;
                        const oldGhostViewCount = array_of_results.docs[0].data().ghost_views;
                        const newTotalViewCount = updatedUniqueViewCount + oldGhostViewCount;

                        // The search already exists, increment the 'views' and proceed with the update in the main searches collection
                        await updateDoc(searchDocRef, {
                            ...array_of_results.docs[0].data(),
                            unique_views: updatedUniqueViewCount,
                            total_views: newTotalViewCount,
                            lastModified: serverTimestamp() 
                        })

                        console.log('SEARCH QUERY UPDATED');
                    }

                    console.log('UPDATED USER SEARCH HISTORY');
                } else {

                    const oldUniqueViewCount = array_of_results.docs[0].data().unique_views;
                    const updatedGhostViewCount = array_of_results.docs[0].data().ghost_views + 1;
                    const newTotalViewCount = oldUniqueViewCount + updatedGhostViewCount;

                    // The search already exists, increment the 'views' and proceed with the update with the update in the main searches collection
                    await updateDoc(searchDocRef, {
                        ...array_of_results.docs[0].data(),
                        ghost_views: updatedGhostViewCount,
                        total_views: newTotalViewCount,
                        lastModified: serverTimestamp()
                    })

                    // dispatch(setAlert('Not logged in', 'danger'));
                }

                // --- END updating User's search history

            } else {
                dispatch(setAlert('That query has already been added.', 'danger'));
            }

        } else {
            // --- Prepare the fields for the new search query document

            const docFields = {};

            docFields.text = queryText;

            if(userData) {
                docFields.unique_views = 1;
                docFields.ghost_views = 0
            } else {
                docFields.unique_views = 0;
                docFields.ghost_views = 1;
            }

            docFields.total_views = 1;
            
            docFields.date = Date.now();
            docFields.lastModified = serverTimestamp();

            // Add search query document to the searches collection
            const searchDocRef = await addDoc(searchCollectionRef, docFields);

            console.log('SEARCH QUERY ADDED');

            // --- Update the user's search history if logged in

            if(userData) {
                console.log('UPDATING USER SEARCH HISTORY');

                // Get the reference to the user and to the user's search history collection
                const docRef = doc(db, 'users', userData._id);
                const colRef = collection(docRef, "searches");

                // Fetch the newly created search document
                const searchQueryDoc = await getDoc(searchDocRef);

                // Create a query to check if the search already exists in the users search history collection
                const q = query(colRef, where("search_text", "==", searchQueryDoc.data().text));

                // Fetch the user's search history
                const searchHistory = await getDocs(q);

                if(searchHistory.docs.length > 0) {
                    // dispatch(setAlert('You have searched this before.', 'danger'));
                } else {
                    // Add the new search to the "searches" subcollection of the user
                    await addDoc(colRef, {
                        search_id: searchQueryDoc.id,
                        search_text: searchQueryDoc.data().text,
                        search_qty: 1,
                        date: Date.now(),
                        lastModified: serverTimestamp(),
                    });
                }
            } else {
                // dispatch(setAlert('Not logged in', 'danger'));
            }

            // --- END updating User's search history
        }

    } catch (err) {
        console.log('ERROR ADDING NEW SEARCH QUERY!!!');
        console.log(err);

        // Handle the error if creating the notification fails
        //   dispatch({
        //     type: NOTIFICATION_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        //   });
    }
};

// Get top 14 popular searches
export const getPopularSearches = () => async dispatch => {
 
    console.log('GETTING POPULAR SEARCHES');
    try {
        console.log('QUERYING MOST VIEWED SEARCHES');

        // Create a query to retrieve posts where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(searchCollectionRef, orderBy('total_views', 'desc'), limit(14));

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const popularSearches = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Dispatch an action of type GET_POPULAR_SEARCHES with the user posts as the payload
        dispatch({
            type: GET_POPULAR_SEARCHES,
            payload: popularSearches
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_POPULAR_SEARCHES with an empty array as the payload in case of an error
        dispatch({
            type: GET_POPULAR_SEARCHES,
            payload: []
        })
    }
};

// Get Recent Searches By User Id
export const getRecentSearches = (userId) => async dispatch => {
 
    console.log('GETTING RECENT SEARCHES');
    try {
        console.log('QUERYING RECENT SEARCHES');

        // Get the reference to the user and to the user's search history collection
        const docRef = doc(db, 'users', userId);
        const colRef = collection(docRef, "searches");

        // Create a query to retrieve posts where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(colRef, orderBy('lastModified', 'desc'));

        // Fetch the user's search history
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const recentSearches = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Dispatch an action of type GET_RECENT_SEARCHES with the user posts as the payload
        dispatch({
            type: GET_RECENT_SEARCHES,
            payload: recentSearches
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_RECENT_SEARCHES with an empty array as the payload in case of an error
        dispatch({
            type: GET_RECENT_SEARCHES,
            payload: []
        })
    }
};

// Delete Comment
export const deleteRecentSearch = (userId, recentSearchId) => async dispatch => {
    console.log('DELETING RECENT SEARCH!!!!!')

    // Delete search from user search history in the 'searches' sub-collection
    const docRef = doc(db, `users/${userId}/searches`, recentSearchId)

    try {

        // Delete the search document
        await deleteDoc(docRef);

        // Dispatch the DELETE_RECENT_SEARCH action with the recentSearchId payload to remove the search query from the state
        dispatch({
            type: DELETE_RECENT_SEARCH,
            payload: recentSearchId
        });

        // Display a success alert
        dispatch(setAlert('Recent Search Removed', 'success'));

    } catch (err) {

        console.log('ERROR');
        console.log(err);

        // Display an error alert if something goes wrong during the deletion
        dispatch(setAlert('Something went wrong', 'danger'));

        // Uncomment the following code if you have specific error handling logic or dispatches for the POST_ERROR action
        // dispatch({
        //     type: POST_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
    }
}   

// Delete Comment
export const clearRecentSearch = (userId) => async dispatch => {
    console.log('DELETING ALL RECENT SEARCHES!!!!!')


    try {
        // Get the reference to the user and to the user's search history collection
        const docRef = doc(db, 'users', userId);
        const colRef = collection(docRef, "searches");

        // Fetch the user's search history
        const querySnapshot = await getDocs(colRef);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        querySnapshot.docs.map(async (searchDoc) => {
            // Delete search from user search history in the 'searches' sub-collection
            const docRef = doc(db, `users/${userId}/searches`, searchDoc.id);

            // Delete the search document
            await deleteDoc(docRef);
        });

        // Dispatch the CLEAR_RECENT_SEARCHES action to remove all search queries from the state
        dispatch({
            type: CLEAR_RECENT_SEARCHES
        });

        // Display a success alert
        dispatch(setAlert('Search History Cleared', 'success'));

    } catch (err) {

        console.log('ERROR');
        console.log(err);

        // Display an error alert if something goes wrong during the deletion
        dispatch(setAlert('Something went wrong', 'danger'));

        // Uncomment the following code if you have specific error handling logic or dispatches for the POST_ERROR action
        // dispatch({
        //     type: POST_ERROR,
        //     payload: { msg: err.response.statusText, status: err.response.status }
        // });
    }
}   


// ---------- Marketplace -------------

// Add New Search Query -> Marketplace
export const addSearchQuery_Marketplace = ( queryText ) => async dispatch => {

    try {

        console.log('ADDING SEARCH QUERY');

        // Check if search query has already been added
        console.log("CHECKING SEARCH QUERY")

        // Create a query to check if the username already exists in the users collection
        const searchQuery = query(searchCollectionRef_marketplace, where("text", "==", queryText))

        // Retrieve the list of users with the same username
        const array_of_results = await getDocs(searchQuery);

        if(array_of_results.docs.length > 0) {

            if(array_of_results.docs[0]) {
                // Reference to the user document in the Firestore database
                const searchDocRef = doc(db, "searches_marketplace", array_of_results.docs[0].id);

                let updatedGhostViewCount = array_of_results.docs[0].data().ghost_views;

                if(!auth.currentUser) {
                    updatedGhostViewCount++
                }

                // The search already exists, increment the 'views' and proceed with the update with the update in the main searches_marketplace collection
                await updateDoc(searchDocRef, {
                    ...array_of_results.docs[0].data(),
                    ghost_views: updatedGhostViewCount,
                    total_views: increment(1),
                    lastModified: serverTimestamp()
                })

            } else {
                dispatch(setAlert('That query has already been added.', 'danger'));
            }

        } else {
            // --- Prepare the fields for the new search query document

            const docFields = {};

            docFields.text = queryText;

            if(auth.currentUser) {
                docFields.unique_views = 1;
                docFields.ghost_views = 0
            } else {
                docFields.unique_views = 0;
                docFields.ghost_views = 1;
            }

            docFields.total_views = 1;
            
            docFields.date = Date.now();
            docFields.lastModified = serverTimestamp();

            // Add search query document to the searches_marketplace collection
            await addDoc(searchCollectionRef_marketplace, docFields);

            console.log('SEARCH QUERY ADDED');
        }

    } catch (err) {

        console.log('ERROR ADDING NEW SEARCH QUERY!!!');
        console.log(err);
    }
};

// Get top 14 popular searches
export const getPopularSearches_Marketplace = () => async dispatch => {
 
    console.log('GETTING POPULAR SEARCHES');
    try {
        console.log('QUERYING MOST VIEWED SEARCHES');

        // Create a query to retrieve posts where the user ID matches the provided ID, ordered by creation date in descending order
        const q = query(searchCollectionRef_marketplace, orderBy('total_views', 'desc'), limit(14));

        // Execute the query and retrieve the query snapshot
        const querySnapshot = await getDocs(q);

        // Map through the document snapshots in the query snapshot and extract the data and ID for each post
        const popularSearches = querySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Dispatch an action of type GET_POPULAR_SEARCHES with the user posts as the payload
        dispatch({
            type: GET_POPULAR_SEARCHES,
            payload: popularSearches
        });
    } catch (err) {
        console.log('ERROR!!!')
        console.log(err)

        // Dispatch an action of type GET_POPULAR_SEARCHES with an empty array as the payload in case of an error
        dispatch({
            type: GET_POPULAR_SEARCHES,
            payload: []
        })
    }
};


