export const ut_valid_email_extensions = [
    // UT Austin
    'utexas.edu',
    'mail.utexas.edu',
    'alumni.utexas.edu',
    'mccombs.utexas.edu',
    'cns.utexas.edu',
    'engr.utexas.edu',
    'law.utexas.edu',
    'pharmacy.utexas.edu',
    'austin.utexas.edu',
    // UT Dallas
    'utdallas.edu',
    // UT Arlington
    '@uta.edu',
    // UT San Antonio
    'utsa.edu',
    // UT Tyler
    'uttyler.edu',
    // UT El Paso
    'utep.edu',
    // University of Texas Medical Branch at Galveston (UTMB)
    'utmb.edu',
    // UT Southwestern Medical Center (UT Southwestern)
    'utsouthwestern.edu',
    // UT Rio Grande Valley
    'utrgv.edu',
    // UTHealth San Antonio
    'uthscsa.edu',
    // UT Permian Basin
    'utpb.edu',
    // UTHealth Houston
    'uth.tmc.edu',
    'uthouston.edu',
    // University of Texas MD Anderson Cancer Center
    'mdanderson.org',
    // University of Texas Health Science Center at Tyler
    'uthct.edu',
    // Used by the University of Texas System Administration.
    'utsystem.edu',
    // Other Specific Extensions
    'my.utexas.edu', // Sometimes used for student-specific accounts at UT Austin.
    'ic2.utexas.edu' // Used by the IC² Institute, a think tank at UT Austin.
    // Add more emails extensions here
];