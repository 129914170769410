import React, { Fragment, useEffect, useState } from 'react';

// Components - material UI
import { Button } from '@material-ui/core';

// Icons - material UI
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import monthData from '../../../utils/calenderData/months';
import dayData from '../../../utils/calenderData/days';
import yearData from '../../../utils/calenderData/years';

const Birth_Date = ({
    onChange,
    month, 
    day,
    year,
    birthDateTodo,
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    goBack
}) => {

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {
        
        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    return (
        <div className="settings">

            {/*Header */}
            <div className="settings__header">

                {/* Back Btn Icon & Badge Value */}
                <div onClick={goBack} className="profile__btnContainer">
                    <ArrowBackIcon />
                    {isMobile && badgeValue > 0 ? (
                        <span className="feed-header-badge defaultBadge">
                            {badgeValue}
                        </span>
                    ) : null}
                </div>

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        Birth Date
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body">
                <div style={{width: '100%', boxSizing:'border-box', padding:'10px', display:'flex', justifyContent:'center', borderBottom:'1px solid #e6ecf0'}}>
                    
                    {/* Month */}
                    <div style={{marginRight:'12px', cursor:'pointer', position: 'relative', flexGrow: 2, backgroundColor:'#fff', borderRadius:'4px', border: '1px solid rgb(207, 217, 222)'}}>
                        <label style={{lineHeight:'16px', fontSize:'13px', paddingTop:'8px', color:'rgb(83, 100, 113)', position:'absolute', overflowWrap: 'break-word', paddingLeft:'8px', paddingRight:'8px'}}>
                            <span>Month</span>
                        </label>
                        <select 
                            name="month" 
                            onChange={e => onChange(e)}
                            style={{width:'100%', paddingLeft:'8px', paddingRight:'8px', paddingTop:'12px', lineHeight:'20px', outline:'none', border:'none', backgroundColor:'rgba(0, 0, 0, 0)', appearance:'none', outlineStyle:'none', color:'rgb(15, 20, 25)', fontSize:'17px', marginTop:'16px', paddingBottom:'8px', cursor:'pointer'}}
                        >
                            <option value="month">{month && `${month.charAt(0).toUpperCase() + month.slice(1)}`}</option>
                            {monthData.map((item, index) => (
                                <option key={index} value={`${item.value}`}>{item.value}</option>
                            ))}
                        </select>
                        <KeyboardArrowDownIcon style={{top: '50%', right:'12px', marginTop:'-0.75em', height:'1.5em', color:'rgb(83, 100, 113)', position:'absolute'}} />
                    </div>

                    {/* Day */}
                    <div style={{marginRight:'12px', cursor:'pointer', position: 'relative', flexGrow: 1, backgroundColor:'#fff', borderRadius:'4px', border: '1px solid rgb(207, 217, 222)'}}>
                        <label style={{lineHeight:'16px', fontSize:'13px', paddingTop:'8px', color:'rgb(83, 100, 113)', position:'absolute', overflowWrap: 'break-word', paddingLeft:'8px', paddingRight:'8px'}}>
                            <span>Day</span>
                        </label>
                        <select 
                            name="day" 
                            onChange={e => onChange(e)}
                            style={{width:'100%', paddingLeft:'8px', paddingRight:'8px', paddingTop:'12px', lineHeight:'20px', outline:'none', border:'none', backgroundColor:'rgba(0, 0, 0, 0)', appearance:'none', outlineStyle:'none', color:'rgb(15, 20, 25)', fontSize:'17px', marginTop:'16px', paddingBottom:'8px', cursor:'pointer'}}
                        >
                            <option value="day">{day && day}</option>
                            {dayData.map((item, index) => (
                                <option key={index} value={`${item.value}`}>{item.value}</option>
                            ))}
                        </select>
                        <KeyboardArrowDownIcon style={{top: '50%', right:'12px', marginTop:'-0.75em', height:'1.5em', color:'rgb(83, 100, 113)', position:'absolute'}} />
                    </div>

                    {/* Year */}
                    <div style={{cursor:'pointer', position: 'relative', flexGrow: 1, backgroundColor:'#fff', borderRadius:'4px', border: '1px solid rgb(207, 217, 222)'}}>
                        <label style={{lineHeight:'16px', fontSize:'13px', paddingTop:'8px', color:'rgb(83, 100, 113)', position:'absolute', overflowWrap: 'break-word', paddingLeft:'8px', paddingRight:'8px'}}>
                            <span>Year</span>
                        </label>
                        <select 
                            name="year" 
                            onChange={e => onChange(e)}
                            style={{width:'100%', paddingLeft:'8px', paddingRight:'8px', paddingTop:'12px', lineHeight:'20px', outline:'none', border:'none', backgroundColor:'rgba(0, 0, 0, 0)', appearance:'none', outlineStyle:'none', color:'rgb(15, 20, 25)', fontSize:'17px', marginTop:'16px', paddingBottom:'8px', cursor:'pointer'}}
                        >
                            <option value="year">{year && year}</option>
                            {yearData.map((item, index) => (
                                <option key={index} value={`${item.value}`}>{item.value}</option>
                            ))}
                        </select>
                        <KeyboardArrowDownIcon style={{top: '50%', right:'12px', marginTop:'-0.75em', height:'1.5em', color:'rgb(83, 100, 113)', position:'absolute'}} />
                    </div>
                </div>

                {/* Submit */}
                <div style={{width: '100%', boxSizing:'border-box', padding:'0 10px', display:'flex', justifyContent:'flex-end'}}>
                    <Button 
                        onClick={birthDateTodo}
                        variant="outlined" 
                        className="sidebar__tweet save" 
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Birth_Date;
