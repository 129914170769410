import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import { toggleShareModal } from '../../actions/navActions';

// Components - imported
import { Button } from '@material-ui/core';

// Icons - imported
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import RoomIcon from '@material-ui/icons/Room';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const SearchWidgets = ({ toggleShareModal, auth }) => {
    return (
        <div className="widgets">

            <div className="widgets__authContainer">

                {/* Title */}
                <div style={{paddingBottom: '12px'}}>
                    <h2>Search filters</h2>
                </div>

                {/* Search Filter Option */}
                <div style={{paddingBottom: '10px', boxSizing: 'border-box'}}>
                    <div style={{paddingBottom: '8px', paddingTop: '12px', color: 'rgb(15, 20, 25', fontWeight: 700, fontSize: '16px', lineHeight: '20px', wordWrap: 'break-word'}}>
                        <span style={{wordWrap: 'break-word'}}>People</span>
                    </div>

                    <div style={{cursor: 'pointer', paddingBottom: '4px', paddingTop: '4px'}}>
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', flexGrow: 1}}>
                            <div style={{color: 'rgb(15, 20, 25)', fontWeight: 400, fontSize: '15px', lineHeight: '20px', wordWrap: 'break-word'}}>
                                <span>From anyone</span>
                            </div>

                            <div style={{marginLeft: '20px', height: '100%', userSelect: 'none', position: 'relative', boxSizing: 'border-box'}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'16px', width:'16px', border:'2px solid rgb(29, 155, 240)', padding:'2px', borderRadius:'50%'}}>
                                    <div style={{height:'100%', width:'100%', background:'rgb(29, 155, 240)', borderRadius:'50%'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{cursor: 'pointer', paddingBottom: '4px', paddingTop: '4px'}}>
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', flexGrow: 1}}>
                            <div style={{color: 'rgb(15, 20, 25)', fontWeight: 400, fontSize: '15px', lineHeight: '20px', wordWrap: 'break-word'}}>
                                <span>People you follow</span>
                            </div>

                            <div style={{marginLeft: '20px', height: '100%', userSelect: 'none', position: 'relative', boxSizing: 'border-box'}}>
                                <div style={{height:'19px', width:'19px', border:'2px solid rgb(15, 20, 25', borderRadius:'50%'}}></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Search Filter Option */}
                <div style={{paddingBottom: '10px', boxSizing: 'border-box'}}>
                    <div style={{paddingBottom: '8px', paddingTop: '12px', color: 'rgb(15, 20, 25', fontWeight: 700, fontSize: '16px', lineHeight: '20px', wordWrap: 'break-word'}}>
                        <span style={{wordWrap: 'break-word'}}>Location</span>
                    </div>

                    <div style={{cursor: 'pointer', paddingBottom: '4px', paddingTop: '4px'}}>
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', flexGrow: 1}}>
                            <div style={{color: 'rgb(15, 20, 25)', fontWeight: 400, fontSize: '15px', lineHeight: '20px', wordWrap: 'break-word'}}>
                                <span>Anywhere</span>
                            </div>

                            <div style={{marginLeft: '20px', height: '100%', userSelect: 'none', position: 'relative', boxSizing: 'border-box'}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'16px', width:'16px', border:'2px solid rgb(29, 155, 240)', padding:'2px', borderRadius:'50%'}}>
                                    <div style={{height:'100%', width:'100%', background:'rgb(29, 155, 240)', borderRadius:'50%'}}></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{cursor: 'pointer', paddingBottom: '4px', paddingTop: '4px'}}>
                        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', flexGrow: 1}}>
                            <div style={{color: 'rgb(15, 20, 25)', fontWeight: 400, fontSize: '15px', lineHeight: '20px', wordWrap: 'break-word'}}>
                                <span>Near you</span>
                            </div>

                            <div style={{marginLeft: '20px', height: '100%', userSelect: 'none', position: 'relative', boxSizing: 'border-box'}}>
                                <div style={{height:'19px', width:'19px', border:'2px solid rgb(15, 20, 25', borderRadius:'50%'}}></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

SearchWidgets.propTypes = {
    toggleShareModal: PropTypes.func.isRequired, // Prop: Function to toggle share modal
    auth: PropTypes.object.isRequired, // Prop: Object representing authentication state
}

const mapStateToProps = state => ({
    auth: state.auth, // Connect: Map authentication state from redux store to auth prop
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleShareModal 
})(SearchWidgets);