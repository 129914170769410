import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Components - imported
import { Button } from '@material-ui/core';
import { toggleAuthModal } from '../../actions/navActions';


// Displays if the user is logged in AND has set up profile
const HowItWorks = ({ 
    nav: {
        campus_id,
        campus_name,
        campus_loading
    },
    toggleAuthModal,
    hide_title
}) => {

    // Render the component
    return (
        <div style={{padding: '16px', borderBottom: '1px solid #e6ecf0'}}>
            {!hide_title && (
                <div style={{color:'rgb(15, 20, 25)', marginBottom: '1.5rem', textAlign:'center', fontWeight:'700', fontSize:'24px', lineHeight:'24px', overflowWrap:'break-word'}}>
                    <span style={{fontSize: '1.125rem'}}>Welcome to {campus_name}'s Buy / Sell Community!</span>
                </div>
            )}
            
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn2.iconfinder.com/data/icons/shopping-and-commerce-activities/135/52-512.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Browse Tons of Great Items Nearby.
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        like furniture, clothes, and electronics — and buy from your classmates.
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://images.freeimages.com/365/images/istock/previews/1063/106340965-chat-icon-vector-blue.jpg" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Instantly Message Sellers.
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        securely on the website to negotiate price and set up a time to meet.
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/1913/1913803.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Check out people's profiles.
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        to see who they are, and what they are selling.
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', marginBottom: '1rem'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/401/401185.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Sell something of your own.
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        by simply taking a picture with your phone. Posting takes less than 30 seconds!
                    </h5>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                <div style={{minWidth: '5rem', border: '1px solid #e6ecf0', borderRadius: '.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5rem', height: '5rem'}}>
                    <img style={{objectFit: 'contain', overflow: 'hidden', margin: 'auto', maxWidth: '80%', height: 'auto'}} src="https://cdn-icons-png.flaticon.com/512/1921/1921935.png" />
                </div>
                <div style={{paddingLeft: '1rem', display: 'flex', flexDirection: 'column'}}>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', marginBottom: '0.25rem'}}>
                        Join thousands of students.
                    </h5>
                    <h5 style={{fontWeight: 500, fontSize: '1rem', color: 'rgb(83, 100, 113)'}}>
                        on the largest groupchat for students to buy and sell at {campus_name}.
                    </h5>
                </div>
            </div>

            {/* <div>
                <Button 
                    variant="outlined" 
                    className="sidebar__tweet ghost" 
                    fullWidth
                    onClick={() => toggleAuthModal('post')}
                >
                    Create a post
                </Button>
            </div> */}
        </div>
    )
}

// Prop types for the component
HowItWorks.propTypes = {

    // Prop type validation for function to toggle the Authentication modal
    nav: PropTypes.object.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
    toggleAuthModal 
})(HowItWorks);
