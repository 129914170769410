import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';

// icons - material UI
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Feedback_Settings = ({ 
    handleSlideMenu, 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    const hostname = window.location.hostname;

    useEffect(() => {

         // Add event listener for window resize
         window.addEventListener('resize', () => handleWindowSizeChange());

         // Cleanup the event listener when the component unmounts
         return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);

    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Initialize Mixpanel and track settings Help page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings Feedback Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    /* Back Btn Icon & Badge Value */
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}

                {/* Title */}
                <div onClick={handleSlideMenu} className="settings__headerText">
                    <h3>
                        Give Us Feedback
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="trust-and-safety" style={{paddingTop:'20px'}}>
                    <h5>We Value Your Feedback</h5>
                    <h3>Any suggestions?</h3>
                    <div>
                        <p>We would love to chat! Send us a DM on Instagram.</p>
                        <Button onClick={() => window.open(`https://www.instagram.com/${campus_name.toLowerCase()}buysell/`, "_blank")} variant="outlined" className="sidebar__tweet ghost" fullWidth>DM us @{campus_name}BuySell</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback_Settings;
