export const uf_valid_email_extensions = [
    'ufl.edu',
    'gator.ufl.edu',
    'ufhealth.org',
    'medicine.ufl.edu', 
    'dental.ufl.edu', 
    'pharmacy.ufl.edu', 
    'veterinary.ufl.edu', 
    'phhp.ufl.edu', 
    'hscl.ufl.edu', 
    'eng.ufl.edu', 
    'business.ufl.edu', 
    'law.ufl.edu', 
    'arts.ufl.edu', 
    'cals.ufl.edu', 
    'jou.ufl.edu', 
    'design.ufl.edu',
    'education.ufl.edu',
    'clas.ufl.edu', 
    'ifas.ufl.edu', 
    'explore.research.ufl.edu', 
    'epi.ufl.edu', 
    'brain.ufl.edu', 
    'astro.ufl.edu', 
    'alumni.ufl.edu', 
    'housing.ufl.edu',
    'library.ufl.edu',
    // Add more emails extensions here
];