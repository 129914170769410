import { db, auth, storage } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, orderBy, serverTimestamp, startAt } from 'firebase/firestore';
import { setAlert } from './alertActions';

// Reference to the "notifications" collection in Firestore
const verificationCollectionRef = collection(db, "verifications");
const emailCollectionRef = collection(db, "mail");

const formatFirebaseTimestamp = (date) => {
    var formattedDate = date.toISOString();
  
    // Replace "T" with a space
    formattedDate = formattedDate.replace("T", " ");
  
    // Remove milliseconds
    formattedDate = formattedDate.split(".")[0];
  
    return formattedDate;
}

// Action creator to set an alert
export const addVerificationCode = (campus_name, school_Email) => async dispatch => {

    // Generate a unique 4 digit code
    const randomCode = Math.floor(Math.random() * 9000 + 1000);

    // Reference to the user document in the Firestore database
    const userRef = doc(db, "users", auth.currentUser.uid);

    try {

        // Fetch the user document
        const userDoc = await getDoc(userRef);

        if (!userDoc) return;

        // Create a new verification document
        // Add the new verification document to the verifications collection
        await addDoc(verificationCollectionRef, {
            userId: auth.currentUser.uid,
            code: randomCode,
            date: Date.now(),
            createdAt: serverTimestamp()
        });

        // --- Send email
        const docFields = {};
        
        docFields.date = Date.now();
        docFields.createdAt = serverTimestamp();

        let email_sending_to = userDoc.data().email;
        
        if(school_Email) {
            email_sending_to = school_Email
        }
        
        docFields.to = email_sending_to;
        docFields.message = {
            subject: `${randomCode} is your ${campus_name} Buy / Sell verification code`,
            html: `<div><h1 style="color:#333;font-size:20px">Confirm your email address</h1> <div><div style="color:#333333;margin-top:12px">Please enter this verification code to continue on ${campus_name} Buy / Sell:</div><pre style="padding:16px 24px;border:1px solid #eeeeee;background-color:#f4f4f4;border-radius:3px;font-family:monospace;margin-bottom:24px">${randomCode}</pre></div> <div style="color:#aaaaaa;margin-top:12px">Verification codes expire after two hours.<div style="margin-top:12px">If you didn't try to login, you can safely ignore this email.</div></div> <div style="font-size:12px;margin-top:32px;margin-bottom:42px;color:#888"><div><a href="https://www.oubuysell.com" style="color:inherit" target="_blank"><span class="il">${campus_name} Buy / Sell</span>.com</a>, the simplest way to Buy / Sell at ${campus_name}.</div></div></div>`,
        };

        // Add the new email document to the mail collection
        await addDoc(emailCollectionRef, docFields);

        console.log('SENT VERIFICATION EMAIL');

        dispatch(setAlert('Email sent', 'okay'));
    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }
}

// Remove the verification document from firestore DB
export const removeVerificationCode = (id) => async dispatch => {

    // TODO: ADD CLOUD FUNCTION TO DELETE VERIFICATION DOC AFTER 2 HOURS
    
    // Create a reference to the chat document using the provided id
    const verificationRef = doc(db, 'verifications', id)
    
    try {

        // Delete the verification document
        await deleteDoc(verificationRef);  

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }

}

// Action creator to set an alert
export const checkVerificationCode = (verifyCode, school_email, campus_id) => async dispatch => {
    
    console.log('CHECKING VERIFICATION CODE');

    // Reference to the user document in the Firestore database
    const userRef = doc(db, "users", auth.currentUser.uid);
    
    try {

        // --- FETCH the users verification document ----

        // Get the current date and time
        let currentDate = new Date();
        
        // Subtract two hours
        currentDate.setHours(currentDate.getHours() - 2);
        
        // Format the date as a Firebase server timestamp
        const formattedDate = formatFirebaseTimestamp(currentDate);

        const verificationQuery = query(verificationCollectionRef, where("userId", "==", auth.currentUser.uid), where("code", "==", Number(verifyCode)), orderBy('date', 'desc'), startAt(formattedDate));

        // Retrieve the verifications that match the query
        const verifySnapshot = await getDocs(verificationQuery);

        // Map the retrieved chats to an array of chat objects with additional "_id" field
        const verifications = verifySnapshot.docs.map((doc) => ({...doc.data(), _id: doc.id}));

        // Check if the randomly generated code field is equal to the user entered verifyCode
        // If so update the fetch and update the user doc, delete the verification document
        // and redirect to home 
        if(verifications[0]?.code === Number(verifyCode)) {

            if(school_email) {
                // Update the userDoc to verify school email
                if(campus_id) {
                    await updateDoc(userRef, {
                        school_verified: true,
                        school_email,
                        campus_id,
                        lastModified: Date.now()
                    })
                } else {
                    await updateDoc(userRef, {
                        school_verified: true,
                        school_email,
                        lastModified: Date.now()
                    })
                }
            } else {
                // Update the userDoc to verify school email
                await updateDoc(userRef, {
                    verified: true,
                    lastModified: Date.now()
                })
            }

            

            dispatch(removeVerificationCode(verifications[0]._id));

            console.log('SUCCESSFULLY VERIFIED');

            if(school_email && campus_id && campus_id !== 'sWlhtpjZ3UFPMwFF4vJX') { 
               // When verifiying school email: Store the campus ID in local storage so next page knows which campus domain to redirect to
                localStorage.setItem("redirect_to", campus_id);
            }

            if(campus_id === 'sWlhtpjZ3UFPMwFF4vJX') {
                window.location.href = '/search_schools';
            } else {
                window.location.href = '/home';
            }
            

            // Fetch the user document
            // const userDoc = await getDoc(userRef);

            // if(!(userDoc.data()?.school_verified)) {
            //     window.location.href = "/settings/account/school_email";
            // } else {

            //     if(school_email) {
            //         window.location.href = '/settings/account';
            //     } else {
            //         window.location.href = '/home';
            //     }
            // }
            
        } else {
            dispatch(setAlert('Your code was incorrect. Please try again.', 'danger'));
        }

    } catch (err) {
        console.log('ERROR');
        console.log(err);

        // Dispatch an alert to notify that something went wrong
        dispatch(setAlert('Something went wrong', 'danger'));
    }
    
}