import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../components/layout/Layout';

// Components - imported
import Post from '../../components/Post/Post';

const Mentions = () => {
    return (
        <Layout page="mentions" notificationsPage>
            <Post 
                displayName="Tunde Adepitan"
                username="foxytunde" 
                verified={true}
                text="People that say “I’ve always got anybody I’ve ever wanted” shoot they shot on somebody twice a year and think they doing something. Get them field goal attempts up then we can talk"
                avatar="https://pbs.twimg.com/profile_images/1257120322899398659/Jxe3zf98_x96.jpg"
            />
            <Post 
                displayName="Tunde Adepitan"
                username="foxytunde" 
                verified={true}
                text="YOOO its working"
                avatar="https://pbs.twimg.com/profile_images/1257120322899398659/Jxe3zf98_x96.jpg"
            />
            <Post 
                displayName="Tunde Adepitan"
                username="foxytunde" 
                verified={true}
                text="YOOO its working"
                avatar="https://pbs.twimg.com/profile_images/1257120322899398659/Jxe3zf98_x96.jpg"
            />
            <Post 
                displayName="Tunde Adepitan"
                username="foxytunde" 
                verified={true}
                text="YOOO its working"
                image="https://media3.giphy.com/media/65ATdpi3clAdjomZ39/giphy.gif"
                avatar="https://pbs.twimg.com/profile_images/1257120322899398659/Jxe3zf98_x96.jpg"
            />
        </Layout>
    )
}

export default Mentions;
