import React, { Children } from 'react';

import Modal from '../modal/Modal';

const ModalPage = ({ children }) => {
    return (
        <div className="modal__page">

            {/* Modal page container */}
            <div className="modal__container">

                {/* Render the Modal component */}
                <Modal show>

                    {/* Render the children */}
                    {children}
                </Modal>
            </div>
        </div>
    )
}

export default ModalPage
