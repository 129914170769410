import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { Link } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Actions
import { setAlert } from '../../actions/alertActions';

// Light Box
import { SRLWrapper } from "simple-react-lightbox";

// Components - material UI
import { Avatar, Button } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';

// Icons - material UI
import DateRangeIcon from '@material-ui/icons/DateRange';
import { toggleProfileModal, toggleAuthModal } from '../../actions/navActions';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Verification_Badge from '../common/Verification_Badge';


const ProfileHeader = ({ 
    setAlert,
    toggleProfileModal, 
    toggleAuthModal,
    toggleMessageModal, 
    toggleEdit, 
    auth: { 
        modalUser, 
        user 
    },
    nav: {
        campus_url
    }
}) => {

    // Toggle Dropdown
    const [dropdown, setDropdown] = useState(false);
    const [menuHeight, setMenuHeight] = useState(null);

    // Edit Modal toggles
    const [copied, setCopied] = useState(false);
    
    // Get the current URL and extract the "show" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const filter = url.searchParams.get("show");

    // Copied Modal timer logic
    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    }, [copied]);

    // Handle Toggling of Message Modal
    const handleMessageModal = () => {
        toggleMessageModal();
    }

    // Handle logic for the Copied Modal
    const toggleCopiedModal = () => {
        setDropdown(false);
        
        // setCopiedModal(!copiedModal);
        setAlert('Copied to clipboard', `${user ? 'okay' : 'success'}`);
        
    }

    // Handle logic for Reporting User
    const reportUser = () => {
        setDropdown(false);
        
        // Show 'User Reported' Alert
        setAlert('User Reported. Thanks for letting us know.', `${user ? 'okay' : 'success'}`);
    }

    // Handle Claim Account logic
    const handleClaimAcct = () => {
        setDropdown(false);

        // Open claim account link in a new window
        window.open(`/login/sent?email=${modalUser && modalUser.email}`)
    }

    // Function to capitalize the first letter of a string
    const capitalizeFirstLetter =(string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Logging modalUser's date value if it exists
    if (modalUser && modalUser.verified) {
        console.log('MODAL DATE HERE');
        console.log(fromUnixTime(modalUser.date))
    }

    // Options for a component (possibly related to a slideshow or gallery)
    const options = {
        caption: {
            showCaption: false
          },
        thumbnails: {
            showThumbnails: false
        },
        buttons: {
            showAutoplayButton: false,
            showFullscreenButton: false,
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false,
        }
    }
    
    return (

        /* SRLWrapper is a lightbox for full screen image display */
        <SRLWrapper options={options}>
            <div className="profile__header">

                {/* Dropdown Button */}
                <div onClick={() => setDropdown(!dropdown)} className="profile__moreBtn">
                    <MoreHorizIcon />
                </div>

                {/* Dropdown Menu */}
                <div className={dropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                    <div className="menu">

                        {/* Copy Link to Profile option */}
                        <CopyToClipboard 
                            text={`${campus_url}/${modalUser && modalUser.username}?redirect=true`}
                            onCopy={toggleCopiedModal}
                        >
                            <div className="menu-item">
                                <LinkIcon />
                                Copy link to profile
                            </div>
                        </CopyToClipboard>

                        {/* Claim Account option */}
                        {modalUser && !modalUser.verified ? (
                            <div onClick={handleClaimAcct} className="menu-item blue">
                            {/* <PersonAddAltOutlinedIcon /> */}
                            Claim this account 
                        </div>
                        ) : null}
                        
                        {/* Report User option */}
                        {user && modalUser && modalUser._id !== user._id ? (
                            <div onClick={reportUser} className="menu-item danger">
                                {/* <OutlinedFlagTwoToneIcon /> */}
                                Report 
                                <b style={{marginLeft:'5px'}}>{modalUser && `${modalUser.first_name }${modalUser.last_name && ' ' + modalUser.last_name}`}</b>
                            </div>
                        ) : null}
                    </div>
                </div>

                {/* Overlay for the Dropdown */}
                <div
                    className={`hidden-overlay ${dropdown ? "show" : ""}`}
                    onClick={() => setDropdown(!dropdown)}
                />

                {/* Profile Image */}
                <div className="profile__image">
                    <a href={modalUser && modalUser.img}>
                        {modalUser && modalUser.img ? (
                            <Avatar src={modalUser.img} />
                        ) : (
                            <DefaultAvatar name={`${modalUser.first_name} ${modalUser.last_name}`} />
                        )}
                    </a>
                </div>

                {/* Profile User Name */}
                <div className="profile__headerBody">
                    <h3>
                        {modalUser && modalUser.first_name}{modalUser && modalUser.last_name ? ` ${modalUser.last_name}` : ''}
                        <span className="profile__headerSpecial">
                            {/*@{modalUser && modalUser.username}*/}
                            <Verification_Badge user={modalUser} />
                        </span>
                    </h3>
                </div>

                {/* Profile Stats */}
                {/* <div className="profile__stats">
                    <p><span className="profile__specialStat">451</span> Bought</p>
                    <p><span className="profile__specialStat">329</span> Sold</p>
                    <p><span className="profile__specialStat">1,015</span> Likes</p>
                </div> */}

                {/* Profile Bio */}
                <div className="profile__description">
                    <p>{modalUser && modalUser.bio?.slice(0, 160)}</p>
                </div>
                
                {/* Profile Membership */}
                {modalUser?.verified && (
                    <div className="profile__membership">
                        <DateRangeIcon />
                        {modalUser?.date && <p>Joined {format(modalUser.date, "MMMM yyyy")} </p>}
                    </div>
                )}

                {/* Edit Profile or Send Message Button */}
                {user && modalUser && modalUser._id === user._id ? (
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet ghost" 
                        fullWidth
                        onClick={user.profile_set_up ? toggleEdit : toggleProfileModal}
                    >
                        {user.profile_set_up ? 'Edit Profile' : 'Set up profile'}
                    </Button>
                ) : (
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet ghost" 
                        fullWidth
                        onClick={handleMessageModal}
                    >
                        Send Message
                    </Button>
                )}

                {/* Profile Header Tabs */}
                <div className="profile__headerTabs">
                    
                    <a href={`/${modalUser ? modalUser.username : 'profile'}`}>
                        <div className={`${(filter === null || filter === undefined || filter === 'home') && 'active'}`}>
                            <h3>Posts</h3>
                            <div className="block__underline"></div>
                        </div>
                    </a>

                    <a href={`/${modalUser ? modalUser.username : 'profile'}?show=photos`}>
                        <div className={`${(filter && filter === 'photos') && 'active'}`}>
                            <h3>Photos</h3>
                            <div className="block__underline"></div>
                        </div>
                    </a>
    
                    <a href={`/${modalUser ? modalUser.username : 'profile'}?show=replies`}>
                        <div className={`${(filter && filter === 'replies') && 'active'}`}>
                            <h3>Replies</h3>
                            <div className="block__underline"></div>
                        </div>
                    </a>
                    
                    <a href={`/${modalUser ? modalUser.username : 'profile'}?show=likes`}>
                        <div className={`${(filter && filter === 'likes') && 'active'}`}>
                            <h3>Likes</h3>
                            <div className="block__underline"></div>
                        </div>
                    </a> 
                    {/* <Link to="/notifications/mentions">
                        <div>
                            <h3>Likes</h3>
                            <div className="block__underline"></div>
                        </div>
                    </Link> */}
                </div>
            </div>
        </SRLWrapper>
    )
}

ProfileHeader.propTypes = {
    // Prop type validation for authentication
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    // Prop type validation for function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    // Prop type validation for function to toggle the auth modal
    toggleAuthModal: PropTypes.func.isRequired,
    // Prop type validation for function to set alerts
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

    // Mapping the authentication state
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setAlert, 
    toggleProfileModal, 
    toggleAuthModal 
})(ProfileHeader);
