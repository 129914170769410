export const stanford_valid_email_extensions = [
    'stanford.edu',
    'stanfordhealthcare.org',
    'stanfordchildrens.org',
    'med.stanford.edu',
    'stanfordhospital.org',
    'lpch.org',
    'law.stanford.edu',
    'gsb.stanford.edu',
    'earth.stanford.edu',
    'engineering.stanford.edu',
    'education.stanford.edu',
    'slac.stanford.edu',
    'hoover.stanford.edu',
    'h-star.stanford.edu',
    'fsi.stanford.edu',
    'chem.stanford.edu',
    'physics.stanford.edu',
    'bioengineering.stanford.edu',
    'alumni.stanford.edu',
    'cardinal.stanford.edu',
    'stanfordathletics.org',
    'library.stanford.edu',
    'cs.stanford.edu'
    // Add more emails extensions here
];