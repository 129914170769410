import React from 'react'
import PropTypes from 'prop-types'

const Btn_For_Headers = ({
    children,
    handleClick
}) => {
  return (
    <div onClick={handleClick} className="post__avatar layoutHeader addIcon" style={{ padding: '7px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px', marginLeft: '0px', marginTop:'-3px', marginBottom: '0px'}}>
        {children}
    </div>
  )
}

Btn_For_Headers.propTypes = {}

export default Btn_For_Headers