import { 
    SET_SIDEBAR, 
    REGISTER_SUCCESS, 
    REGISTER_FAIL, 
    USER_LOADED, 
    PROFILE_UPDATE_LOADING,
    PROFILE_UPDATE_LOADED,
    ACCOUNT_LOADING,
    ACCOUNT_LOADING_DONE,
    GET_USER,
    GET_USERS,
    SET_USERS,
    UPDATE_AUTH,
    UPDATE_USER_IMG, 
    AUTH_ERROR, 
    USER_ERROR, 
    LOGIN_SUCCESS, 
    LOGIN_FAIL, 
    LOGOUT,
    CLEAR_USER,
} from '../actions/types';

const initialState = {
    user_id: localStorage.getItem('user_id'), // Retrieve the user ID from local storage
    isAuthenticated: null, // Authentication status
    loading: true, // Loading status
    profile_update_loading: false, // Updating Profile Loading status
    account_loading: false, // Creating Account Loading status
    user: null, // User data
    users: [], // List of users
    modalUser: null, // User data for the modal
    sidebar: 'nav', // Sidebar type
    delivery: false, // Delivery option
    pickup: false, // Pickup option
    block:'', // User's block data
    date:'', // User's date data
    postal_code:'', // User's postal code data
    error: {} // Error object
}

// Reducer function to manage authentication and user-related state
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case USER_LOADED:

            // Update state with user data when user is loaded
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: payload,
                user_id: payload._id ? payload._id : null
            }
        case PROFILE_UPDATE_LOADING:

            return {
                ...state,
                profile_update_loading: true,
            }
        case PROFILE_UPDATE_LOADED:

            return {
                ...state,
                profile_update_loading: false,
            }
        case ACCOUNT_LOADING:

            return {
                ...state,
                account_loading: true,
            }
        case ACCOUNT_LOADING_DONE:

            return {
                ...state,
                account_loading: false,
            }
        case GET_USER: 

            // Update state with modal user data
            return {
                ...state,
                modalUser: action.payload,
                loading: false
            };
        case GET_USERS:

            // Update state with list of users
            return {
                ...state,
                users: payload,
                loading: false
            };
        case SET_USERS: {

            // Append new users to the existing user list
            const users = action.payload;
            let tempUsers = users; 
            
            if(state.users.length > 0) {
                tempUsers= [...state.users, ...tempUsers ];
            }

            return {
                ...state,
                users: tempUsers,
                loading: false
            };
        }
        case UPDATE_USER_IMG:

            // Update state with user data
            return {
                ...state,
                user: {
                    ...state.user,
                    img: payload
                }
            }
        case UPDATE_AUTH:

            // Update state with user data
            return {
                ...state,
                user: payload
            }
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            
            // Update state with authentication and user data on successful registration/login
            return {
                ...state,
                ...payload,
                isAuthenticated: true,
                loading: false
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:

            // Clear user data and set authentication status to false on registration/login failure or logout
            localStorage.removeItem('user_id');
            return {
                ...state,
                user_id: null,
                isAuthenticated: false,
                user: null,
                loading: false
            }
        case SET_SIDEBAR:

            // Update state with sidebar type
            return {
                ...state,
                sidebar: payload
            }
        case CLEAR_USER:

            // Clear modal user data
            return {
                ...state,
                modalUser: null,
                loading: false
            }
        case USER_ERROR:

            // Update state with user-related error
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}