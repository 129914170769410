import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mixpanel from 'mixpanel-browser';

// Actions
import { logout } from '../../actions/authActions';

// Layout Wrapper
import ModalPage from '../../components/layout/ModalPage';

// Components - imported
import { Button } from '@material-ui/core';


const Logout = ({
    auth: { 
        isAuthenticated,
        loading
    },
    logout,
    history
}) => {

    // State for tracking if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const hostname = window.location.hostname;

    // Cancel Logout and redirect to home page
    const cancelLink = () => {
        // history.push('/home')
        window.location.href = "/home";
    }

    // Redirect if logged in
    // if(!loading && !isAuthenticated) {
    //     history.push("/");
    // }

    // Function to handle Mixpanel event tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Logout Page View");
    }

    // Send Mixpanel event only in production and if not already sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (

        // Empty page that looks like it has a modal
        <ModalPage>

            {/* Tilte */}
            <div>
                <h2>Log Out</h2>
            </div>

            {/* Description */}
            <p>Are you sure you want to logout?</p>

            {/* Actions */}
            <Button 
                onClick={logout}
                variant="outlined" 
                className="sidebar__tweet black"
                fullWidth
            >
                Log out
            </Button>
            <Button 
                onClick={cancelLink}
                variant="outlined" 
                className="sidebar__tweet ghost blackText"
                fullWidth
            >
                Cancel
            </Button>
        </ModalPage>
    )
}

Logout.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    logout 
})(Logout);
