import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types'

import { db } from '../../../../config/firebase-config';
import { collection, getDocs, doc, query, onSnapshot} from 'firebase/firestore';

// Redux
import { connect } from 'react-redux';

// Libraries - imported
import moment from 'moment';
import ReactEmoji from 'react-emoji';
// Light Box
import { SRLWrapper } from "simple-react-lightbox";

// Actions 
import { addLike } from '../../../../actions/chatActions';

// Components - imported
import { Avatar } from '@material-ui/core';
import DefaultAvatar from 'react-avatar';
import Spinner from '../../../common/Spinner';

// Icons - imported
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import New_ImageChat from './New_ImageChat';
import New_ImageBox from '../../../common/New_ImageBox';

const Message = ({ 
    addLike,
    auth, 
    chat: {
        chat
    },
    chatData,
    message: { 
        _id,
        user_name, 
        user, 
        text, 
        date,
        likes,
        hasPhoto
    }, 
    name, 
    chatAvatar,
    otherChatUserId,
    scrollToBottom
}) => {

    const [checkedUser, setCheckedUser] = useState(false);
    const [lastMessageID, setLastMessageID] = useState(null);
    const [lastMessage, setLastMessage] = useState(null);
    const [notRead, setNotRead] = useState(true);

    // Tracks the like status of post
    const [liked, setLiked] = useState(false);
    // Flag for checking if like is properly updated
    const [checkLike, setCheckLike] = useState(false);

    // --- Convert DraftJS text to HTML text

    // Tracks whether the text has been converted
    const [gotText, setGotText] = useState(false);
    // Stores the converted text
    const [textState, setTextState] = useState(null);

    // --- Image states

    const [messageImgs, setMessageImgs] = useState(null);
    const [gotMessageImgs, setGotMessageImgs] = useState(false);
    // Check if post has image - for font size reasons
    const [hasImg, setHasImg] = useState(false);
    // Flag for checking if hasImg is properly updated
    const [checkHasImg, setCheckHasImg] = useState(false);

    // Firebase collection reference for messages
    const messagesCollectionRef = collection(db, "messages");

    const messageRef = doc(db, 'messages', _id)
    const msgImgGalleryRef = collection(messageRef, "img_gallery")

    useEffect(() => {

        if(chat) {
            if(chat.last_message_id) setLastMessageID(chat.last_message_id)
            setLastMessage(chat.last_message);

            setNotRead(true);

            if(Array.isArray(chat.read)) {
                if(!chat.read.includes(otherChatUserId)) {
                    setNotRead(false);
                }
            } else {
                if(Array.isArray(chatData?.read) && !chatData?.read.includes(otherChatUserId)) {
                    setNotRead(false);
                }
            }
        }

        

    }, [chat])

    // Trigger if message HAS imgs - for img display purposes
    useEffect(() => {
        handleHasImgState();
    }, [messageImgs]);

    useEffect(
        () => 
            onSnapshot(query(msgImgGalleryRef), (snapshot) => {
                getMessageImgs();
            }
        ), []
    )
    
    useEffect(() => {

        // Scroll to the bottom of messages when a new message is added
        scrollToBottom();

    }, [hasImg])

    // Handle post like action
    const handleLike = (_id) => {
        if(auth.user && _id && chat._id) {
            addLike(_id, auth.user, likes, chat._id, otherChatUserId);

            // Toggles the like status
            setLiked(!liked);
        }
    }

    // Checks if the user has already liked the message
    if(auth.user && !checkLike) {
        if(likes?.length > 0){
            setLiked(true);
        }
        
        // Marks that the check for likes has been performed
        setCheckLike(true);
    }  


    // Initial state set-up for checking if the message is sent by the current user
    let isSentByCurrentUser = false;

    // Trim and convert the name and user_name to lowercase for comparison
    const trimmedName = name.trim().toLowerCase();
    const trimmedUserName = user_name.trim().toLowerCase();

    // Set 'true' if the user_name matches the current user's name
    if(user === auth.user._id) {
        isSentByCurrentUser = true;
    }

    // Converts the DraftJS text to HTML and sets the converted text
    if (text?.includes('{"blocks":[{') && !gotText) {
        console.log('TEXT BLOCK')

        let unRawState;

        if(text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(text))
            );
        
            console.log(unRawState)

            console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setTextState(unRawDescription);
        }

        // Marks that the text conversion has been performed
        setGotText(true)
    }

    // --- Image functions

    // Function to get message images
    async function getMessageImgs() {
        const docRef = doc(db, 'messages', _id)
        const colRef = collection(docRef, "img_gallery")
        // console.log('FETCHING IMGS')
        try {
            // console.log('GALLERY DATA')
            const galleryData = await getDocs(colRef);
            const galleryList = galleryData.docs.map((doc) => ({...doc.data(), id: doc.id}));
            // console.log(galleryList);
            setMessageImgs(galleryList)
            setGotMessageImgs(true);
        } catch (err) {
            console.log('ERROR:');
            console.log(err);
        }
    }

    // For font size reasons
    const handleHasImgState = () => {
        if(messageImgs && messageImgs.length > 0 && checkHasImg != null && !checkHasImg) {
            setHasImg(true)
            setCheckHasImg(true);
            console.log('CHECK HAS IMAGE')
            console.log(messageImgs)
        }
    }

    // Check if message image's are not fetched yet and if the message obj exists
    if(!gotMessageImgs && _id) {

        // Fetch message images
        getMessageImgs()

        // Don't fetch imgs again after this
        setGotMessageImgs(true);
    }

    const optionsNoThumbnail = {
        caption: {
            showCaption: false 
        },
        thumbnails: {
            showThumbnails: false 
        },
        buttons: {
            showAutoplayButton: false, 
            showFullscreenButton: false, 
            showNextButton: false,
            showPrevButton: false,
            showThumbnailsButton: false, 
        }
    }

    // Read status comparison
    let showReadReceipt = false;

    if(lastMessageID) {
        if(lastMessageID === _id) {
            showReadReceipt = true
        }
    } else if(lastMessage === text) {
        showReadReceipt = true;
    }

    return (

        // Render message if sent by the current user
        isSentByCurrentUser ? (
            <SRLWrapper options={optionsNoThumbnail}>
            <div className="messageContainer currentUser">

                {/* Render the actual message && timestamp */}
                <div className="messageBox__container">

                    {(hasPhoto || hasImg) && (
                        <Fragment>
                            {hasImg ? (
                                <Fragment>
                                    {(messageImgs[0].img_name?.includes('mov') || messageImgs[0].img_name?.includes('mp4') ? 
                                        <New_ImageChat 
                                            img_obj={{
                                                img_name: messageImgs[0].img_name,
                                                img_path: messageImgs[0].img_path,
                                                width: messageImgs[0].img_width,
                                                height: messageImgs[0].img_height
                                            }}
                                            isChatMsg={true}
                                        /> : 
                                        <New_ImageBox 
                                            img_obj={{
                                                img_name: messageImgs[0].img_name,
                                                img_path: messageImgs[0].img_path,
                                                width: messageImgs[0].img_width,
                                                height: messageImgs[0].img_height
                                            }}
                                        />
                                    )}
                                </Fragment>
                                
                            ) : <Spinner /> }
                        </Fragment>
                    )}
                    

                    {/* Msg*/}
                    {text && (
                        <div className="messageBox">
                            {textState !== null ? (<p dangerouslySetInnerHTML={{__html: textState}} />) : <p>{ReactEmoji.emojify(text)}</p>}
                            {/* <p>{ReactEmoji.emojify(text)}</p> */}
                        </div>
                    )}

                    {liked && (
                        <div className='messageBox__active currentUser'>
                            <FavoriteIcon fontSize="small" />
                        </div>
                    )}

                    {/* Msg timestamp */}
                    <div className="messageBox__specialText">
                        <p>{moment(date).format('dddd')}{' '}{moment(date).format("h:mm a")}</p>

                        {showReadReceipt && (
                            (notRead) ? (
                                <p style={{marginLeft: '5px'}}>&#8226; <span style={{fontWeight:700}}>Delivered</span></p>
                            ) : (
                                <p style={{marginLeft: '5px'}}>&#8226; <span style={{fontWeight:700}}>Seen</span></p>
                            )
                        )}
                    </div>
                </div>

                {/* Render the current user's avatar */}
                {/* <Avatar src={`/api/users/image/${avatar && avatar}`} /> */}
            </div>
            </SRLWrapper>
        ) : (
            <SRLWrapper options={optionsNoThumbnail}>
            {/* Render message if received from another user */}
            <div className="messageContainer">

                {/* Render the other user's avatar */}
                {/* {chatAvatar ? (
                    <Avatar src={chatAvatar && chatAvatar} />
                ) : (
                    <DefaultAvatar name={user_name} />
                )} */}

                {/* Render the actual message && timestamp */}
                <div className="messageBox__container">

                    {(hasPhoto || hasImg) && (
                        <Fragment>
                            {hasImg ? (
                                <Fragment>
                                    {(messageImgs[0].img_name?.includes('mov') || messageImgs[0].img_name?.includes('mp4') ? 
                                        <New_ImageChat 
                                            img_obj={{
                                                img_name: messageImgs[0].img_name,
                                                img_path: messageImgs[0].img_path,
                                                width: messageImgs[0].img_width,
                                                height: messageImgs[0].img_height
                                            }}
                                            isChatMsg={true}
                                        /> : 
                                        <New_ImageBox 
                                            img_obj={{
                                                img_name: messageImgs[0].img_name,
                                                img_path: messageImgs[0].img_path,
                                                width: messageImgs[0].img_width,
                                                height: messageImgs[0].img_height
                                            }}
                                        />
                                    )}
                                </Fragment>
                        ) : <Spinner /> }
                        </Fragment>
                    )}

                    {/* Msg*/}
                    {text && (
                        <div className="messageBox">
                            {textState !== null ? (<p dangerouslySetInnerHTML={{__html: textState}} />) : <p>{ReactEmoji.emojify(text)}</p>}
                        </div>
                    )}

                    {liked && (
                        <div onClick={() => handleLike(_id)} className='messageBox__active'>
                            <FavoriteIcon fontSize="small" />
                        </div>
                    )}
                    
                    {/* Msg timestamp */}
                    <div className="messageBox__specialText">
                        <p>{moment(date).format('dddd')}{' '}{moment(date).format("h:mm a")}</p>
                    </div>
                </div>

                {!liked && (
                    <div onClick={() => handleLike(_id)} className='messageBox__actions'>
                        <FavoriteIcon fontSize="small" />
                    </div>
                )}
            </div>
            </SRLWrapper>
        )
    )
}

Message.propTypes = {
    auth: PropTypes.object.isRequired,
    chat: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat
});

export default connect(mapStateToProps, {
    addLike
})(Message);