import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { format, parseISO, fromUnixTime, formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

// Redux
import { connect } from 'react-redux';

// Date format
import Moment from 'react-moment';

// Actions
import { addLike, deleteComment } from '../../actions/postActions';

// components - imported
import { Avatar, Button } from '@material-ui/core';
import ImageBlock from '../common/ImageBlock';
import DefaultAvatar from 'react-avatar';
import Verification_Badge from '../common/Verification_Badge';

// Icons - imported
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import RepeatIcon from '@material-ui/icons/Repeat';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import CheckIcon from '@material-ui/icons/Check';

// DraftJS
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

// Modal
import Modal from '../modal/Modal';
import ModalContainer from '../modal/ModalContainer';

const Comment = ({ 
    comment, 
    postId, 
    auth, 
    addLike,
    img,
    deleteComment,
    nav: {
        campus_url
    }
}) => {

    // Tracks the like status of comment
    const [liked, setLiked] = useState(false);
    // Flag for checking if like is properly updated
    const [checkLike, setCheckLike] = useState(false);

    // --- Convert DraftJS text to HTML text

    // Tracks whether the text has been converted
    const [gotText, setGotText] = useState(false);
    // Stores the converted text
    const [textState, setTextState] = useState(null);

    // Toggle Dropdwon
    const [shareDropdown, setShareDropdown] = useState(false);

    // Stores the height of a toggle menu
    const [menuHeight, setMenuHeight] = useState(null);

    // Edit Modal toggles
    const [deleteModal, setDeleteModal] = useState(false);

    // Tracks the status of copying
    const [copied, setCopied] = useState(false);

    // Hide comment after delete
    const [hideThis, setHideThis] = useState(false);

    // Resets the share dropdown and copied status after a delay
    useEffect(() => {
        setTimeout(() => {
            setShareDropdown(false);
            setCopied(false);
        }, 1000);
    }, [copied]);

    // const handleLike = (postId) => {
    //     if(auth.user) {
    //         addLike(postId);
    //         setLiked(!liked);
    //     }
    // }

    // if(auth.user && !checkLike) {
    //     if(likes.filter(like => like.user.toString() === auth.user._id).length > 0){
    //         setLiked(true);
    //     }
        
    //     setCheckLike(true);
    // } 
    
    // Closes the dropdown menu and closes delete modal
    const toggleDeleteModal = () => {
        setShareDropdown(false)

        // Toggles the delete modal
        setDeleteModal(!deleteModal);
    }

    // Deletes the comment
    const handleDelete = () => {
        if(comment) {
            deleteComment(postId, comment._id);
        }

        // Closes the delete modal
        setDeleteModal(false);

        // setHideThis(true);
    }

    // Converts the DraftJS text to HTML and sets the converted text
    if (comment && !gotText) {
        console.log('TEXT BLOCK')

        let unRawState;

        if(comment.text !== null) {
            unRawState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(comment.text))
            );
        
            console.log(unRawState)

            console.log(draftToHtml(convertToRaw(unRawState.getCurrentContent())))
            
            const unRawDescription = draftToHtml(convertToRaw(unRawState.getCurrentContent()));

            setTextState(unRawDescription);
        }

        
        // let editorState = comment.text
        // const rawContentState = editorState.getCurrentContent();
 
        // const markup = draftToHtml(
        //     rawContentState
        // );

        // console.log(markup)

        // Marks that the text conversion has been performed
        setGotText(true)
    }
    
    return (
        <Fragment>
            {/* Render the post component */}
            <div className={!hideThis ? "post" : "post hide"}>

                {/* Avatar - left side 3;7 */}
                <div onClick={() => window.location.href = `/${comment.user.username}`} className="post__avatar postComment">

                    {comment.user.img ? (
                        <Avatar src={comment.user.img && comment.user.img} />
                    ) : (
                        <DefaultAvatar name={`${comment.user.first_name} ${comment.user.last_name}`} />
                    )}
                </div>

                {/* Comment Main Body - right side 3:7 */}
                <div className="post__body">

                    {/* More Button */}
                    <div onClick={() => setShareDropdown(!shareDropdown)} className="post__moreBtn">
                        <MoreHorizIcon />
                    </div>

                    {/* Dropdown Menu */}
                    <div className={shareDropdown ? "edit-dropdown active" : "edit-dropdown"} style={{height: menuHeight}}>
                        <div className="menu">

                            {/* Copy Link Menu Item */}
                            <CopyToClipboard 
                                text={`${campus_url}/post/${postId}`}
                                onCopy={() => setCopied(true)}
                            >
                                <div className="menu-item">
                                    {!copied ? (
                                        <Fragment>
                                            <LinkIcon />
                                            Copy link
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <CheckIcon />
                                            Copied!
                                        </Fragment>
                                    )}
                                </div>
                            </CopyToClipboard>

                            {/* Delete Menu Item (shown only for authenticated user who created the post) */}
                            {auth.user && comment.user._id === auth.user._id ? (
                                <div onClick={toggleDeleteModal} className="menu-item danger">
                                    <DeleteIcon />
                                    Delete
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {/* Hidden Overlay for dropdown */}
                    <div
                        className={`hidden-overlay ${shareDropdown ? "show" : ""}`}
                        onClick={() => setShareDropdown(!shareDropdown)}
                    />

                    {/* Comment Header */}
                    <div className="post__header">
                        <div className="post__headerText">
                            <h3>

                                {/* Comment Author name */}
                                <span className="comment_username">
                                    <a href={`/${comment.user.username}`}>
                                        <span>{comment.username ? comment.username : null} {' '}</span>
                                        <Verification_Badge user={comment.user} />
                                    </a>
                                </span>

                                {/* Comment Timestamp */}
                                <span className="post__headerSpecial">
                                    {formatDistanceToNow(comment.date)} ago
                                </span>
                            </h3>
                        </div>

                        {/* Comment Text */}
                        <div className="post__headerDescription">
                            {/* <p>{comment.text ? comment.text : null}</p> */}

                            {textState !== null && (<div dangerouslySetInnerHTML={{__html: textState}} />)}
                        </div>
                    </div>

                    {/* <ImageBlock onClick={() => history.push(`/post/${comment._id}`)} detailPost={comment} /> */}
                    {/* {comment.img && <img src={comment.img} alt="" />} */}
                    
                    {/* <div className="post__footer">
                        <div>
                            <button 
                                onClick={() => history.push(`/post/${comment._id}`)}
                                className="secondaryBtn post"
                            >
                                <div>
                                    <ChatBubbleOutlineIcon fontSize="small" />
                                    <span>Reply</span>
                                </div>
                            </button>
                        </div>
                        <div className={liked ? "active" : "" } onClick={() => handleLike(comment._id)}>
                            <button className={liked ? "secondaryBtn post active" : "secondaryBtn post" }>
                                <div>
                                    {liked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
                                    <span>{liked ? 'Liked!' : 'Like'} {comment.likes.length > 0 && (
                                        <span style={liked ? {display:'none'}: {fontSize:'11px'}}>({comment.likes.length})</span>
                                    )}</span>
                                </div>
                            </button>
                        </div>
                    
                        
                        <div>
                            <button className="secondaryBtn post">
                                <div>
                                    <ShareIcon fontSize="small" />
                                    <span>Share</span>
                                </div>
                            </button>
                        </div>
                    </div> */}
                </div>

                {/* Comment Overlay to detect when clicked and redirect to commentPage */}
                <div 
                    className="post__overlay" 
                ></div>
            </div>

            {/* Delete Comment Modal */}
            <ModalContainer show={deleteModal} onClose={toggleDeleteModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Delete Comment?</h2>
                    </div>

                    {/* Modal Description */}
                    <p>This can’t be undone and will be removed from this post.</p>

                    {/* Modal Actions */}
                    <Button 
                        onClick={handleDelete}
                        variant="outlined" 
                        className="sidebar__tweet danger"
                        fullWidth
                    >
                        Delete
                    </Button>
                    <Button 
                        onClick={toggleDeleteModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Cancel
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

Comment.propTypes = {
    comment: PropTypes.object.isRequired, // Required prop: Object representing the comment data
    addLike: PropTypes.func.isRequired, // Required prop: Function to add a like
    deleteComment: PropTypes.func.isRequired, // Required prop: Function to delete a comment
    auth: PropTypes.object.isRequired, // Required prop: Object representing the authenticated user
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop
    auth: state.auth,
    nav: state.nav
});

export default connect(mapStateToProps, { 
    
    // Connecting actions from redux to the component
    addLike, 
    deleteComment 
})(Comment);
