import { 
    GET_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    SET_LAST_NOTIFICATIONS,
    GET_ACTIVITY,
    SET_UNREAD_COUNT,
    NOTIFICATION_ERROR,
    CLEAR_NOTIFICATIONS,
    NOTIFICATIONS_LOADING
} from "../actions/types";

const initialState = {
    notifications: [],
    no_more_notifications: false,
    activity: [],
    num_unread: 0,
    read: false,
    loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case NOTIFICATIONS_LOADING:

            // Set the loading state to true
            return {
                ...state,
                loading: true
            };
        case GET_NOTIFICATIONS:

            // Set the notifications state to the payload value,
            // mark all notifications as read, and set loading to false
            return {
                ...state,
                notifications: payload,
                read: true,
                loading: false
            }
        case GET_ACTIVITY:

            // Set the activity state to the payload value and set loading to false
            return {
                ...state,
                activity: payload,
                loading: false
            }
        case SET_NOTIFICATIONS: {

            // Merge the existing notifications with the payload notifications,
            // and update the notifications state accordingly
            const notifications = action.payload;
            let tempNotifications = notifications; 
            
            if(state.notifications.length > 0) {
                tempNotifications= [...state.notifications, ...tempNotifications ];
            }

            return {
                ...state,
                notifications: tempNotifications,
                loading: false
            };
        }
        case SET_LAST_NOTIFICATIONS: {

            // Merge the existing notifications with the payload notifications, and update the notifications state
            const notifications = action.payload;
            let tempNotifications = notifications; 
            
            if(state.notifications.length > 0) {
                tempNotifications= [...state.notifications, ...tempNotifications ];
            }

            return {
                ...state,
                notifications: tempNotifications,
                no_more_notifications: true,
                loading: false
            };
        }
        case SET_UNREAD_COUNT: {

            // Set the num_unread state to the payload value
            return {
                ...state,
                num_unread: action.payload,
            };
        }
        case NOTIFICATION_ERROR:

            // Set the error state to the payload value and set loading to false
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case CLEAR_NOTIFICATIONS:

            // Clear the notifications state and set loading to false
            return {
                ...state,
                notifications: [],
                loading: false,
            }
        default:
            return state;
    }
}