import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Link } from 'react-router-dom';
import Div100vh, { use100vh } from 'react-div-100vh'

// Redux
import { connect } from 'react-redux';

// Actions
import { setUsers } from '../actions/authActions';
import { createChat} from '../actions/chatActions';


// Components - imported
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';

import UserTable from '../components/Tables/UserTable/UserTable';

// Icons - material UI
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

// Initial state for the form
const initialState = {
    username: '',
    phone: '',
    email: '',
    password: '',
    password2: ''
};

const UserSearch = ({ createChat, setUsers, auth, history }) => {

    useEffect(() => {
        handleUserList()
    }, [])

    // Fetches the user list from the API
    const handleUserList = async () => {
        const res = await axios.get(`/api/users`);
        console.log('USER LIST');
        // console.log(res.data)
        setUsers(res.data);
    }

    // Creates a new chat
    const createNewChat = async () => {

        // Check if a chat already exists for the selected user
        const alreadyExists = await axios.get(`/api/chats/check/${auth.modalUser._id}`);
        
        console.log('CHECK THE DATA');
        console.log(alreadyExists.data);

        // Proceed only if a user is selected
        if(auth.modalUser) {

            // If a chat doesn't exist, create a new chat
            if(!alreadyExists.data) {
                createChat(auth.modalUser._id, history);
            } else {

                // If a chat already exists, redirect to the existing chat
                history.push(`/messages/${alreadyExists.data._id}`);
            }
        }
    }

    // Calculates the page height using the use100vh hook
    const pageHeight = use100vh();

    return (
        <div style={{width:'100vw', height: pageHeight, display:'flex', overflow:'hidden', flexDirection:'column'}}>

            {/* Header section */}
            <div style={{display:'flex', justifyContent:'space-between'}}>
                
                {/* Close Button */}
                <button className="secondaryBtn close">
                    <CloseIcon /> 
                    <span>Close</span>
                </button>

                {/* Title */}
                <h2>New Message</h2>

                {/* Submit */}
                <div>
                    <Button onClick={createNewChat} className="tweetBox__tweetButton">Next</Button>
                </div>
            </div>

            {/* Search input */}
            <div className="modal__search" style={{padding:'0 16px'}}>
                <SearchIcon />
                <input placeholder="Search" className="input" type="text" />
            </div>
            
            {/* User table */}
            <div className="modal-table-list-container" >
                <UserTable />
            </div>
        </div>
    )
}

// Prop types for the UserSearch component
UserSearch.propTypes = {
    setPage: PropTypes.func.isRequired,
    setUsers: PropTypes.func.isRequired,
    createChat: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    auth: state.auth,
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setUsers, 
    createChat 
})(UserSearch);
