import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

// Redux
import { connect } from 'react-redux';

import { setAlert } from '../../actions/alertActions';

// Icons = material UI
import CloseIcon from '@material-ui/icons/Close';

const Image_TweetBox = ({index, imgInfo, removeImg, executeScroll, admin, setAlert}) => {

    const handleRemoveImg = async () => {
        setAlert("Removing photo...", 'okay');
        await removeImg(index);
    }

  return (
    <Fragment>
        <div className="tweetBox__img--image">
            <a href={imgInfo[index].img_path}>
                <img loading='lazy' className={imgInfo[index].width > imgInfo[index].height ? "fullHeight" : "fullWidth"} src={imgInfo[index].img_path} alt="img" onLoad={executeScroll && executeScroll} />
            </a>
        </div>
        {admin && (

            // Delete button if user is adding imgs from tweetBox
            <div onClick={handleRemoveImg} className="tweetBox__imgBtn">
                <CloseIcon />
            </div>
        )}
    </Fragment>
  )
}

Image_TweetBox.propTypes = {
    setAlert: PropTypes.func.isRequired, // Required prop: Function to set an alert
}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop

});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
})(Image_TweetBox);