export const ucla_valid_email_extensions = [
    'ucla.edu',
    'mednet.ucla.edu',
    'dentistry.ucla.edu',
    'sonnet.ucla.edu',
    'uclahealth.org',
    'gseis.ucla.edu',
    'arts.ucla.edu',
    'anderson.ucla.edu',
    'law.ucla.edu',
    'luskin.ucla.edu',
    'seas.ucla.edu',
    'international.ucla.edu',
    'arts.ucla.edu',
    'tft.ucla.edu',
    'chavez.ucla.edu',
    'brain.ucla.edu',
    'epi.ucla.edu',
    'cens.ucla.edu',
    'econ.ucla.edu',
    'chem.ucla.edu',
    'alumni.ucla.edu',
    'housing.ucla.edu',
    'library.ucla.edu',
    'g.ucla.edu',
    // Add more emails extensions here
];