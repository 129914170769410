import React, { Fragment, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Blurhash } from 'react-blurhash';

// Redux
import { connect } from 'react-redux';

import { setAlert } from '../../../../actions/alertActions';

// Icons = material UI
import CloseIcon from '@material-ui/icons/Close';

// Reac-Icons for Video Player
import {
    FaPlay,
    PaPause,
    FaStop,
    FaExpand,
    FaCompress,
    FaVolumeUp,
    FaVolumeMute,
    FaPause
} from 'react-icons/fa'

const New_ImageChat = ({
    index,
    img_obj, 
    executeScroll,
    admin,
    removeImg,
    isChatMsg,
    chatInput
}) => {

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   const [imgLoaded, setImgLoaded] = useState(false);

   // Video Player Logic
   const videoRef = useRef(null);
   const intervalRef = useRef(null);
   const [isPlaying, setIsPlaying] = useState(true);
   const [isFullScreen, setIsFullScreen] = useState(false);
   const [progress, setProgress] = useState(0);
   const [volume, setVolume] = useState(1);
   const [isMuted, setIsMuted] = useState(true);
   const [useNativeControls, setUseNativeControls] = useState(
       window.innerWidth < 767
   )
   // END: Video Player Logic

   // Determine window size breakpoints
   const isMobile = windowWidth <= 769;
   const isTablet = windowWidth <= 1000;

    // --- ImageKit (img optimization service) ---
   
    // Replace the firebase URL with ImageKit.io URL-endpoint
    const optimized_img_path = img_obj.img_path.replace("https://firebasestorage.googleapis.com","https://ik.imagekit.io/pba3w2st28/");

   useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImgLoaded(true)
        }
        img.src = optimized_img_path

        if(img_obj?.img_name?.includes('mov') || img_obj?.img_name?.includes('mp4')) {
            setImgLoaded(true)
        }

   }, [img_obj])

   // --- Video Player Functions ---

   useEffect(() => {

        // Set up an event listener for when the video ends
        const video = videoRef.current;

        const handleVideoEnd = () => {
            setIsPlaying(false);
            setProgress(0);
            stopProgressLoop();
        };

        if (video) {
            video.addEventListener('ended', handleVideoEnd);
        }

        // Clean up event listener
        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
            stopProgressLoop();
        }

    }, [])

    // Listen for fullscreen change events (for exiting fullscreen with ESC key)
    document.addEventListener('fullscreenchange', () => {
        setIsFullScreen(!!document.fullscreenElement);
    });

    // This effect cleans up the event listener when the component unmounts
    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);

            if(videoRef) {
                videoRef.current.play();
                setIsPlaying(true);
                startProgressLoop();
            }
            
        }

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        }
    }, [])

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {

        setWindowWidth(window.innerWidth);
    };

    // --- END: Video Player Functions

   const handleRemoveImg = async () => {
        setAlert("Removing photo...", 'okay');
        await removeImg(index);
    }

    // --- Video Player Functions ---

    const updateProgress = () => {
        if (videoRef.current) {
            const value = (videoRef.current.currentTime / videoRef.current.duration) * 100;
            setProgress(value)
        }
    }

    const startProgressLoop = () => {
        // Clear any existing intervals
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        // Set up an interval for updating the progress bar
        intervalRef.current = setInterval(() => {
            updateProgress();
        }, 1000); // Update every second
    }

    const stopProgressLoop = () => {
        // Clear ther interval when the video is paused or stopped
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
                startProgressLoop()
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
                stopProgressLoop();
            }
        }
    }

    const stopVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }

    const handleSeek = (event) => {
        const seekTo = (event.target.value / 100) * videoRef.current.duration;
        videoRef.current.currentTime = seekTo;
        setProgress(event.target.value);
    }

    const toggleMute = () => {
        const currentVolume = videoRef.current.volume;
        console.log('TOGGLE VOLUME')
        console.log(currentVolume)
        if (currentVolume > 0) {
            videoRef.current.volume = 0;
            setVolume(0);
            setIsMuted(true);
        } else {
            videoRef.current.volume = 1;
            setVolume(1);
            setIsMuted(false);
        }
    }

    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        setIsMuted(newVolume === 0);
    }

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if(videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.mozRequestFullscreen) {
                // Firefox
                videoRef.current.mozRequestFullscreen();
            } else if (videoRef.current.webkitRequestFullscreen) {
                // Chrom, Safari & Opera
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) {
                // IE / Edge
                videoRef.current.msRequestFullscreen();
            } 
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                // Chrom, Safari & Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                // IE / Edge
                document.msExitFullscreen();
            }
        }

        setIsFullScreen(!isFullScreen);

        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
            startProgressLoop()
        }
    }

    const handleVideoClick = (e) => {
        if(admin && isTablet) {
            e.preventDefault(); 
            return false;
        } else {
            togglePlayPause();
        }
    }

    const renderCustomControls = () => {
        return (
            <>
                <button onClick={togglePlayPause}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                <button onClick={stopVideo}>
                    <FaStop />
                </button>
                <input
                    type='range'
                    min='0'
                    max='100'
                    value={progress}
                    onChange={handleSeek}
                />
                <button onClick={toggleMute}>
                    {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                </button>
                <input
                    type='range'
                    min='0'
                    max='1'
                    step='0.05'
                    value={volume}
                    onChange={handleVolumeChange}
                />
                <button onClick={toggleFullScreen}>
                    {isFullScreen ? <FaCompress /> : <FaExpand />}
                </button>
            </>
        );
    }

    // --- END: Video Player Functions ----

   const maxHeight = 200;
            
    // Get the original dimensions of the image
    var originalWidth = img_obj.width;
    var originalHeight = img_obj.height;

    // Calculate the scaling factor based on the max height
    var scale = maxHeight / originalHeight;

    // Calculate the new dimensions based on the scaling factor
    var newWidth = originalWidth * scale;
    var newHeight = maxHeight;


  return (
    <div className={chatInput ? 'new_image_chat' : ''} style={isChatMsg ? {
        overflow: 'hidden',
        width: `${newWidth}px`,
        height: `${newHeight}px`,
        borderRadius: '16px',
        background: '#e6ecf0',
        display: 'inline-block',
        border: '1px solid rgb(207, 217, 222)',
        position: 'relative',
        marginTop: '3px',
        marginBottom: '3px',
        marginRight: '10px',
    } : {
        overflow: 'hidden',
        width: `${newWidth}px`,
        height: `${newHeight}px`,
        borderRadius: '16px',
        marginRight: '5px',
        background: '#e6ecf0',
        display: 'inline-block',
        border: '1px solid rgb(207, 217, 222)',
        position: 'relative',
    }}>

            {!imgLoaded && (
                <Blurhash
                    hash="L8I;kmVf#t8^$+RktlpI00t+pc.T"
                    width={newWidth}
                    height={newHeight}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1} />
            )}
            {imgLoaded && (
                <Fragment>
                    {(img_obj.img_name?.includes('mov') || img_obj.img_name?.includes('mp4')) ? (
                        <Fragment>
                            <video 
                                src={optimized_img_path} 
                                poster={optimized_img_path} 
                                ref={videoRef}
                                onClick={handleVideoClick}
                                onPlay={startProgressLoop}
                                onPause={stopProgressLoop}
                                autobuffer={!isTablet}
                                controls={!isTablet}
                                controlsList="nodownload"
                                playsinline
                                muted={!isTablet}
                                autoPlay={!isTablet}
                                loop
                                style={{height:'100%', width: '100%', cursor: 'pointer'}}
                                onLoad={executeScroll && executeScroll}
                            />

                        </Fragment>
                    ) : (
                        <img src={optimized_img_path} alt="img" />
                    )}

                {admin ? (
                    // Delete button if user is adding imgs from tweetBox
                    <div onClick={handleRemoveImg} className="tweetBox__imgBtn">
                        <CloseIcon />
                    </div>
                ) : (
                    <Fragment>

                            {isTablet ? (
                                <div onClick={togglePlayPause} className="tweetBox__imgBtn">
                                    {isPlaying ? <FaPause /> : <FaPlay />}
                                </div>
                            ) : (
                                <div onClick={isPlaying ? toggleFullScreen : togglePlayPause} className="tweetBox__imgBtn">
                                    {isPlaying ? (
                                        <Fragment>
                                            {isFullScreen ? <FaCompress /> : <FaExpand />}
                                        </Fragment>
                                    ) : <FaPlay />}
                                </div>  
                            )}
                    </Fragment>
                )}

                </Fragment>
                
            )}
        
    </div>
  )
}

New_ImageChat.propTypes = {
    setAlert: PropTypes.func.isRequired, // Required prop: Function to set an alert
}

const mapStateToProps = state => ({
    // Mapping the auth state from the Redux store to the 'auth' prop

});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setAlert, 
})(New_ImageChat);