import React, { Fragment, useEffect, useState } from 'react';

import mixpanel from 'mixpanel-browser';

// components - material UI
import { Button } from '@material-ui/core';
import GettingStarted from '../common/GettingStarted';

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const HowItWorks_Settings = ({ 
    auth,
    chat,
    notification,
    getUnreadChats,
    getUnreadCount,
    history,
    campus_name,
    goBack
}) => {

    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Screen Width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Notification Badge
    const [badgeValue, setBadgeValue] = useState(0);

    const hostname = window.location.hostname;

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);


    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        // Fetch unread counts for notifications and chats
        if(auth.user){
            getUnreadCount(auth.user._id);
            getUnreadChats();
        }

    }, [notification.notifications, auth.user]);

    useEffect(() => {

        // Update the badge value to total (Notifications + unread Chats)
        setBadgeValue(notification.num_unread + chat.num_unread_chats);
        
    }, [notification.num_unread, chat.num_unread_chats])

    // Determine mobile and tablet screen sizes
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;


    // Initialize Mixpanel and track settings How page view
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Settings HOW Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <div className="settings">

            {/* Header */}
            <div className="settings__header">
                {isTablet && (

                    /* Back Btn Icon & Badge Value */
                    <div onClick={goBack} className="profile__btnContainer">
                        <ArrowBackIcon />
                        {isMobile && badgeValue > 0 ? (
                            <span className="feed-header-badge defaultBadge">
                                {badgeValue}
                            </span>
                        ) : null}
                    </div>
                )}

                {/* Title */}
                <div className="settings__headerText">
                    <h3>
                        How It Works
                    </h3>
                </div>
            </div>

            {/* Main Content */}
            <div className="settings__body terms">
                <div className="trust-and-safety" style={{paddingTop:'20px'}}>
                    <h5>Follow these steps.</h5>
                    <h4 style={{marginTop:'30px'}}>Step 1: Buy an item</h4>
                    <ol style={{paddingLeft: '20px', margin: '10px 0px 20px 20px'}}>
                        <li style={{marginBottom: '10px'}}>
                            <span>When you find an item you like, you can use the built-in chat option to DM the seller or you can directly comment on their post.</span>
                        </li>
                        <li style={{marginBottom: '10px'}}>
                            <span>Make an offer.</span>
                        </li>
                        <li style={{marginBottom: '10px'}}>
                            <span>Once the seller agrees to your offer, find a safe meet-up spot near the both of you.</span>
                        </li>
                        <li>
                            <span>Inspect the item &#8594; If you are satified &#8594; Pay in person.</span>
                        </li>
                    </ol>
                    <h4 style={{marginTop:'30px'}}>Step 2: Sell your item</h4>
                    <p>
                        <span>Once your item is posted on {campus_name} Buy / Sell, it&rsquo;s time to sell it. Here&rsquo;s how it works:</span>
                    </p>
                    <ol style={{paddingLeft: '20px', marginTop: '20px', marginLeft: '20px'}}>
                        <li style={{listStyleType: 'none'}}>
                            <ol style={{paddingLeft: '20px'}}>
                                <li style={{marginBottom: '10px'}}>
                                    <span>You&apos;ll get messages in your inbox and comments on your post from interested buyers.&nbsp;</span>
                                    {/* <a href="#" style={{backgroundColor: 'transparent', color: 'rgb(0, 0, 0)', textDecoration: 'underline'}}>
                                        <span>Find messages</span>
                                    </a> */}
                                </li>
                                <li style={{marginBottom: '10px'}}>
                                    <span>Reply to these messages to answer any questions, then agree on a price with a buyer.</span>
                                </li>
                                <li style={{marginBottom: '10px'}}>
                                    <span>If you&rsquo;re meeting the buyer in person:</span>
                                    <ul style={{padding: '0px 0px 0px 20px'}}>
                                        <li style={{marginBottom: '10px', marginTop: '5px'}}>
                                            <span>Use the built-in chat to agree on a time / place to meet. If the item is large or heavy, we recommend making plans to lift or move it.&nbsp;</span>
                                        </li>
                                        <li style={{marginBottom: '10px'}}>
                                            <span>When you meet, let the buyer check out the item.&nbsp;</span>
                                        </li>
                                        <li style={{marginBottom: '10px'}}>
                                            <span>If you and the buyer agree to the sale, swap the item and payment in person.</span>
                                            {/* <a href="#" style={{backgroundColor: 'transparent', color: 'rgb(0, 0, 0)', textDecoration: 'underline'}}>
                                                <span>payment in person</span>
                                            </a>
                                            <span>.</span> */}
                                        </li>
                                        <li>
                                            <span>If the sale doesn&apos;t work out, be polite, and don&apos;t be discouraged. There are many more potential buyers on {campus_name} Buy / Sell.</span>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="no-rides" />
        </div>
    )
}

export default HowItWorks_Settings;
