import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

// A class component representing a text editor
export default class TextEditor extends Component {
  constructor(props) {
    super(props);
  }

  state = {

    // Initialize the editor state as empty
    editorState: EditorState.createEmpty(),

    // Flag to track data fetching
    fetchData: false,

    // Flag to track if reset has been checked
    checkedReset: false
  };

  // Lifecycle method called after the component has been rendered
  componentDidMount(){
  
    let unRawState;

    // If the description object is provided, create an EditorState with the parsed content
    if(this.props.descriptionObj !== null) {
       unRawState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.descriptionObj))
      );
      this.setState({editorState: unRawState});
    }
  }

  // Handler for editor state changes
  onEditorStateChange = (editorState) => {
    if(this.props.resetEditor) {
      this.props.setResetEditor(false);
    }

    this.setState({
      editorState,
      checkedReset: false
    });

    this.props.setDescriptionObj(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
  };

  handleBeforeInput = (input) => {
    if (this.props.maxLength) {
      if (this.props.inputCharacterLength >= this.props.maxLength) {
        
        if(this.props.setAlertAction) this.props.setAlertAction();
        return 'handled';
      }
    }
  };

  // Lifecycle method called to render the component
  render() {

    // If the resetEditor prop is true and the reset has not been checked yet
    if(this.props.resetEditor && !this.state.checkedReset) {
      this.setState({
        editorState: EditorState.createEmpty(),
        checkedReset: true
      });
    }

    const { editorState, fetchData } = this.state;

    let newState;

    // If the description object is provided and the fetch has not been done yet
    if(this.props.descriptionObj !== null && !fetchData) {
       newState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.descriptionObj))
      );
      this.setState({editorState: newState, fetchData: true});
    }
    return (
      <div style={{background:'#fff', minHeight:'200px'}}>

        {/* Render the editor */}
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          placeholder={this.props.isChatBox ? "Say something . . ." : (this.props.postId != null ? "Write a comment..." : "What are you selling?")}
          onEditorStateChange={this.onEditorStateChange}
          handleBeforeInput={this.handleBeforeInput}
          editorStyle={{ minHeight:'200px', cursor:'text', paddingLeft:'10px', paddingRight:'10px' }}
          handlePastedText={() => false}
        />

        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        ></textarea> */}
      </div>
    );
  }
}