import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Components - imported
import { Avatar, Button } from '@material-ui/core';
import Alert from '../../common/Alert';
import Logo from '../../common/Logo';

// Icons
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';


const AddPhoto = ({
    slideform1, 
    setSlideForm1,
    user,
    userId,
    changeProfileImg,
    setAlert
}) => {

    // State variable to store the file URL
    const [fileUrl, setFileUrl] = useState('');

    // Function to trigger the file upload button
    const fileUploadButton = () => {
        document.getElementById('fileButton').click();
    }

    // Event handler for file selection
    const fileChanged = e => {

        // Array to store the selected files
        let fileList = [];

        // Iterate through the selected files
        for (var i = 0; i < e.target.files.length; i++) {

            if(!e.target.files[i]) return;

            const fileName = e.target.files[i].name;

            // --- File extension

            const fileExt = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length)
            console.log('File ext: ', fileExt);

            if(!(fileExt.includes('jpeg') || fileExt.toLowerCase().includes('jpg') || fileExt.includes('png') || fileExt.includes('gif'))) {
                setAlert('Only photos are allowed!', 'danger');
                return;
            }

            // --- END File extension
            
            // Add the file to the fileList array
            fileList.push(e.target.files[i])

            let reader = new FileReader()

            // Read the file as data URL
            reader.readAsDataURL(e.target.files[i])
            reader.onload = () => {
                console.log('FILE PATH')

                // Display the file path (data URL)
                console.log(reader.result);

                // Update the file URL state with the new data URL
                setFileUrl(fileUrl => [...fileUrl, reader.result])
            };
            
            // Display if there is an error reading the file
            reader.onerror = function (error) {
                console.log('Error: ', error);
            }
        }

        // Call the changeProfileImg function with the fileList and userId parameters
        changeProfileImg(fileList, userId);

        console.log('USER IMG ID: ');

        // Display the user image ID
        console.log(userId);
    }

    // Toggle the slideform1 state variable (go to next page in slide form)
    const todo = (e) => {
        setSlideForm1(!slideform1);
    }

    return (
        <div className="form-transition-element">

            {/* Render the Logo component */}
            <Logo />

            {/* Display the heading */}
            <div>
                <h2>Pick a profile picture</h2>
            </div>

            {/* Header Description */}
            <div>
                <div style={{color:'rgb(83, 100, 113)', textAlign:'center', fontSize:'14px', fontWeight:'500', marginTop:'5px', lineHeight:'20px', overflowWrap:'break-word'}}>
                    <span>Do you have a favorite selfie? Upload it now.</span>
                </div>
            </div>

            <div className="profile-modal__avatar">

                {/* Render an overlay with an icon to trigger the file upload */}
                <div onClick={fileUploadButton} className="profile__avatar__overlay">
                    <AddAPhotoOutlinedIcon />
                </div>

                {/* Render an Avatar component with the uploaded file URL or the user's existing image */}
                <Avatar 
                    src={fileUrl !== '' ? fileUrl : user.img} 
                />

                {/* Render a hidden input element to handle file selection */}
                <input
                    onChange={fileChanged} 
                    id="fileButton" 
                    type="file" 
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    hidden
                />
            </div>

            {/* Slide Form Actions */}
            {/* If not profile img then give the option to 'Skip' */}
            {/* If there is an img then say 'Next' */}
            <Button 
                onClick={todo}
                variant="outlined" 
                className={`sidebar__tweet ${fileUrl === '' && !user.img ? 'ghost' : ''}`}
                fullWidth
            >
                {fileUrl === '' && !user.img ? 'Skip for now' : 'Next'}
            </Button>

            <div className="auth__footer">
                {/* <a href={`/register`}>Forgot password?</a>
                <a href={`/login`}>Log In</a> */}
            </div>
        </div>
    )
}

AddPhoto.propTypes = {

}

export default AddPhoto
