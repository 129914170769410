import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from '../common/Spinner';

const PrivateRoute = ({ 
    component: Component, 
    auth: { isAuthenticated, loading}, 
    ...rest 
}) => (
    <Route 
        {...rest} 
        render={props => 

            // If the loading flag is true, show a Spinner component while authentication state is being loaded
            loading ? (
                <Spinner />
            ) :

            // If the user is not authenticated and the loading flag is false, redirect to the home page
            !isAuthenticated && !loading ? (
                <Redirect to='/' />
            ) : (

                // If the user is authenticated or the loading flag is true, render the specified component
                <Component {...props} />
            )
        } 
    />
);


PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired, // Object prop representing the authentication data for the user
}

const mapStateToProps = state => ({

    // Mapping the auth state from the Redux store to the 'auth' prop
    auth: state.auth
});

// Connect the SidebarMobile component to the Redux store and export it
export default connect(mapStateToProps)(PrivateRoute);
