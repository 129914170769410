import React from 'react'
import PropTypes from 'prop-types'

// Components - material UI
import { Button } from '@material-ui/core';

// Icons = material UI
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import WeekendOutlinedIcon from '@material-ui/icons/WeekendOutlined';

import CategoryData from '../../utils/categoryData/postCategories';

const Categories = ({ closeModal, chooseCategory, postCategory, categoryClicked }) => {

    return (
        <div className="menu">
            {closeModal && (

                // Render close button if on full categoryPage for mobile
                <div style={{margin: '20px'}} onClick={closeModal} className="form__backBtn right">
                    <CloseOutlinedIcon />
                </div>
            )}

            {/* Header text */}
            <div className="menu-header">
                <div style={{fontWeight:'700', fontSize:'15px', lineHeight:'20px', overflowWrap:'break-word'}}>
                    <span>Boost visibility</span>
                </div>
                <div style={{color: 'rgb(83, 100, 113)', fontSize:'14px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                    <span>Add a category to your post for better search results.</span>
                </div>
            </div>

            {/* Category list */}
            <div className="menu-items-container">
                {CategoryData.map(category => (

                    // Render each category item
                    <div key={category.value} onClick={() => chooseCategory(category)} className={`menu-item ${categoryClicked === category.value && 'active'}`}>
                        <div className="menu-item-img-container">

                            {/* Render the icon for the category */}
                            {category.icon2}
                        </div>
                        <span style={{flexGrow: 1 }}>{category.text}</span>
                        {postCategory && postCategory.value === category.value ? (

                            // Render the checkmark icon if the category is selected
                            <div className="userTable__active">
                                <CheckIcon />
                            </div>
                        ) : null}
                    </div>
                ))}
            </div>
        </div>
    )
}

Categories.propTypes = {

}

export default Categories
