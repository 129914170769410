import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// ALGOLIA SEARCH
import algoliasearch from 'algoliasearch/lite';

// Redux
import { connect } from 'react-redux';

// Actions
import { getRecentSearches, deleteRecentSearch, clearRecentSearch } from '../../actions/searchActions';
import { toggleShareModal } from '../../actions/navActions';

// Components - imported
import { Button, Avatar } from '@material-ui/core';
import Spinner from '../common/Spinner';

// Icons - imported
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import RoomIcon from '@material-ui/icons/Room';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

const Widgets = ({ 
    toggleShareModal, 
    auth,
    search: {
        recent_searches,
        recent_loading
    },
    nav: {
        campus_name
    },
    getRecentSearches,
    deleteRecentSearch, 
    clearRecentSearch,  
}) => {

    // --- Search stuff

    const [inputClicked, setInputClicked] = useState(false);

    const [searchText, setSearchText] = useState('');

    const [searchRecommendations, setSearchRecommendations] = useState([]);

    const [dropdownOptionIndex, setDropdownOptionIndex] = useState(-1);

    const [searchDropdownArray, setSearchDropdownArray] = useState([]);

    useEffect(() => {

        if(auth.user) {
            // Get recent searches
            getRecentSearches(auth.user._id);
        }

    }, [auth.user]);

    // Update the array for counting the search dropdown index for when up/down arrows are clicked
    useEffect(() => {

        if(searchDropdownArray.length === 0 && recent_searches.length > 0) {
            setSearchDropdownArray([...recent_searches])
        } else {
            setSearchDropdownArray([...searchRecommendations, ...recent_searches])
        }

    }, [searchRecommendations, recent_searches]);

    useEffect(() => {

        if(!inputClicked) {
            setDropdownOptionIndex(-1);
        }

    }, [inputClicked]);

    useEffect(() => {
        // 👇 Get the Input element by Id
      const searchInput = document.getElementById('search-input');
      
      if (!searchInput) return;
  
  
      searchInput.addEventListener("focusin", (event) => {
        console.log('FOCUS IN')
        setInputClicked(true);
      });
      
    //   searchInput.addEventListener("focusout", (event) => {
    //     console.log('FOCUS OUT')
    //     setInputClicked(false);
    //   });

      return () => {
        searchInput.removeEventListener("focusin", (event) => {
            console.log('FOCUS IN')
            setInputClicked(true);
        });
        
        // searchInput.removeEventListener("focusout", (event) => {
        //     console.log('FOCUS OUT')
        //     setInputClicked(false);
        // });
      };

    }, []);

    const searchRecommendationClient = algoliasearch('H8YQ3RB3ZI', '8ad74b3884c12ec628eb17e0e9f49935');

    const recommendationIndex = searchRecommendationClient.initIndex('searches');

    // Function to handle input change
    const onChange = e => setSearchText(e.target.value);

    const onDownArrow = () => {

        console.log('SEARCH ARRAY')
        console.log(searchDropdownArray.length)

        console.log('SEARCH INDEX')
        console.log(dropdownOptionIndex + 1)

        console.log('REQ LENGTH INDEX')
        console.log(searchRecommendations.length)


        let nextIndex = dropdownOptionIndex + 1;

        if(nextIndex > searchDropdownArray.length - 1) {
            nextIndex = 0;
        }

        setDropdownOptionIndex(nextIndex);
    }

    const onUpArrow = () => {

        let nextIndex = dropdownOptionIndex - 1;

        if(nextIndex === -1 ) {
            nextIndex = searchDropdownArray.length - 1;
        }

        setDropdownOptionIndex(nextIndex);
    }

    const handleSubmit = async (queryText) => {
        if(dropdownOptionIndex > -1) {
            window.location.href = `/search?q=${searchDropdownArray[dropdownOptionIndex].text ? searchDropdownArray[dropdownOptionIndex].text : searchDropdownArray[dropdownOptionIndex].search_text}`;

            console.log('SEARCH THIS WORD')
            console.log(searchDropdownArray[dropdownOptionIndex]);
        } else {
            if(queryText === '') return;

            window.location.href = `/search?q=${queryText}`;
        }
    }

    const handleRecommendations = async (queryText) => {

        setDropdownOptionIndex(-1); // Reset search dropdown index

        try {

            if(queryText === '') return;

            const results = await recommendationIndex.search(queryText);

            console.log('SEARCH RESULT');
            console.log(results);

            setSearchRecommendations(results.hits.map((doc) => ({...doc, _id: doc.objectID})));

            setSearchDropdownArray([...results.hits.map((doc) => ({...doc, _id: doc.objectID})), ...searchDropdownArray]);

        } catch (error) {
            console.log(error);
            setSearchRecommendations([]);
        }
    }

    let recommendations_list = [];

    if(searchRecommendations.length > 0) {

        // Iterate through the posts array and render each post component   
        for (let i = 0; i < searchRecommendations.length; i++) {
            const recommendation = searchRecommendations[i];
            
            if (recommendation) {
                recommendations_list.push(
                <div key={recommendation._id} onClick={() => window.location = `/search?q=${recommendation.text}`} className={dropdownOptionIndex === i ? "search-dropdown-item active" : "search-dropdown-item"}>
                    <div className='search_dropdown_item_icon'>
                    <SearchIcon />
                    </div>
                    <div className='search_dropdown_item_option'>
                    <div style={{fontWeight:700}}>
                        {recommendation.text}
                    </div>
                    </div>
                </div>
                );
            }
        }
    }

    let recent_list;

    if(auth.isAuthenticated && (recent_searches === null || recent_loading)) {
        recent_list = <Spinner />;
    } else if(recent_searches.length > 0) {

        recent_list = [];

        // Iterate through the recent_searches array and render each search query component
        for (let i = 0; i < recent_searches.length; i++) {
            const recent_search = recent_searches[i];

            if(recent_search) {
                recent_list.push(
                    <div key={recent_search._id} className={dropdownOptionIndex === searchRecommendations.length + i ? "search-dropdown-item active" : "search-dropdown-item"}>
                        <div className='search_dropdown_item_icon'>
                            <SearchIcon />
                        </div>
                        <div className='search_dropdown_item_option'>
                            <div>
                                {recent_search.search_text}
                            </div>
                            <div className='search__actionBtn' onClick={() => deleteRecentSearch(auth.user._id, recent_search._id)}>
                                <CloseIcon />
                            </div>
                        </div>

                        {/* Post Overlay to detect when clicked and redirect to postPage */}
                        <div 
                            className="post__overlay" 
                            onClick={() => window.location = `/search?q=${recent_search.search_text}`}
                        ></div>
                    </div>
                )
            }
        }
        
    } else {
        recent_list = null;
    }

    return (
        <div className="widgets">
            {/* <div className={`widgets__input ${inputClicked ? 'active' : ''}`}>
                <SearchIcon className="widgets__searchIcon" />
                <input 
                    id="search-input" 
                    placeholder={`Search ${campus_name} Buy / Sell`}
                    type="text" 
                    autocomplete="off" 
                    value={searchText}
                    onChange={e => onChange(e)}
                    onKeyDown={(e) => {
                        if(e.key === 'Enter') {
                            handleSubmit(searchText);
                        } else if(e.keyCode == '40') { // down arrow
                            onDownArrow();
                        } else if(e.keyCode == '38') { // up arrow
                            onUpArrow();
                        } else {
                            handleRecommendations(searchText);
                        }
                    }}
                />
            </div> */}

            {/* Dropdown Menu */}
            {/* <div className={inputClicked ? "search-dropdown widget_search active" : "search-dropdown widget_search"}>
                
                <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(239, 243, 244)'}} />

                {recommendations_list}

                {recent_list && (
                    <Fragment>
                        <div className='menu-title' style={{display: 'flex', cursor:'default', justifyContent:'space-between', alignItems: 'center', padding: '12px 16px'}}>
                            <div style={{lineHeight:'24px', fontSize:'20px', fontWeight:700, wordWrap:'break-word', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                <span>My Recent</span>
                            </div>

                            <div onClick={() => clearRecentSearch(auth.user._id)} style={{minWidth: '24px', cursor:'pointer', minHeight:'24px', padding:'0 12px', color:'rgb(29, 155, 240)', display:'flex', justifyContent:'center', fontWeight:700, fontSize:'14px', lineHeight:'16px', wordBreak:'break-word', textAlign:'center', alignItems:'center'}}>
                                <span>Clear all</span>
                            </div>
                        </div>

                        {recent_list}
                    </Fragment>
                )}

                {!recent_list?.length > 0 && !recommendations_list?.length > 0 ? (
                    <div className='menu-title' style={{display: 'flex', width:'100%', color:'rgb(83, 100, 113)', fontSize:'15px', cursor:'default', justifyContent:'center', alignItems: 'center', paddingTop: '12px'}}>Try searching for keywords</div>
                ) : null}

            </div> */}

            {/* Hidden Overlay for dropdown */}
            {/* <div
                className={`hidden-overlay ${inputClicked ? "show" : ""}`}
                onClick={() => setInputClicked(!inputClicked)}
            /> */}

            <div className="widgets__authContainer">

                {/* Title */}
                <h2>About This Group</h2>

                {/* Website info */}
                <div className="widgets__authStats group">
                    <GroupIcon />
                    <span style={{fontWeight:'500'}}>5.4K members</span>
                </div>
                {campus_name === 'OU' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Norman, Oklahoma</span>
                    </div>
                )}
                {campus_name === 'UT' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Austin, Texas</span>
                    </div>
                )}
                {campus_name === 'SMU' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Dallas, Texas</span>
                    </div>
                )}
                {campus_name === 'UF' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Gainesville, Florida</span>
                    </div>
                )}
                {campus_name === 'UCLA' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Los Angeles, California</span>
                    </div>
                )}
                {campus_name === 'Stanford' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Stanford, California</span>
                    </div>
                )}
                {campus_name === 'Duke' && (
                    <div className="widgets__authStats location">
                        <RoomIcon />
                        <span style={{fontWeight:'500'}}>Durham, North Carolina</span>
                    </div>
                )}


                {/* Button for widget box (sign up or share website if logged in) */}
                <div style={{marginBottom:'16px'}}>
                    {!auth.loading && !auth.isAuthenticated ? (
                        <Fragment>
                            {/* <div style={{border: '1px solid rgb(207, 217, 222)', paddingLeft:'16px', paddingRight:'16px', minHeight:'36px', minWidth:'36px', marginBottom:'12px', height:'40px', width: '300px', borderRadius: '40px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <span style={{fontWeight:'700', fontSize:'15px'}}>Sign up with Google</span>
                            </div> */}
                            {/* <div style={{border: '1px solid rgb(207, 217, 222)', paddingLeft:'16px', paddingRight:'16px', minHeight:'36px', minWidth:'36px', marginBottom:'12px', height:'40px', width: '300px', borderRadius: '40px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <span style={{fontWeight:'700', fontSize:'15px'}}>Sign up with Apple</span>
                            </div> */}
                            <Button onClick={() => window.location.href = "/user_registration"} variant="outlined" className="sidebar__tweet ghost" fullWidth>
                                Join Group
                            </Button>
                            {/* <div style={{border: '1px solid rgb(207, 217, 222)', paddingLeft:'16px', paddingRight:'16px', minHeight:'36px', minWidth:'36px', marginBottom:'12px', height:'40px', width: '300px', borderRadius: '40px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <span style={{fontWeight:'700', fontSize:'15px'}}>Join Group</span>
                            </div> */}
                        </Fragment>
                    ) : (
                        <Button onClick={toggleShareModal} variant="outlined" className="sidebar__tweet ghost" fullWidth>
                            Share Group
                        </Button>
                    )}
                </div>

                {/* Small disclaimer text under action button */}
                <div className="widgets__authDisclaimer">
                    By signing up, you agree to the <a href="/settings/terms">Terms of Service</a> and <a href="/settings/privacy">Privacy Policy</a>, including <a href="/settings/privacy#cookies">Cookie Use.</a>
                </div>
            </div>

            {/* Footer - bottom nav */}
            <div className="widgets__footer">
                <nav>
                    <a href="/settings/terms">
                        Terms of Service
                    </a>
                    <a href="/settings/trust_and_safety">
                        Trust/Safety
                    </a>
                    <a href="/settings/returns">
                        Returns
                    </a>
                    <a href="/settings/about">
                        About
                    </a>
                    <a href="/settings/help">
                        Help
                    </a>
                    <div>
                        © 2024 Cardboard Express, LLC
                    </div>
                </nav>
            </div>

            {/* <div className="widgets__widgetContainer">
                <h2>What's happening</h2>

                <TwitterTweetEmbed tweetId={"858551177860055040"} />
            </div> */}
        </div>
    )
}

Widgets.propTypes = {
    nav: PropTypes.object.isRequired,
    toggleShareModal: PropTypes.func.isRequired, // Prop: Function to toggle share modal
    auth: PropTypes.object.isRequired, // Prop: Object representing authentication state
    search: PropTypes.object.isRequired,
    getRecentSearches: PropTypes.func.isRequired,
    deleteRecentSearch: PropTypes.func.isRequired,
    clearRecentSearch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth, // Connect: Map authentication state from redux store to auth prop
    search: state.search,
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleShareModal,
    getRecentSearches, 
    deleteRecentSearch, 
    clearRecentSearch 
})(Widgets);