import { 
    CHATS_LOADING,
    CHAT_LOADING,
    GET_CHAT,
    GET_CHATS, 
    CREATE_CHAT,
    ADD_MESSAGE,
    GET_MESSAGES,
    SET_MESSAGES,
    SET_MESSAGE,
    UPDATE_LAST_MESSAGE,
    SET_UNREAD_CHATS,
    CLEAR_MESSAGES,
    CHAT_ERROR, 
    CLEAR_CHAT,
    CLEAR_CHATS,
    UPDATE_EDITOR_TEXT,
    CLEAR_EDITOR_TEXT,
    UPDATE_EDITOR_IMG_FILE,
    CLEAR_EDITOR_IMG_FILE,
    UPDATE_IMG_URL,
    REMOVE_IMG_URL
} from "../actions/types";


// Initial state of the chat-related data
const initialState = {
    chat: null, // Current chat
    chats: [], // List of chats
    num_unread_chats: 0, // Number of unread chats
    loading: true, // Loading status
    chat_loading: true, // Chat loading status
    messages: [], // List of messages
    editorText: null,
    editorImgFiles: [],
    img_url: [],
    img_loading: true,
    error: {} // Error object
}

// Reducer function to manage chat-related state
export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CHATS_LOADING:

            // Set loading status for chats
            return {
                ...state,
                loading: true
            };
        case CHAT_LOADING:

            // Set loading status for a specific chat
            return {
                ...state,
                chat_loading: true
            };
        case GET_CHAT:

            // Get a specific chat and update its read status
            let tempChat = payload; 

            tempChat.read = true;

            return {
                ...state,
                chat: tempChat,
                loading: false,
                chat_loading: false
            }
        case GET_CHATS:

            // Get all chats
            return {
                ...state,
                chats: payload,
                loading: false
            }
        case CREATE_CHAT:

            // Create a new chat and update the chats and current chat
            return {
                ...state,
                chats: [payload, ...state.chats],
                chat: payload,
                loading: false,
                chat_loading: false
            };
        case SET_UNREAD_CHATS: {

            // Set the number of unread chats
            return {
                ...state,
                num_unread_chats: action.payload,
            };
        }
        case ADD_MESSAGE:

            // Add a new message to the list of messages
            return {
                ...state,
                messages: [...state.messages, payload],
                loading: false
            };
        case GET_MESSAGES:

            // Get all messages
            return {
                ...state,
                messages: payload,
                loading: false
            };
        case SET_MESSAGES: {

            // Append new messages to the existing message list
            const messages = action.payload;
            let tempMessages = messages; 
            
            if(state.messages.length > 0) {
                tempMessages= [...state.messages, ...tempMessages ];
            }

            return {
                ...state,
                messages: tempMessages,
                loading: false
            };
        }
        case SET_MESSAGE: {

            // Add a single message to the list of messages
            const message = action.payload;
            let tempMessages = [message]; 
            
            if(state.messages.length > 0) {
                tempMessages= [...state.messages, ...tempMessages ];
            }

            return {
                ...state,
                messages: tempMessages,
                loading: false
            };
        }
        case UPDATE_LAST_MESSAGE: {

            return {
                ...state,
                chat: payload,
                chats: state.chats.map(chat =>
                    chat._id === payload._id ? payload : chat
                ),
                loading: false
            };
        }
        case CHAT_ERROR:

            // Update state with chat-related error and clear chat data
            return {
                ...state,
                error: payload,
                loading: false,
                chat_loading: false,
                chat: null
            };
        case CLEAR_CHAT:

            // Clear the current chat
            return {
                ...state,
                chat: null,
                loading: false,
                chat_loading: false
            }
        case CLEAR_CHATS:

            // Clear all the chats
            return {
                ...state,
                chats: [],
                loading: false,
                chat_loading: false
            }
        case CLEAR_MESSAGES:

            // Clear all messages chat in chat
            return {
                ...state,
                messages: [],
                loading: false,
                chat_loading: false
            }  
        case UPDATE_EDITOR_TEXT:

            // Update the editorText state with the payload value
            const editorData = payload;

            return {
                ...state,
                editorText: editorData
            };
        case CLEAR_EDITOR_TEXT:

            // Clear the editorText state
            return {
                ...state,
                editorText: null
            };
        case UPDATE_EDITOR_IMG_FILE:

            // Update the editorImgFile state with the payload value
            const fileData = payload;

            return {
                ...state,
                editorImgFiles: [fileData]
            };
        case CLEAR_EDITOR_IMG_FILE:

            // Clear the editorImgFile state
            return {
                ...state,
                editorImgFiles: []
            };
        case UPDATE_IMG_URL:

            // Add the new URL to the img_url state
            const urlData = payload;

            return {
                ...state,
                img_url: [urlData],
                img_loading: false
            };
        case REMOVE_IMG_URL:

            return {
                ...state,
                img_url: [],
                img_loading: true
            };
        default:
            return state;
    }
}