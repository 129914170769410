import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

// Actions
import { getComments, getPostById, clearEditorText, clearEditorFiles, clearUrls } from '../actions/postActions';
import { editUsername } from '../actions/authActions';
import { setPage, add_To_Recent_History, toggleCommentModal, toggleAuthModal } from '../actions/navActions';

// Layout Wrapper
import Layout from '../components/layout/Layout';

import ModalPost from '../components/Post/ModalPost';

// components
import { Button } from '@material-ui/core';
import Spinner from '../components/common/Spinner';
import TweetBox from '../components/TweetBox/TweetBox';
import Detail from '../components/PostPage/Detail';
import Comment from '../components/Post/Comment';
import Post from '../components/Post/Post';
import Image_Block from './MediaPage/Image_Block';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Modal
import CommentModal from '../components/modal/CommentModal';
import ModalContainer from '../components/modal/ModalContainer';
import Modal from '../components/modal/Modal';

const PostPage = ({ 
    setPage, 
    add_To_Recent_History,
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    toggleCommentModal,
    toggleAuthModal,
    getPostById, 
    getComments,
    post: { 
        post, 
        comments,
        loadingComments,
        loading 
    }, 
    nav: {
        commentModal,
        postModal,
        campus_id,
        campus_name,
        campus_loading
    },
    editUsername, 
    auth, 
    match, 
    history 
}) => {
    
    // Analytics 
    const [sentMixpanel, setSentMixpanel] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Modal toggles
    const [displayModal, setDisplayModal] = useState(false);
    const [discardModal, setDiscardModal] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);
    const [gotExecuteScroll, setGotExecuteScroll] = useState(false);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const hostname = window.location.hostname;

    // Determine if the device is mobile or tablet based on the `windowWidth` state
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    useEffect(() => {

        // Set the page to 'detail'
        setPage('detail');
        add_To_Recent_History(url.pathname);  // current url

        // Fetch the post data based on the `match.params.id` parameter
        getPostById(match.params.id);

    }, [ match.params.id ]);

    useEffect(() => {

        // Fetch the comments for the post if the loading state is `false` and the post data is available
        if(!loading && post) {
            getComments(post._id);
        }

    }, [ loading, post ]);

    useEffect(() => {

        // Add a resize event listener to update the `windowWidth` state
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Cleanup the event listener when the component unmounts
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    });

    // Update the `windowWidth` state whenever the window is resized
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Handle create Comment Modal Toggle
    const handleToggle_CommentModal = () => {
        if(auth.user) {
            toggleCommentModal();
        } else {
            toggleAuthModal('comment');
        }
        

    }

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight } = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY && gotExecuteScroll){

                // scrolling down - hide top nav
                setScrollDirection("down");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {
    
                // scrolling up - show top nav
                setScrollDirection("up");
    
                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
                
            }
        }
        // --- END Handle top nav bar display

        // For purposes of correctly displaying top nav when auto scrolling to bottom of comment detail page
        var scrollTimeout;
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function() {
            console.log('----- Scroll ended');
            setGotExecuteScroll(true);
        }, 1000);

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)
    }

    // Toggle the `displayModal` 
    const handleDisplayModal = () => {
        setDisplayModal(!displayModal)
    }

    // Toggle the `discardModal` 
    const handleCommentModal = () => {
        toggleDiscardModal();
    }

    // Toggle the `discardModal` 
    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const handleDiscard = () => {

        // Close the discard modal
        setDiscardModal(false);
        // close comment modal
        toggleCommentModal();

        clearEditorFiles();
        clearUrls();
        clearEditorText();
    }

    let commentList;

    if(loading || post === null) {
        commentList = <></>
    } else {
        if(comments === null || loadingComments) {
            commentList = <Spinner />;
        }
        else {
            if(comments.length > 0) {
                   

                const renderPostRecursive = (postComments) => {
                    if (Array.isArray(postComments)) {
                        return postComments.map((comment) => (
                            <Fragment>
                                <Post key={comment._id} postDoc={comment} />
    
                                {/* Recursive call to render comments of comments */}
                                {comment.commentsOfComments && comment.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                        {renderPostRecursive(comment.commentsOfComments[comment.commentsOfComments.length - 1])} {/* Render only the first sub-comment */}
                                    </div>
                                )}
                            </Fragment>
                        ));
                    } else {
                        return (
                            <Fragment>
                                <Post key={postComments._id} postDoc={postComments} />
    
                                {/* Recursive call to render comments of comments */}
                                {postComments.commentsOfComments && postComments.commentsOfComments.length > 0 && (
                                    <div className="comments-of-comments">
                                    {renderPostRecursive(postComments.commentsOfComments[0])} {/* Render only the first sub-comment */}
                                    </div>
                                )}
                            </Fragment>
                        );
                    }

                };

                commentList = (
                    <div className='post_container'>
                        {renderPostRecursive(comments)}
                    </div>
                )

                // return (

                //     <Post key={comment._id} post={comment} />
                //     // <Comment 
                //     //     key={comment._id}
                //     //     comment={comment}
                //     //     postId={post._id}
                //     // /> 
                // )
            }
            else {
                commentList = (

                    <div className="no-rides">
                        <h1 className='placeholder_title'>Be the first to comment</h1>
                        <p className='placeholder_text'>Add your thoughts and get the conversation started.</p>

                        <Button 
                            onClick={handleToggle_CommentModal}
                            variant="outlined" 
                            className="sidebar__tweet minWidth black"
                        >
                            Create A Comment
                        </Button>
                    </div>
                );
            }
        }
    }


    let postContent;

    if(loading) {
        postContent = <Spinner />;
    }
    else {
        if(post === null) {
            postContent = (
                <div className="no-post">
                    <p>Hmm...this page doesn’t exist. Try searching for something else.</p>
                    <Button  
                        onClick={() => window.location ='/search'}
                        className="noChats__btn"
                    >
                        Search
                    </Button>
                </div>
            );
        } else {
            postContent =  (
                <Fragment>
                    <Detail 
                        post={post}
                        toggleCommentModal={toggleCommentModal}
                        history={history}
                        isTablet={isMobile}
                        commentList={commentList}
                        setScrollDirection={setScrollDirection}
                        setOldScrollY={setOldScrollY}
                        oldScrollY={oldScrollY}
                    />

                    {!commentModal && !postModal && (
                        <TweetBox isTablet={isTablet} handleDisplayModal={handleDisplayModal} postId={post._id} comment mobile_redirect={isMobile} />
                    )} 

                    {commentList}

                    {/* <div className="no-rides" /> */}
                </Fragment>
            );
        }
    }

    const handleMixpanel = () => {

        // Initialize Mixpanel with the provided Mixpanel ID
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Identify the current user by their ID
        mixpanel.identify(auth.user._id);

        // Track the Page View event
        mixpanel.track("Post Detail Page View");
    }

    // Check if the code is running in production and Mixpanel event hasn't been sent yet
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && auth.user !== null  && !hostname.includes('xbuysell')) {
        
        // Initialize Mixpanel and track the event
        handleMixpanel();

        // Set the flag to indicate that Mixpanel event has been sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} page="post">

                {!auth.loading && !auth.isAuthenticated && (
                    <div style={{margin: '12px 16px'}} className="btnRow">
                        <Button 
                            onClick={toggleAuthModal}
                            variant="outlined" 
                            className="sidebar__tweet ghost minWidth noMargin"
                        >
                            Log In
                        </Button>
                        <Button 
                            onClick={toggleAuthModal}
                            variant="outlined" 
                            className="sidebar__tweet minWidth noMargin"
                        >
                            Join Group
                        </Button>
                    </div>
                )}

                {postContent}

                <div className='no-rides'>
                    {/* add space to bottom of page for better scroll experience */}
                </div>
            </Layout>

            {/* Modal for adding a Comment */}
            <div className={`Sliding-Modal ${displayModal ? "Show" : ""}`}>

                {/* Close Icon & Button */}
                <div className="closeBtn__container sliding">
                    <button onClick={handleDisplayModal} className="secondaryBtn close">
                        <ArrowBackIcon /> 
                        <span>Back</span>
                    </button>
                    <Button className="tweetBox__tweetButton sliding">Tweet</Button>
                </div>

                {/* Main Section of modal */}
                <div style={{padding:'10px 10px 10px 20px'}}>

                    {/* Display the post to comment on's content if available */}
                    {post && <ModalPost post={post} />}

                    {/* Display the tweet box if the user is authenticated */}
                    {!auth.loading && auth.user ? (
                        <TweetBox postId={post && post._id} modal comment sliding />
                    ) : null}
                </div>
            </div>

            {/* Backdrop for the modal */}
            <div
                className={`Sliding-Overlay ${displayModal ? "Show" : ""}`}
                onClick={() => setDisplayModal(!displayModal)}
            />

            {/* Commenting Modal */}
            <ModalContainer show={commentModal} onClose={handleCommentModal}>
                <CommentModal>

                    {/* Close Button */}
                    <div className="closeBtn__container start modalClose">
                        <button onClick={handleCommentModal} className="secondaryBtn close">
                            <CloseIcon /> 
                            <span>Close</span>
                        </button>
                    </div>

                    {/* Modal Content */}
                    <ModalPost post={post} />
                    
                    {/* Display the tweet box for commenting */}
                    <TweetBox postId={post && post._id} modal comment />
                </CommentModal>
            </ModalContainer>

            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Discard Post?</h2>
                    </div>

                    {/* Modal Text  */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Modal Actions  */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>
        </Fragment>
    )
}

PostPage.propTypes = {
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    clearEditorText: PropTypes.func.isRequired, 
    clearEditorFiles: PropTypes.func.isRequired, 
    clearUrls: PropTypes.func.isRequired,
    toggleCommentModal: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
    getPostById: PropTypes.func.isRequired,
    getComments: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    setPage, 
    add_To_Recent_History,
    clearEditorText, 
    clearEditorFiles, 
    clearUrls,
    toggleCommentModal, 
    toggleAuthModal,
    getPostById, 
    getComments, 
    editUsername })(PostPage);
