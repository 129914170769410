import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// Components - imported
import { Button } from '@material-ui/core';
import { toggleProfileModal } from '../../actions/navActions';

// Displays if the user is logged in BUT HAS NOT set up profile
const GettingStarted = ({ 
    nav: {
        campus_name
    },
    toggleProfileModal,
    hide_actionBtn
}) => {
    
    // Render the component
    return (
        <div style={{padding: '0 16px 16px'}}>
            <label style={{marginTop:'12px', display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
                <div style={{paddingRight:'12px', flexShrink:1, width:'100%'}}>
                    {/* <div style={{color:'rgb(83, 100, 113)', fontSize:'14px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                        <span>
                            Twitter uses this data to personalize your experience. This web browsing history will never be stored with your name, email, or phone number.
                        </span>
                    </div> */}
                    <header style={{fontSize: '16px'}}>
                        <h1 style={{textAlign: 'center'}}>How it works</h1>
                    </header>
                    <section style={{fontSize: '16px'}}>
                        <div style={{lineHeight: '1.6', margin: '10px 0px', overflowWrap: 'break-word'}}>
                            <div>
                                <p style={{textAlign: 'center', marginBottom:'10px'}}>
                                    <span>Easily buy / sell items by following these steps.</span>
                                </p>
                                <h2 style={{textAlign: 'center', fontSize: '22px'}}>
                                    <strong style={{fontWeight: 'bold'}}>Step 1: Buy an item</strong>
                                </h2>
                                <ol style={{paddingLeft: '20px', margin: '10px 0px 20px 20px'}}>
                                    <li style={{marginBottom: '10px'}}>
                                        <span>When you find an item you like, you can use the built-in chat option to DM the seller or you can directly comment on their post.</span>
                                    </li>
                                    <li style={{marginBottom: '10px'}}>
                                        <span>Make an offer.</span>
                                    </li>
                                    <li style={{marginBottom: '10px'}}>
                                        <span>Once the seller agrees to your offer, find a safe meet-up spot near the both of you.</span>
                                    </li>
                                    <li>
                                        <span>Inspect the item &#8594; If you are satified &#8594; Pay in person.</span>
                                    </li>
                                </ol>
                                <h2 style={{textAlign: 'center', fontSize: '22px'}}>
                                    <strong style={{fontWeight: 'bold'}}>Step 2: Sell your item</strong>
                                </h2>
                                <p>
                                    <span>Once your item is posted on {campus_name} Buy / Sell, it&rsquo;s time to sell it. Here&rsquo;s how it works:</span>
                                </p>
                                <ol style={{paddingLeft: '20px', marginTop: '20px', marginLeft: '20px'}}>
                                    <li style={{listStyleType: 'none'}}>
                                        <ol style={{paddingLeft: '20px'}}>
                                            <li style={{marginBottom: '10px'}}>
                                                <span>You&apos;ll get messages in your inbox and comments on your post from interested buyers.&nbsp;</span>
                                                {/* <a href="#" style={{backgroundColor: 'transparent', color: 'rgb(0, 0, 0)', textDecoration: 'underline'}}>
                                                    <span>Find messages</span>
                                                </a> */}
                                            </li>
                                            <li style={{marginBottom: '10px'}}>
                                                <span>Reply to these messages to answer any questions, then agree on a price with a buyer.</span>
                                            </li>
                                            <li style={{marginBottom: '10px'}}>
                                                <span>If you&rsquo;re meeting the buyer in person:</span>
                                                <ul style={{padding: '0px 0px 0px 20px'}}>
                                                    <li style={{marginBottom: '10px', marginTop: '5px'}}>
                                                        <span>Use the built-in chat to agree on a time / place to meet. If the item is large or heavy, we recommend making plans to lift or move it.&nbsp;</span>
                                                    </li>
                                                    <li style={{marginBottom: '10px'}}>
                                                        <span>When you meet, let the buyer check out the item.&nbsp;</span>
                                                    </li>
                                                    <li style={{marginBottom: '10px'}}>
                                                        <span>If you and the buyer agree to the sale, swap the item and pay in person.</span>
                                                        {/* <a href="#" style={{backgroundColor: 'transparent', color: 'rgb(0, 0, 0)', textDecoration: 'underline'}}>
                                                            <span>payment in person</span>
                                                        </a>
                                                        <span>.</span> */}
                                                    </li>
                                                    <li>
                                                        <span>If the sale doesn&apos;t work out, be polite, and don&apos;t be discouraged. There are many other potential buyers on {campus_name} Buy / Sell.</span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </section>
                </div>
            </label>
            {/* <div style={{marginTop:'10px'}}>
                <div style={{color:'rgb(83, 100, 113)', fontSize:'14px', fontWeight:'500', lineHeight:'20px', overflowWrap:'break-word'}}>
                    <span>For more details about these settings, visit the <a href="#" style={{textDecoration:'none', color:'rgb(27, 149, 224)'}}>Help Center</a>.</span>
                </div>
            </div> */}

            {!hide_actionBtn && (
                <div>
                    <Button 
                        variant="outlined" 
                        className="sidebar__tweet ghost" 
                        fullWidth
                        onClick={toggleProfileModal}
                    >
                        Set up profile
                    </Button>
                    {/* <Button 
                        variant="outlined" 
                        className="sidebar__tweet ghost" 
                        fullWidth
                    >
                        Skip
                    </Button> */}
                </div>
            )}
        </div>
    )
}

// Prop types for the component
GettingStarted.propTypes = {

    // Prop type validation for function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    nav: state.nav
})

export default connect(mapStateToProps, { 

    // Connecting actions from Redux to the component
    toggleProfileModal 
})(GettingStarted);
