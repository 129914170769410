import { 
    GET_RECENT_SEARCHES,
    GET_POPULAR_SEARCHES,
    SEARCHES_ERROR,
    CLEAR_POPULAR_SEARCHES,
    POPULAR_SEARCHES_LOADING,
    CLEAR_RECENT_SEARCHES,
    RECENT_SEARCHES_LOADING,
    DELETE_RECENT_SEARCH
} from "../actions/types";

const initialState = {
    popular_searches: [],
    recent_searches: [],
    popular_loading: true,
    recent_loading: true,
    error: {}
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case RECENT_SEARCHES_LOADING:

            // Set the recent_loading state to true
            return {
                ...state,
                recent_loading: true
            };
        case POPULAR_SEARCHES_LOADING:

            // Set the popular_loading state to true
            return {
                ...state,
                popular_loading: true
            };
        case GET_RECENT_SEARCHES:

            // Set the recent_searches state to the payload value,
            return {
                ...state,
                recent_searches: payload,
                recent_loading: false
            }
        case GET_POPULAR_SEARCHES:

            // Set the popular_searches state to the payload value,
            return {
                ...state,
                popular_searches: payload,
                popular_loading: false
            }
        case SEARCHES_ERROR:

            // Set the error state to the payload value and set loading to false
            return {
                ...state,
                error: payload,
                popular_loading: false,
                recent_loading: false,
            };
        case CLEAR_RECENT_SEARCHES:

            // Clear the recent_searches state and set recent_loading to false
            return {
                ...state,
                recent_searches: [],
                recent_loading: false,
            }
        case CLEAR_POPULAR_SEARCHES:

            // Clear the popular_searches state and set popular_loading to false
            return {
                ...state,
                popular_searches: [],
                popular_loading: false,
            }
        case DELETE_RECENT_SEARCH: 

            // Remove the deleted search query from the recent_searches state,
            return {
                ...state,
                recent_searches: state.recent_searches.filter(recent_search => recent_search._id !== payload),
                recent_loading: false
            };
        default:
            return state;
    }
}