import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Components
import { Button } from '@material-ui/core';

import ou_logo from '../../utils/imgs/ou_logo.png';
import ut_logo from '../../utils/imgs/ut_logo.png';
import smu_logo from '../../utils/imgs/smu_logo.png';

const Default = ({
  nav: {
    campus_name,
},
}) => {

  // --- Campus Logis ----

  let app_logo;

  if(campus_name === 'OU') {
          
      app_logo = ou_logo
  }
  else if(campus_name === 'UT') {
      app_logo = ut_logo
  }
  else if(campus_name === 'SMU') {
      app_logo = smu_logo
  } else {
    app_logo = ou_logo
  }

  // --- END: Campus Logis ----

  return (
    <div className="no-post">

      {/* Logo image */}
      <img onClick={() => window.location = '/home'} className="modal__twitterIcon" src={app_logo} style={{maxHeight: '80px', cursor:'pointer'}} alt="logo" />
      
      <h1>This page doesn’t exist. </h1>
      <p>Try searching for something else.</p>

      <Button  
          onClick={() => window.location ='/home'}
          className="noChats__btn"
      >
          Go To Feed
      </Button>
    </div>
  );
};

Default.propTypes = {
    nav: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component

})(Default);