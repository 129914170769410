import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History, togglePostModal, goBack } from '../../actions/navActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// components
import Spinner from '../../components/common/Spinner';
import TweetBox from '../../components/TweetBox/TweetBox';
import Post from '../../components/Post/Post';
import { Button } from '@material-ui/core';

// Icons - material UI
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Modal
import ModalContainer from '../../components/modal/ModalContainer';
import Modal from '../../components/modal/Modal';

const CreatePost = ({ 
    setPage, 
    add_To_Recent_History,
    goBack,
    auth: { 
        modalUser 
    }, 
    post: { 
        post,
        postUploading,
        postUploadingImgs
    }, 
    togglePostModal, 
    nav: { 
        postModal 
    }, 
    history }) => {
    
    // State variable for tracking Mixpanel event
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Modal toggles
    const [discardModal, setDiscardModal] = useState(false);
    const [postUploadingModal, setPostUploadingModal] = useState(false);
    const [postUploadingImgsModal, setPostUploadingImgsModal] = useState(false);

    // Window width state
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Extract URL and previous URL page
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    let previousUrl = url.searchParams.get("goBack");
    const hostname = window.location.hostname;

    useEffect(() => {

        // Event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());
        
        // Set current page to 'create'
        setPage('create');
        add_To_Recent_History(url.pathname);  // current url

        // Close post modal if open
        if(postModal) {
            togglePostModal();
        }

        // Clean up event listener
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    // Function to handle window size change
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Function to toggle discard modal
    const toggleDiscardModal = () => {
        setDiscardModal(!discardModal);
    }

    const togglePostUploadingModal = () => {
        setPostUploadingModal(!postUploadingModal);
    }

    const togglePostUploadingImgsModal = () => {
        setPostUploadingImgsModal(!postUploadingImgsModal);
    }

    // Logic for handling discard action
    const handleDiscard = () => {
        setDiscardModal(false);

        // Redirect to previous URL if available
        goBack();
        // if(previousUrl !== null) {
        //     window.location.href = `/${previousUrl}`;
        // } else {
        //     history.goBack()
        // }
    }

    // Check if the window size is mobile or tablet
    const isMobile = windowWidth <= 769;
    const isTablet = windowWidth <= 1000;

    // Redirect logic for non-mobile devices
    if(!isMobile) {
        if(previousUrl !== null) {
            togglePostModal();

            // Redirect based on previous URL
            if(previousUrl === 'detail') {
                if(post) {
                    history.push(`/post/${post._id}`);
                }
            } else if(previousUrl === 'profile') {
                if(modalUser) {
                    history.push(`/${modalUser.username}`);
                } else {
                    history.push(`/home`);
                }
            } else {
                history.push(`/${previousUrl}`)
            }
        }
    }

    // Mixpanel tracking logic
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);
        mixpanel.track("Mobile Post Page View");
    }

    // Trigger Mixpanel tracking on first render
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {
        handleMixpanel();
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <div className="createPost__page">
                {/* Post creation coomponents */}
                <div onClick={toggleDiscardModal} className="closeBtn__container start">
                    
                    {/* Button to go Back */}
                    <button  className="secondaryBtn close">
                        <ArrowBackIcon /> 
                        <span>Back</span>
                    </button>
                    {/* <Button className="tweetBox__tweetButton sliding">Tweet</Button> */}
                </div>

                {/* TweetBox */}
                <div style={{padding:'10px 10px 10px 20px'}}>
                    <TweetBox modal sliding history={history} />
                </div>
            </div>

            {/* Discard modal */}
            <ModalContainer show={discardModal} onClose={toggleDiscardModal}>
                <Modal>

                    {/* Title */}
                    <div>
                        <h2>Discard Post?</h2>
                    </div>

                    {/* Description */}
                    <p>Are you sure? Your draft will not be saved.</p>
                    
                    {/* Actions */}
                    <Button 
                        onClick={handleDiscard}
                        variant="outlined" 
                        className="sidebar__tweet black"
                        fullWidth
                    >
                        Discard
                    </Button>
                    <Button 
                        onClick={toggleDiscardModal}
                        variant="outlined" 
                        className="sidebar__tweet ghost blackText"
                        fullWidth
                    >
                        Keep Writing
                    </Button>
                </Modal>
            </ModalContainer>
            
            {/* Discard Post Modal */}
            <ModalContainer show={postUploading} onClose={togglePostUploadingModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Post...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>

            {/* Discard Post Modal */}
            <ModalContainer show={postUploadingImgs} onClose={togglePostUploadingImgsModal}>
                <Modal>

                    {/* Modal Title */}
                    <div>
                        <h2>Uploading Photos...</h2>
                    </div>

                    {/* Modal Description */}
                    <p>Patience is a virtue.</p>
                    
                    <Spinner />
                </Modal>
            </ModalContainer>
            
        </Fragment>
    )
}

CreatePost.propTypes = {
    toggleSideNav: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    add_To_Recent_History: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    togglePostModal: PropTypes.func.isRequired,
    post: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    post: state.post,
    nav: state.nav,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions from redux to the component
    toggleSideNav, 
    setPage, 
    add_To_Recent_History,
    goBack,
    togglePostModal 
})(CreatePost);
