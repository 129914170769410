export default [
    {
        value: '2021',
    },
    {
        value: '2020',
    },
    {
        value: '2019',
    },
    {
        value: '2018',
    },
    {
        value: '2017',
    },
    {
        value: '2016',
    },
    {
        value: '2015',
    },
    {
        value: '2014',
    },
    {
        value: '2013',
    },
    {
        value: '2012',
    },
    {
        value: '2011',
    },
    {
        value: '2010',
    },
    {
        value: '2009',
    },
    {
        value: '2008',
    },
    {
        value: '2007',
    },
    {
        value: '2006',
    },
    {
        value: '2005',
    },
    {
        value: '2004',
    },
    {
        value: '2003',
    },
    {
        value: '2002',
    },
    {
        value: '2001',
    },
    {
        value: '2000',
    },
    {
        value: '1999',
    },
    {
        value: '1998',
    },
    {
        value: '1997',
    },
    {
        value: '1996',
    },
    {
        value: '1995',
    },
    {
        value: '1994',
    },
    {
        value: '1993',
    },
    {
        value: '1992',
    },
    {
        value: '1991',
    },
    {
        value: '1990',
    },
    {
        value: '1989',
    },
    {
        value: '1988',
    },
    {
        value: '1987',
    },
    {
        value: '1986',
    },
    {
        value: '1985',
    },
    {
        value: '1984',
    },
    {
        value: '1983',
    },
    {
        value: '1982',
    },
    {
        value: '1981',
    },
    {
        value: '1980',
    },
    {
        value: '1979',
    },
    {
        value: '1978',
    },
    {
        value: '1977',
    },
    {
        value: '1976',
    },
    {
        value: '1975',
    },
    {
        value: '1974',
    },
    {
        value: '1973',
    },
    {
        value: '1972',
    },
    {
        value: '1971',
    },
    {
        value: '1970',
    }
];
