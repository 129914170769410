import React, { useEffect } from 'react';
import PropTypes from 'prop-types'

import { toggleProfileModal, toggleVerifySchoolModal, goBack } from '../../actions/navActions';

// Redux
import { connect } from 'react-redux';

// Modals
import DefaultModal from './DefaultModal';
import ModalContainer from './ModalContainer';

// Components - imported
import { Button } from '@material-ui/core';
import AuthPage_Body from '../../pages/auth/AuthPage_Body';

// Icons - material UI
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CreateIcon from '@material-ui/icons/Create';
import MailIcon from '@material-ui/icons/Mail';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocalMallIcon from '@material-ui/icons/LocalMall';

const VerifySchool_Modal = ({ 
    auth,
    nav: { 
        verifySchool_Modal,
        authModalType
    },
    toggleProfileModal, 
    toggleVerifySchoolModal,
    goBack
 }) => {


    useEffect(() => {
        
        if(auth.user?.school_verified && verifySchool_Modal) toggleVerifySchoolModal();

    }, [auth.user]);

    {/* Authentication Modal - if not logged in && triggered an Action */}
    return (
        <ModalContainer show={verifySchool_Modal} onClose={() => authModalType === 'search' ? goBack() : toggleVerifySchoolModal()}>
            <DefaultModal>
                <div className="closeBtn__container start transparent no_header">
                    <div onClick={() => authModalType === 'search' ? goBack() : toggleVerifySchoolModal()} className="closeBtn">
                        <CloseOutlinedIcon />
                    </div>
                </div>

                {/* Not Authenticated Action */}
                <div className="guest__action">
                    {/* Tried to: Create A Post */}
    
                        {/* Create Post Icon */}
                        {/* <div style={{marginBottom:'8px'}}>
                            <CreateIcon />
                        </div> */}

                        {/* Heart Icon */}
                        {/* <div style={{marginBottom:'8px'}}>
                            <FavoriteIcon />
                        </div> */}

                        {/* Comment Icon */}
                        {/* <div style={{marginBottom:'8px'}}>
                            <ChatBubbleIcon />
                        </div> */}

                        {/* Message Icon */}
                        {/* <div style={{marginBottom:'8px'}}>
                            <MailIcon />
                        </div> */}

                        {/* Bag Icon */}
                        {/* <div style={{marginBottom:'8px'}}>
                            <LocalMallIcon />
                        </div> */}
                        
                        {/* Modal Content */}
                        <div style={{width:'100%', margin:'32px'}}>

                            {authModalType === 'post' && (
                                <div style={{marginBottom:'16px', textAlign: 'center'}}>
                                    {/* Modal Title */}
                                    <div style={{marginBottom:'8px', lineHeight:'32px', fontSize:'26px', fontWeight:'800', overflowWrap:'break-word'}}>
                                        <span>Verify your account to create a post.</span>
                                    </div>
                                    {/* Modal Description */}
                                    <div style={{ color:'rgb(83, 100, 113)', fontWeight:'500', fontSize:'15px', lineHeight:'20px', overflowWrap:'break-word'}}>
                                        <span>
                                        Once you verify your school email, you can create a post.
                                        </span>
                                    </div>
                                </div>
                            )}

                            {authModalType === 'message' && (
                                <div style={{marginBottom:'16px', textAlign: 'center'}}>
                                    {/* Modal Title */}
                                    <div style={{marginBottom:'8px', lineHeight:'32px', fontSize:'26px', fontWeight:'800', overflowWrap:'break-word'}}>
                                        <span>Verify your account to send a message.</span>
                                    </div>
                                    {/* Modal Description */}
                                    <div style={{ color:'rgb(83, 100, 113)', fontWeight:'500', fontSize:'15px', lineHeight:'20px', overflowWrap:'break-word'}}>
                                        <span>
                                        Once you verify your school email, you can send a message.
                                        </span>
                                    </div>
                                </div>
                            )}

                            {/* Tried to: Comment post */}
                            {authModalType === 'comment' && (
                                <div style={{marginBottom:'16px', textAlign:'center'}}>
                                    {/* Modal Title */}
                                    <div style={{marginBottom:'8px', lineHeight:'32px', fontSize:'26px', fontWeight:'800', overflowWrap:'break-word'}}>
                                        <span>Verify your account to comment.</span>
                                    </div>
                                    {/* Modal Description */}
                                    <div style={{color:'rgb(83, 100, 113)', fontWeight:'500', fontSize:'15px', lineHeight:'20px', overflowWrap:'break-word'}}>
                                        <span>
                                            Once you verify your school email, you can respond to {auth.modalUser && `${auth.modalUser.first_name}`}'s post.
                                        </span>
                                    </div>
                                </div>
                            )}
                            {/* Modal Actions */}

                            <div className='register_container'>
                                <div className={`register_content_container noPaddingTop`}>
                                    <div className='register_content'>

                                        
                                        <div style={{marginTop: '20px'}}>

                                            {/* /* Form Actions */}
                                            <div className='register_btns'>
                                                <Button 
                                                    className="sidebar__tweet black"
                                                    fullWidth
                                                    onClick={() => window.location.href = '/settings/account/school_email'}
                                                >
                                                    Verify Account
                                                </Button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* Page footer - fixed to bottom of screen */}
                                <div className={`register_footer marginTop_20`}>
                                    <hr style={{height:'1px', background:'rgb(232, 233, 235)'}} />
                                    <div className={`register_footer_nav flexDirection_col`}>
                                        <a href='/settings/terms'>
                                        Read our privacy policy
                                        </a>
                                        <a href='/settings/help'>
                                        Contact support
                                        </a>
                                    </div>
                                </div>
                            </div>
                    
                        </div>
                    
                    </div>
                
            </DefaultModal>
        </ModalContainer>
    )
}

VerifySchool_Modal.propTypes = {
    auth: PropTypes.object.isRequired,
    nav: PropTypes.object.isRequired,
    // Function to toggle the profile modal
    toggleProfileModal: PropTypes.func.isRequired,
    // Function to toggle the authentication modal
    toggleVerifySchoolModal: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    nav: state.nav
})

export default connect(mapStateToProps, { 
    // Connecting actions to the component
    toggleProfileModal, 
    toggleVerifySchoolModal,
    goBack
})(VerifySchool_Modal);


