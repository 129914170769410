import React, { useEffect } from 'react';

import ScrollToBottom from 'react-scroll-to-bottom';

// Components
import Message from './Message/Message';

const Messages = ({ 
    chatData, 
    messages, 
    name, 
    chatAvatar, 
    otherChatUserId,
    showTopSpinner,
    loadMoreDelay
}) => {

    useEffect(() => {

        // Scroll to the bottom of messages when a new message is added
        if(messages.length > 0) scrollToBottom();

    }, [messages.length, showTopSpinner, loadMoreDelay])

    let messagesEnd;

    const scrollToBottom = () => {

        // Scroll the messagesEnd element (ref) into view with smooth behavior
        messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    let messages_list = [];

    for (let i = 0; i < messages.length; i++) {
        let pushIndex = messages.length - 1;
        messages_list.push(
            <div key={messages[pushIndex - i]._id}>
                <Message chatData={chatData} message={messages[pushIndex - i]} name={name} chatAvatar={chatAvatar} otherChatUserId={otherChatUserId} scrollToBottom={scrollToBottom} />
            </div>
        )
    }

    return (
        <div className="messages">
            
            {/* Render individual messages */}
            {messages_list}

            {/* Scroll to bottom */}
            <div style={{ float:"left", clear: "both" }}
                ref={(el) => { messagesEnd = el }}>
            </div>
        </div>
    )
}

export default Messages;