import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Firebase
// Firebase
import { db } from '../../config/firebase-config';
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, setDoc, deleteDoc, query, where, onSnapshot, orderBy, startAfter, limit } from 'firebase/firestore';

import mixpanel from 'mixpanel-browser';

// Redux
import { connect } from 'react-redux';

// Actions
import { toggleSideNav, setPage, add_To_Recent_History, toggleAuthModal } from '../../actions/navActions';
import { setPosts, getPosts_With_Images, set_ShowBottomSpinner } from '../../actions/postActions';

// Layout Wrapper
import Layout from '../../components/layout/Layout';

// Components
import { Button } from '@material-ui/core';
import Spinner from '../../components/common/Spinner';
import Image_Block from './Image_Block';
import Empty_Card from './Empty_Card';

// Icons
import SearchIcon from '@material-ui/icons/Search';

// Modal

const MediaPage = ({ 
    nav: { 
        sideNav, 
        campus_id,
        campus_name,
        campus_loading,
        campus_privacy
    },
    post: {
        posts,
        no_more_posts,
        show_bottom_spinner,
        last_page_doc,
        loading
    },
    auth,
    setPage, 
    add_To_Recent_History,
    toggleSideNav,
    setPosts,
    getPosts_With_Images,
    set_ShowBottomSpinner,
    toggleAuthModal
}) => {

    // State variable to track if Mixpanel event has been sent
    const [sentMixpanel, setSentMixpanel] = useState(false);

    // Window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Posts List
    const [postList, setPostList] = useState([]);
    const [gotPosts, setGotPosts] = useState(false);

    // Scroll direction - for displaying top nav bar
    const [scrollDirection, setScrollDirection] = useState("up");
    const [oldScrollY, setOldScrollY] = useState(window.scrollY);

    // Temporarily disable to load more function to avoid duplicate fetch requests
    const [canLoadMore, setCanLoadMore] = useState(true);

    // Get the current URL and extract the "redirect" parameter
    const url_filter = (window.location.href);
    const url = new URL(url_filter);
    const hostname = window.location.hostname;

    const SPACE_HEIGHT = 250; // Hieght of className 'no-rides' which is a spacer to make sure user can always scroll down

    // Listen for window resize event and update window width state
    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', () => handleWindowSizeChange());

        // Clean up by removing event listener on component unmount
        return () => window.removeEventListener('resize', () => handleWindowSizeChange());
    }, []);

    useEffect(() => {


        if(!campus_loading) {
            if(campus_privacy === 'private') {
                if(!auth.loading && auth.isAuthenticated) {
                    getPosts_With_Images({
                        campus_id,
                        campus_name
                    });
                }
    
            } else if(campus_privacy === 'public') {
                getPosts_With_Images({
                    campus_id,
                    campus_name
                });
            }
        }
        

    }, [campus_loading, campus_privacy, auth.loading, auth.isAuthenticated]);

    useEffect(() => {

        renderPostList();

    }, [posts, canLoadMore]);

    // Update window width state on window resize
    const handleWindowSizeChange = () => {
        setWindowWidth(window.innerWidth);
    };

    // Determine window size breakpoints
    const isMobile = windowWidth <= 769;

    // Start - Infinite Scroll

    // Infinite scroll AND handle top nav bar display
    const handleScroll = (e) => {
        const { offsetHeight, scrollTop, scrollHeight} = e.target

        const newScrollY = offsetHeight + scrollTop;

        // --- Handle top nav bar display
        if(isMobile) {
            if(oldScrollY < newScrollY){

                // scrolling down - hide top nav
                setScrollDirection("down");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.remove("active");
                }
                
            } else {

                // scrolling up - show top nav
                setScrollDirection("up");

                if(document.getElementById('feed-header') !== null) {
                    document.getElementById('feed-header').classList.add("active");
                }
            }
        }
        // --- END Handle top nav bar display

        // update the scroll position for future comparison
        setOldScrollY(newScrollY)

        // Check if the user has scrolled to the bottom of the content and conditions for loading more posts are met
        if (newScrollY >= scrollHeight - (SPACE_HEIGHT+1) && !show_bottom_spinner && !no_more_posts && posts.length > 0 && canLoadMore) {
            console.log('scrolling');
            loadMore(last_page_doc);
        }
    }

    // Function to load more paginated posts
    const loadMore = (lastPage_doc) => {
        console.log("LOADING MORE");
        console.log("last", lastPage_doc);

        // Temporarily disable to load more function to avoid duplicate fetch requests
        setCanLoadMore(false);

        // Show spinner
        set_ShowBottomSpinner(true);

        // Fetch the next set of posts
        getPosts_With_Images(
            {
                campus_id,
                campus_name
            }, 
            lastPage_doc
        );

        // Re-enable the load more function after 1 second
        setTimeout(() => {
            setCanLoadMore(true);
        }, 1000);
    }

    // END - Infinite Scroll

    const renderPostList = async () => {
        setPostList([]);

        if((posts === null && loading) || !gotPosts) {
            setPostList([(
                <div className="no-rides">
                    <Spinner />
                </div>
            )])
        }
        else { 
            if(posts.length > 0) {
                posts.map(post_doc => {
                    setPostList(postList => [...postList, (
                        <Image_Block key={post_doc._id} detailPost={post_doc} />
                    )])
                }) 
            } else {
                setPostList(postList => [...postList, (
                    <div className="no-rides">
                        <h1>No Photos / Videos</h1>
                    </div>
                )])
                
            }
        } 

        setGotPosts(true);
    }

    useEffect(() => {

        // Set the current page
        setPage('Media Page');
        add_To_Recent_History(url.pathname);  // current url

    }, []);

    // Handles Mixpanel tracking
    const handleMixpanel = () => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_ID);

        // Track "About Page View" event
        mixpanel.track("About Page View");
    }

    // Checks if the environment is production and Mixpanel tracking hasn't been sent
    if(process.env.NODE_ENV === 'production' && !sentMixpanel && !hostname.includes('xbuysell')) {

        // Call Mixpanel tracking function
        handleMixpanel();

        // Set Mixpanel event as sent
        setSentMixpanel(true);
    }

    return (
        <Fragment>
            <Layout handleScroll={handleScroll} sideNav={sideNav} handleSlideMenu={toggleSideNav} page="home">

            {(campus_privacy === 'private' && !auth.loading && !auth.isAuthenticated) ? (
                <Fragment>
                    <div className="no-rides">
                        <h1 className='placeholder_title'>This community is private.</h1>
                        <p className='placeholder_text'>Click to join the group and view / participate in discussions.</p>

                        <Button 
                            onClick={() => toggleAuthModal('join campus')}
                            variant="outlined" 
                            className="sidebar__tweet minWidth black"
                        >
                            Click to Join
                        </Button>
                    </div>

                    <div className="no-rides"></div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="feed_headerTabs" style={{borderBottom: '1px solid #e6ecf0'}}>
                        <Link to="/home">
                            <div>
                                <h3>Feed</h3>
                                <div className="block__underline"></div>
                            </div>
                        </Link>
                        <Link to="/media">
                            <div className="active">
                                <h3>Media</h3>
                                <div className="block__underline"></div>
                            </div>
                        </Link>
                        <Link to="/about">
                            <div>
                                <h3>About</h3>
                                <div className="block__underline"></div>
                            </div>
                        </Link>
                    </div>

                    {/* <div style={{background: '#f5f8fa', textAlign: 'center', color: 'grey', fontSize: '16px', fontWeight: 'bold', padding: '10px', borderBottom: '1px solid #e6ecf0'}}>
                        <span>Find what you need</span>
                    </div> */}

                    {/* Search Input
                    <div onClick={() => window.location = '/search'} style={{marginRight: '20px', marginBottom: '10px'}} className={`widgets__input`}>
                        <SearchIcon className="widgets__searchIcon" />
                        <input 
                            id="search-input" 
                            placeholder={`Search Buy / Sell`}
                            type="text" 
                            autocomplete="off" 

                        />
                    </div> */}

                    {posts?.length > 0 ? (
                        <div style={{width: '100%', height: 'fit-content', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'min-content', borderBottom: '1px solid #ddd'}}>
                            {/* Render the postList */}
                            {postList}

                            {(!no_more_posts && posts.length !== 0) && (<Empty_Card last_page_doc={last_page_doc} page={"gallery"} loadMore={loadMore} setCanLoadMore={setCanLoadMore} canLoadMore={canLoadMore} />)}

                        </div>
                    ) : postList}

                    {/* Render a message when there are no more posts to load */}
                    <div className="no-rides">{(no_more_posts && posts.length !== 0) && "You've reached the end."}</div>
                </Fragment>
            )}
            </Layout>

        </Fragment>
    )
}

MediaPage.propTypes = {
    nav: PropTypes.object.isRequired,
    post: PropTypes.func.isRequired,
    auth: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired, // Function prop to set the current page in the navigation
    add_To_Recent_History: PropTypes.func.isRequired,
    toggleSideNav: PropTypes.func.isRequired,
    setPosts: PropTypes.func.isRequired,
    getPosts_With_Images: PropTypes.func.isRequired,
    set_ShowBottomSpinner: PropTypes.func.isRequired,
    toggleAuthModal: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({

    // Mapping the states from the Redux store to the below props
    nav: state.nav,
    post: state.post,
    auth: state.auth
});

export default connect(mapStateToProps, { 

    // Connecting actions to the component
    setPage, 
    add_To_Recent_History,
    toggleSideNav,
    setPosts,
    getPosts_With_Images,
    set_ShowBottomSpinner,
    toggleAuthModal
})(MediaPage);
